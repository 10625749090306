<footer class="container-fluid footer-bg py-4">
    <div class="container">
        <div class="d-flex">
            <div class="col-12">
                <div class="foot-logo mb-0">
                    <a href="#">
                        <img src="../../../assets/images/logo.svg" alt="logo" />
                    </a>
                </div>
                <p class="text-center text-dark mt-4">30% off your 1st order + <b>{{ 1.20 | currency:'GBP' }}</b> off on every extra dabba same day</p>
                <p class="small text-center">* The regular price for a dabba is £12.00. When you order additional dabbas for the same day, you'll enjoy a 10% discount</p>
            </div>
        </div>
        <div class="d-flex justify-content-center gap-3 font-mob mt-3" style="font-size: 0.9rem;">
            <span> <a role="button" (click)="navigatePageUrl('termsandconditions')" class="link-dark  link-offset-2 pointer">Terms and Conditions</a> </span>
            <span> <a role="button" (click)="navigatePageUrl('privacypolicy')" class="link-dark link-offset-2  pointer">Privacy Policy</a> </span>
        </div>
        <div class="socialIcons mt-3">
            <div class="text-dark text-center">Follow us on social @makemydabba</div>
            <div class="d-flex gap-2" style="font-size: 1rem;">
                <span role="button" (click)="openLinkInWindow('facebook.com/makemydabba.fb')"><i class="bi bi-facebook"></i></span>
                <span role="button" (click)="openLinkInWindow('instagram.com/makemydabba')"><i class="bi bi-instagram"></i></span>
            </div>
        </div>
        <hr class="hr-line color-ash">
        <div class="row">
            <small class="">The Make My Dabba &copy; 2023. All rights reserved. </small>
        </div>
    </div>
</footer>