import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { UserService } from '@app/serveces/app/user/user.service';
import { ModelWindowComponent } from '@app/components/model-window/model-window.component';
import { ModalConfig } from '@app/components/model-window/modal.config';
import {  PostalCodeAccess } from '@app/models/user.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  checkPostalCode:FormGroup;
  ModalConfig: ModalConfig = {
    modalTitle: 'Message',
    disableDismissButton: () => {
      return true;
    },
    closeButtonLabel: 'Ok',
  };
  postCodeMessage?:string;

  constructor(private router:Router, private modalService: NgbModal, private formBUilder:FormBuilder, private userService:UserService) {
    this.checkPostalCode = this.formBUilder.group({
      postalCode:["",[Validators.required,Validators.pattern(/^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/)]]
    })
   }
  

  @ViewChild('modal') private modalContent: TemplateRef<any> | undefined;
  @ViewChild('mdWindow') private modelWindowComponent: ModelWindowComponent | any;

  async openModal() {
    return await this.modelWindowComponent.open();
  }

  goTopage:boolean = false;
  private modalRef: NgbModalRef | any
  isPasscodeValid!:PostalCodeAccess;
  postalCodeSubscription$: Subscription | undefined

  ngOnInit(): void {
   this.postalCodeSubscription$ = this.userService.getPostalCodeAccess$.subscribe({
    next:(resp)=>{
       this.isPasscodeValid = resp;
      }
   })
  }

  openWindow(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent,{ backdrop : 'static', keyboard : false})
      this.modalRef.result.then(resolve, resolve)
    })
  }

  closeWindow(reason:string) {
    this.modalRef.dismiss(reason)
  }

  checkDeliverable(paylod:any) {
    if(this.checkPostalCode.valid) {
      this.modalRef.close(true);
      this.userService._checkPostalCode(paylod.postalCode.trim().toUpperCase()).subscribe({
        next:(resp:any)=>{
          this.checkPostalCode.reset();
          const { message, deliverable } = resp;
          const data:PostalCodeAccess = {
            deliverable:deliverable,
            postalCode:paylod.postalCode
          }
          this.userService.setAcitvePostalCode(data)
          if(deliverable==false) {
            this.postCodeMessage = message;
            this.openModal();
          } else {
            this.modalRef.close(true);
            this.goToProduct();
          }
        },
        error:error=>console.log(error)
      })
    }
  }

  goToProduct() {
    const isDeliverable = this.isPasscodeValid?.deliverable || false
    switch (isDeliverable.toString()) {
      case 'false':
        this.openWindow()
        break;
      case 'true':
        this.router.navigate(['menu-items'])  
        break;
    }
  }

  chagePostalCode(e:any) {
    e.target.value = e.target.value.trim().toUpperCase()
    this.checkPostalCode.patchValue({
      postalCode: e.target.value
    })
  }

  ngOnDestroy(): void {
    this.postalCodeSubscription$?.unsubscribe()
  }

}
