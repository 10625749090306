<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="contact-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">FAQ</h2>
        </div>
    </div>
</div>
<div class="container-fluid mt-3 vh-75">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-8 m-auto">
                <div class="aboutus-text-cnt">
                    <h3 class="page-titles-font text-uppercase">Frequently Asked Questions</h3>
                    <h3 class="page-titles-font faq-font-title">How it works?</h3>
                    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                        <ngb-panel title="How do i start ?" id="config-panel-one">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li> <a role="button" (click)="openVideoWindow()" class="link-primary link-offset-2  pointer">View Video</a> </li>
                                    <li> <a role="button" (click)="navigatePageUrl('how-it-works')" class="link-primary link-offset-2  pointer">How it works</a> </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="How much does it cost ?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>Each item has its own price. A dabba consists of one item from the mains, sides, and rice/bread sections. When two full dabbas are ordered for a day, a discount of £1.20 is applied.</li>
                                    <!-- <li>Example  <br>
                                        1 Meal -> £12<br>
                                        2 Meals -> £12 + £10.80 (£12 - 10% discount) = £22.80<br>
                                        3 Meals -> £12 + £21.60 (£24 * 10% discount) = £33.60
                                    </li> -->
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="How do i place an order?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>Step 1 - Click order now and select a day from the next 28 days. Once selected design you Dabba by choosing from Mains, Sides and Accompaniments.</li>
                                    <li>Step 2 - Repeat Step 1 for any other days you wish to order from the next 28 days</li>
                                    <li>Step 3 - Click Place order and proceed to checkout by creating / signing into you Make my dabba account</li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <h3 class="page-titles-font faq-font-title">Changes and cancellations</h3>
                    <ngb-accordion #acc="ngbAccordion">
                        <ngb-panel title="Can I change/cancel a delivery after the cut off time?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        After our order cut off time (8 PM before the delivery day), we place all our orders for the most freshest of produce to bring you your tasty nutritious meals.
                                    </li>
                                    <li>
                                        We also prepare all the admin associated with your order which includes printing, arranging the labels for your meals and planning routes for the drivers ensure timely delivery.
                                    </li>
                                    <li>
                                        Therefore, we are unable to cancel your order after the cut off times due to these costs associated. We hope you will understand that.
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="Can I make changes to my order?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        We do not offer changes given the cancellation is upto 8PM the day before delivery. If you wish to change then you may cancel the order anytime 8PM before the delivery date and place a new order
                                    </li>
                                </ul> 
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <h3 class="page-titles-font faq-font-title">The meals</h3>
                    <ngb-accordion #acc="ngbAccordion">
                        <ngb-panel title="Can I just quickly look up the menu?" >
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        Yes click here to see our <a role="button" (click)="navigatePageUrl('current-menu')" class="link-primary link-offset-2  pointer">current menu</a>
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="Where do you source your food from?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        We source most of our vegetables/meat from reputed suppliers and all the spices from the land of spices (which is INDIA)
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="Do you offer halal food?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        Yes all our meat is Halal certified
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="Can I heat in the microwave?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        Yes our packaging is microwave friendly but we recommend to transfer the food into a microwavable bowl and heat it 
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="What if I have allergies?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        When you are placing an order, all the menu items will have allergen information. We kindly request you to read the allergens for each menu item and choose accordingly
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="What if I have issues with my meals?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        We aim to provide 100% satisfaction guarantee. If there are any issues with your meals, please contact us immediately explaining the issue to us and we will try our best to resolve this for you.
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <h3 class="page-titles-font faq-font-title">Delivery</h3>
                    <ngb-accordion #acc="ngbAccordion">
                        <ngb-panel title="What days do you deliver?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>
                                        Every day 3pm - 7pm (First meal service to do this in UK)
                                    </li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="What areas do you deliver?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>Since we cook everything on the same day, we can only deliver to a certain distance. We can currently deliver only in Leeds and surroundings.</li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="How will my Dabba get delivered?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>Our own delivery driver will drop the Dabba at your door step.</li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="How much is delivery?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>Change this to ‘Our minimum order requirement is £12.00. Currently, we offer free delivery for orders exceeding £12.00. If the minimum order value is not met, the default basket value will be set to £12 to cover any delivery charges.</li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel title="What if I'm not home?">
                            <ng-template ngbPanelContent>
                                <ul class="faq-list">
                                    <li>No problem! When you are placing your order at checkout, you'll be able to specify delivery instructions to the driver such as a safe place.</li>
                                </ul>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                    <div class="my-4 pb-2 text-center">
                        <button type="button" (click)="navigatePageUrl('menu-items')" class="btn btn-round btn-green">Order Now</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
<ng-template #videoCnt>
    <div class="modal-body">
        <iframe style="width: 100%;" height="315" src="https://www.youtube.com/embed/j8sizE2QiMo?si=sPzoFO-4sA8LZ1xV&amp;controls=0" title="Make My Dabba video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
</ng-template>
