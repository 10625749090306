import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, OnDestroy, AfterContentInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { CommonService } from '@app/serveces/app/common/common.service';
import { ModalConfig } from '@app/components/model-window/modal.config';
import { ModelWindowComponent } from '@app/components/model-window/model-window.component';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { MealMenu, Category, Item } from '@app/models/mealMenuItems';
import { GenId, MealType, MealCycle } from '@app/models/app.enum';
import { SelectedCartItems } from '@app/models/mealMenuItems';
import { ServiceDatesService } from '@app/serveces/menu-dates/service-dates.service';
import { UserService } from '@app/serveces/app/user/user.service';
import { PostalCodeAccess } from '@app/models/user.model';
import { HeaderService } from '@app/components/header/header.service';

@Component({
  selector: 'app-product-menu',
  templateUrl: './product-menu.component.html',
  styleUrls: ['./product-menu.component.scss'],
})
export class ProductMenuComponent implements OnInit, AfterContentInit, OnDestroy {
  groupedMenu: any;
  itemsList: any = [];
  weeksList: any;
  toDay: any;
  selectedWeek?: any;
  weekWiseMenuItems: any;
  categoryList: any;
  selectedCategory?: string;
  itemMenuId: any;
  cartTotal: any;
  genId: number = GenId.GENID;
  ModalConfig: ModalConfig = {
    modalTitle: 'Title Here',
    disableDismissButton: () => {
      return true;
    },
    closeButtonLabel: 'Ok',
  };
  buttonText: string = 'Checkout';
  cartItems: any = [];
  cartItemSubscription: Subscription | undefined;
  cartTotalSubscription: Subscription | undefined;
  groupedMenuSubscription: Subscription | undefined;
  groupedMenuServerDataSubscription: Subscription | undefined;
  groupdeMenuServerData:any;
  isShowCartItems:boolean = true;

  constructor(
    private commonService: CommonService,
    private datePipe: DatePipe,
    private router: Router,
    private cartService: CartService,
    private dateList: ServiceDatesService,
    private userService: UserService,
    public headerService:HeaderService
  ) {
    
  }
  ngAfterContentInit(): void {
    this.groupedMenuServerDataSubscription = this.cartService.groupedMenuServerItem$.subscribe({
      next: (resp:any) =>{
        this.groupdeMenuServerData = resp;
      }
    })
  }

  ngOnInit(): void {
    this.buttonText = 'Checkout';
    this.userService.getPostalCodeAccess$.subscribe({
      next: (resp: PostalCodeAccess) => {
        const isDelivarable = resp?.deliverable;
        if (isDelivarable.toString() === 'false') {
          this.router.navigate(['/']);
        }
      },
    });
    
    // this.getCategory();
    this.weeksList = this.dateList.getSevenDayList();
    this.selectedWeek = this.weeksList[0];
    this.cartItemSubscription = this.cartService.cartItems.subscribe({
      next: (resp) => {
        if (resp) {
          this.cartItems = resp.sort(
            (a: any, b: any) =>
              new Date(a.date).getTime() - new Date(b.date).getTime()
          );
        }
      },
    });
    this.cartTotalSubscription = this.cartService.getCartTotal.subscribe({
      next: (resp) => {
        this.cartTotal = resp;
      },
    });
    this.groupedMenuSubscription = this.cartService.groupedMenuItems.subscribe({
      next: (resp: any) => {
          if(resp) {
            this.globalDayWiseMenuData = resp;
            this.getGroupedItemData();
          } else {
              this.getGroupedMenu();
          }
      }
    });
  }

  @ViewChild('mdWindow') private modelWindowComponent:
    | ModelWindowComponent
    | any;
  
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if(window.innerWidth > 992) {
      this.isShowCartItems = true;
    } else {
      this.isShowCartItems = false;
    }
  }

  async openModal() {
    return await this.modelWindowComponent.open();
  }

  addNewMenu(payload: MealMenu) {
    let menuItems = this.globalDayWiseMenuData.find((elm: MealMenu) => {
      return elm.date === payload.date;
    });
    const newPayload = JSON.parse(JSON.stringify(menuItems));
    const newMealItem = {
      ...newPayload,
      genId: payload.genId + 1,
      itemsSelected: 0,
      mealQuantity: 1,
      isLockedItem: false,
      addedNewMeal: false,
    };
    newMealItem.category.map((elm: Category) => {
      elm.selectedCategory = undefined;
      elm.selectedItemImg = undefined;
      elm.selectedItemName = undefined;
      elm.genId = payload.genId + 1;
      elm.items.map((item: Item) => {
        item.genId = payload.genId + 1;
        item.isSelected = false;
      });
    });

    this.globalDayWiseMenuData.push(newMealItem);
    this.globalDayWiseMenuData.map((el: MealMenu) => {
      if (el.date === payload.date && el.genId === payload.genId) {
        el.addedNewMeal = true;
      }
    });
    this.cartService.setGroupedMenuData(this.globalDayWiseMenuData);
  }

  newCartItem(cartItem:any) {
    this.cartItems.push(cartItem);
    this.cartService.setCartItems(this.cartItems)
  }

  updateCartItem(cartItem:any) {
    this.cartService.updateCartItem(cartItem)
  }

  getSelectedWeek(e: any) {
    this.selectedWeek = this.weeksList[e];
    this.getGroupedItemData();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  setUpMenuItemsWithServerData(resp:any) {
    this.createMenuByCartItems(resp);
  }

  getGroupedMenu() {
    // if(this.groupdeMenuServerData) {
    //   this.createMenuByCartItems(JSON.parse(JSON.stringify(this.groupdeMenuServerData)))
    // } else { }
    this.commonService._getGroupedMenuItems().subscribe({
      next: (resp) => {
        // this.cartService.setGroupedMenuServerData(JSON.parse(JSON.stringify(resp)))
        this.createMenuByCartItems(resp)
      },
      error: (error) => {
        console.log(error);
        alert(error.error.message.message);
      },
    });
  }

  mealMenuItems: MealMenu[] = [];
  dayWiseInternalData: MealMenu[] = [];
  globalDayWiseMenuData: MealMenu[] = [];

  getGroupedItemData() {
    this.mealMenuItems = [];
    this.globalDayWiseMenuData.filter((elm: MealMenu) => {
      if (
        elm.date >= this.selectedWeek.start &&
        elm.date <= this.selectedWeek.end
      ) {
        this.mealMenuItems.push(elm);
      }
    });
    this.mealMenuItems = this.mealMenuItems.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    // console.log(this.mealMenuItems)
  }

  getCategory() {
    this.commonService._getCategoryList().subscribe({
      next: (resp) => {
        this.categoryList = resp;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  gotoCheckOut(): void {
    if (this.cartItems.length) {
      this.router.navigate(['checkout']);
    } else {
      alert('Cart should not be empty!!');
    }
  }

  createMenuByCartItems(menuItems:MealMenu[]) {
    let selItems: any = [];
    if(this.cartItems)
    this.cartItems.forEach((cartItem: SelectedCartItems) => {
      menuItems.map((el: MealMenu) => {
        if (el.date == cartItem.date) {
          if (el.genId === cartItem.genId) {
            el.genId = cartItem.genId;
            el.isLockedItem = true;
            el.itemsSelected = cartItem.items?.length;
            el.mealQuantity = cartItem.mealQuantity;
            el.addedNewMeal = true;
            cartItem.items?.forEach((item: any) => {
              el.category.map((cat: any) => {
                cat.selectedCategory = cat.name;
                cat.genId = cartItem.genId
                cat.items.map((ctem: any) => {
                  ctem.genId = cartItem.genId
                  if (item._id === ctem._id) {
                    ctem = item;
                    cat.selectedItemImg = item.photos[0].url;
                    cat.selectedItemName = item.name;
                  }
                });
              });
            });
          } else {
            el = JSON.parse(JSON.stringify(el));
            el.genId = cartItem.genId || 0;
            el.isLockedItem = true;
            el.itemsSelected = cartItem.items?.length;
            el.mealQuantity = cartItem.mealQuantity;
            el.addedNewMeal = true;
            cartItem.items?.forEach((item: any) => {
              el.category.map((cat: any) => {
                cat.selectedCategory = cat.name;
                cat.genId = cartItem.genId
                cat.items.map((ctem: any) => {
                  ctem.genId = cartItem.genId
                  if (item._id === ctem._id) {
                    ctem = item;
                    cat.selectedItemImg = item.photos[0].url;
                    cat.selectedItemName = item.name;
                  }
                });
              });
            });
            selItems.push(el);
          }
        }
      });
      this.cartService.setLocalCart(cartItem);
    });
    selItems.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    // this.cartService.setSelectedMeals(this.cartItems)
    selItems.forEach((el: any) => {
      menuItems.push(el);
    });
    this.dayWiseInternalData = menuItems;
    this.cartService.setGroupedMenuData(menuItems);
  }

  ngOnDestroy(): void {
    this.cartItemSubscription?.unsubscribe();
    this.cartTotalSubscription?.unsubscribe();
    this.groupedMenuSubscription?.unsubscribe();
    this.groupedMenuServerDataSubscription?.unsubscribe();
    this.globalDayWiseMenuData = [];
    this.cartItems = [];
    this.groupdeMenuServerData = [];
  }
}
