<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">User Login / Registration</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-7" style="margin: 0 auto;">
                <!-- <div class="date-select-desg py-3 position-relative">
                    <label class="col-form-label ms-2 page-titles-font set-label-size"> {{ formTitle }}</label>
                </div> -->
                <div class="product-cnt pt-3">
                    <div class="col-md-12" *ngIf="isShowRegister">
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="registerUser" (submit)="_submitUserRegistration(registerUser.value)">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 mb-3">
                                        <label class="form-label">First Name<span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" formControlName="firstName"
                                                placeholder="First Name"
                                                [ngClass]="{'is-invalid': registerUser.controls['firstName'].touched && registerUser.controls['firstName'].errors}" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12 mb-3">
                                        <label class="form-label">Last Name<span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Last Name"
                                                formControlName="lastName"
                                                [ngClass]="{'is-invalid': registerUser.controls['lastName'].touched && registerUser.controls['lastName'].errors}" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Email<span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Email"
                                                formControlName="email"
                                                [ngClass]="{'is-invalid': registerUser.controls['email'].touched && registerUser.controls['email'].errors}" />
                                        </div>
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Phone Number<span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Phone Number"
                                                formControlName="phoneNumber"
                                                [ngClass]="{'is-invalid': registerUser.controls['phoneNumber'].touched && registerUser.controls['phoneNumber'].errors}" />
                                                <label class="invalid-feedback font-sm"*ngIf="registerUser.get('phoneNumber')?.errors?.['pattern']">
                                                    Enter a valid Phone Number
                                                </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Password<span class="text-danger">*</span></label>
                                        <div class="form-group">
                                            <input type="password" class="form-control" placeholder="Password"
                                                formControlName="password"
                                                [ngClass]="{'is-invalid': registerUser.controls['password'].touched && registerUser.controls['password'].errors}" />
                                                <label class="invalid-feedback font-sm" *ngIf="registerUser.get('password')?.errors?.['pattern']">
                                                    Password should be min 8 char, and atleast 1 char of lowercase, uppercase, numbers and symbols
                                                </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" formControlName="acceptPolicy" >
                                    <label class="form-check-label" for="flexCheckDefault">
                                        <span class="text-danger">*</span>By registering you accept our <a role="button" (click)="openPolocyWindow()" class="link-primary">privacy policy</a>
                                    </label>
                                    <label class="invalid-feedback font-sm" *ngIf="registerUser.get('acceptPolicy')?.errors?.['required']">
                                        Accept privacy policy is required
                                    </label>
                                </div>
                                <div class="mt-3 d-flex">
                                    <button type="submit" [disabled]="!registerUser.valid"
                                        class="ms-auto btn btn-round btn-theme-yellow ms-3">Register</button>
                                </div>
                            </form>
                           
                        </div>
                        
                    </div>
                    <div class="me-auto mt-3">Registered User? <a role="button" (click)="openLogin()" class="link-primary">Login Here</a></div>
                    </div>
                    <!--login-->
                    <div class="mt-4" *ngIf="!isShowRegister && !isNewUserRegistered && !openOtpButton">
                        <div class="align-content-center">
                            <div class="col-md-8 col-sm-12 col-lg-9 col-xl-7 ms-auto me-auto">
                                <div class="card">
                                    <div class="card-body">
                                        <form [formGroup]="loginUser" (submit)="_submitUserLogin(loginUser.value)">
                                            <div class="mb-3">
                                                <label for="exampleInputEmail1" class="form-label">Email address<span class="text-danger">*</span></label>
                                                <input type="email" class="form-control" placeholder="Email"
                                                    formControlName="email"
                                                    [ngClass]="{'is-invalid': loginUser.controls['email'].touched && loginUser.controls['email'].errors}">
                                            </div>
                                            <div class="mb-3">
                                                <label for="exampleInputPassword1" class="form-label">Password<span class="text-danger">*</span></label>
                                                <input type="password" class="form-control" placeholder="Password" formControlName="password"
                                                    [ngClass]="{'is-invalid': loginUser.controls['password'].touched && loginUser.controls['password'].errors}">
                                                    <label class="invalid-feedback font-sm" *ngIf="loginUser.get('password')?.errors?.['pattern']">
                                                        Password should be min 8 char, and atleast 1 char of lowercase, uppercase, numbers and symbols
                                                    </label>
                                            </div>
                                            <!-- <div class="mb-3 form-check">
                                        <input type="checkbox" class="form-check-input">
                                        <label class="form-check-label" for="exampleCheck1">Remember</label>
                                    </div> -->
                                            <div class="d-flex">
                                                <button type="submit" [disabled]="!loginUser.valid" class="btn btn-round btn-theme-yellow ms-auto">Login</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="d-flex mt-3">
                                    <div class="me-auto">New User <a role="button" (click)="openRegister()" class="link-primary pointer">Register Here</a></div>
                                    <div class="ms-auto">
                                        <a role="button" (click)="navigatePageUrl('forgotpassword')" class="link-primary pointer">Forgot Password?</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- login end -->
                    <!-- sendOtp-->
                    <div class="mt-4" *ngIf="openOtpButton">
                        <div class="align-content-center">
                            <div class="col-md-8 col-sm-12 col-lg-7 ms-auto me-auto">
                                <div class="card">
                                    <div class="card-body">
                                        <form [formGroup]="loginUser" (submit)="resendOtp()">
                                            <div class="mb-4">
                                                <label for="exampleInputEmail1" class="form-label">Email address<span class="text-danger">*</span></label>
                                                <input type="email" class="form-control" placeholder="Eamil" formControlName="email" [ngClass]="{'is-invalid': loginUser.controls['email'].touched && loginUser.controls['email'].errors}">
                                            </div>
                                            <div class="d-flex">
                                                <button type="submit"  class="btn btn-round btn-theme-yellow ms-auto">SEND OTP</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- sendOtp end -->
                    <user-otp-validate *ngIf="isNewUserRegistered" #input="ngModel" [(ngModel)]="otpNumber" >
                        <div class="d-flex mt-3">
                            <span class="me-auto font-sm" *ngIf="countDownNum > 0" ><small id="showTimer">{{dispTimeFormat}} </small></span>
                            <button role="button" *ngIf="countDownNum === 0" (click)="resendOtp()" class="btn btn-sm btn-theme-yellow me-auto px-3 secondaryButton">Resend Otp</button>
                            <button role="button" (click)="getTheOtpValue()" [disabled]="otpNumber?.length !== 6" class="btn btn-sm btn-theme-yellow ms-auto px-3 secondaryButton">Validate Otp</button>
                        </div>
                    </user-otp-validate>
                </div>
                <hr class="mt-4" *ngIf="!isNewUserRegistered">
                <div class="mt-4" *ngIf="!isNewUserRegistered">
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-outline-danger" style="border-radius: 25px;" (click)="openGoogleLogin()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google me-2"
                                viewBox="0 0 16 16"><path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                            </svg> Login With Google 
                        </button>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <app-product-list></app-product-list>
            </div> -->
        </div>
    </div>
</div>
<ng-template #privacyPolcy>
    <div class="modal-header">
		<h6 class="modal-title" id="modal-basic-title">Privacy Policy for Make My Dabba Ltd.</h6>
		<button type="button" class="btn-close" aria-label="Close" (click)="closePolocyWindow('close window')" ></button>
	</div>
    <div class="modal-body">
        <app-privacy-text></app-privacy-text>
    </div>
</ng-template>