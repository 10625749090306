<div class="col-md-12 desktop-items position-relative" [ngClass]="{'mob-items':!isShowSelectButton}">
    <div class="chef-special-desig" *ngIf="Item.highlighted">{{ Item.highlightedText }}</div>
    <div class="item-border">
        <div class="align-items-center">
            <div class="prod-img" [ngClass]="{'img-ht' : !isShowSelectButton}">
                <img [src]="Item.photos[0].url" alt="product" [ngClass]="{'img-ht' : !isShowSelectButton}" />
            </div>
            <div class="prod-desc p-2">
                <h6 class="position-relative font-size-pro mb-1"><b>{{ Item.name }} </b>
                    <div>
                        <span class="font-mid color-green-text"><b>{{ Item.price | currency:'GBP' }} </b></span>
                        <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==1">
                            <span class="icon-spice" [style.right.px]="3"></span>
                        </span>
                        <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==2">
                            <span class="icon-spice" [style.right.px]="3"></span>
                            <span class="icon-spice" [style.right.px]="20"></span>
                        </span>
                        <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==3">
                            <span class="icon-spice" [style.right.px]="3"></span>
                            <span class="icon-spice" [style.right.px]="20"></span>
                            <span class="icon-spice" [style.right.px]="38"></span>
                        </span>
                    </div>
                </h6>
                <div class="calc-items">
                    <!-- <p class="item-descp mb-0"> {{ Item.description }} </p> -->
                    <p class="item-descp mb-0">{{ (Item.description.length > 85) ? (Item.description | slice:0:85)+'... ': (Item.description) }}
                        <span role="button" style="text-decoration: underline;" class="ms-1" *ngIf="Item.description.length > 85" placement="bottom" ngbPopover="{{Item.description}}">Read full</span>
                    </p>
                </div>
                <div class="mt-2">
                    <i class="bi bi-info-circle me-2" style="font-size: 0.75rem"></i>
                    <span role="button" (click)="allergenInfo($event)" style="font-size: 0.75rem"
                        class="font-sm allergen">Allergen info</span>
                    <div class="hide-alg">
                        <p class="m-0"><small>{{ Item.allergen || 'Not applicable' }}</small></p>
                    </div>
                </div>

                <div class="my-2 text-center" *ngIf="isShowSelectButton && Item.quantity == 0">
                    <button type="button" *ngIf="Item.isSelected == false" class="btn btn-round btn-small btn-green"
                        (click)="selectItem(Item)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                            class="bi bi-cart-plus-fill me-1" viewBox="0 0 16 16">
                            <path
                                d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                        </svg>Add
                    </button>
                </div>
                <div class="item-cnt-mob d-flex m-auto my-2" style="width: 65%; border: 1px solid #d7d9b8;" *ngIf="isShowSelectButton && Item.quantity !== 0">
                    <span class="input-group-text-mob col-3 text-center" (click)="updateCartByItem(Item)" role="button">
                        <i class="bi bi-dash font-14-btn"></i></span>
                    <span class="col-6 m-auto text-center font-sm">{{Item.quantity}}</span>
                    <span class="input-group-text-mob col-3 text-center" role="button" (click)="selectItem(Item)">
                        <i class="bi bi-plus font-14-btn"></i>
                    </span>
                </div>
            </div>
            
            <!-- <div class="button-block" *ngIf="isShowSelectButton && Item.quantity !== 0">
                <div class="input-group button-size p-0 m-auto">
                    <span class="input-group-text" (click)="updateCartByItem(Item)" role="button"><i
                            class="bi bi-dash font-14-btn"></i></span>
                    <input type="text" #inputValue [readOnly]="true" value="{{Item.quantity}}"
                        class="form-control px-2 font-sm text-center" />
                    <span class="input-group-text" role="button" (click)="selectItem(Item)">
                        <i class="bi bi-plus font-14-btn"></i>
                    </span>
                </div>
            </div> -->
        </div>
    </div>
</div>
<div class="col-md-12 mob-items position-relative" [ngClass]="{'desktop-items' : !isShowSelectButton}">
    <div class="chef-special-desig" *ngIf="Item.highlighted">{{ Item.highlightedText }}</div>
    <div class="d-flex">
        <div class="col-8">
            <div class="prod-mob-dec">
                <h6><b>{{ Item.name }} </b></h6>
                <div class="d-flex">
                    <span style="font-size: 0.75rem;" class="me-auto color-green-text"><b>{{ Item.price | currency:'GBP' }} </b></span>
                    <div class="ms-auto position-relative">
                        <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==1">
                            <span class="icon-spice" [style.right.px]="3"></span>
                        </span>
                        <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==2">
                            <span class="icon-spice" [style.right.px]="3"></span>
                            <span class="icon-spice" [style.right.px]="20"></span>
                        </span>
                        <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==3">
                            <span class="icon-spice" [style.right.px]="3"></span>
                            <span class="icon-spice" [style.right.px]="20"></span>
                            <span class="icon-spice" [style.right.px]="38"></span>
                        </span>
                    </div>
                </div>
                <div class="item-mob-dec">
                    <p style="font-size: 0.7rem;" class="mb-2" >{{ (Item.description.length > 60)? (Item.description | slice:0:60)+'...': (Item.description) }}
                        <span role="button" style="text-decoration: underline;" class="ms-1" *ngIf="Item.description.length > 60" placement="bottom" ngbPopover="{{Item.description}}">Read full</span>
                    </p>
                    <div>
                    <i class="bi bi-info-circle me-2" style="font-size: 0.75rem"></i>
                    <span role="button" placement="bottom" ngbPopover="{{ Item.allergen || 'Not applicable' }}" class="allergen" style="font-size: 0.75rem;">Allergen info</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="prod-mob-img position-relative" [ngClass]="{'img-ht' : !isShowSelectButton}">
                <img [src]="Item.photos[0].url" alt="product" [ngClass]="{'img-ht' : !isShowSelectButton}" />
                <div class="position-absolute item-cnt-mob d-flex" *ngIf="isShowSelectButton && Item.quantity !== 0">
                    <span class="input-group-text-mob col-3 text-center" (click)="updateCartByItem(Item)" role="button">
                        <i class="bi bi-dash font-14-btn"></i></span>
                    <span class="col-6 m-auto text-center font-sm">{{Item.quantity}}</span>
                    <span class="input-group-text-mob col-3 text-center" role="button" (click)="selectItem(Item)">
                        <i class="bi bi-plus font-14-btn"></i>
                    </span>
                </div>
                <div style="background: transparent; border: none;" class="position-absolute item-cnt-mob text-center" *ngIf="isShowSelectButton && Item.quantity == 0">
                    <button type="button" *ngIf="Item.isSelected == false" class="btn btn-round btn-small btn-green"
                        (click)="selectItem(Item)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-cart-plus-fill me-1" viewBox="0 0 16 16"> 
                            <path  d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                        </svg>Add
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>