<ng-template #modal>
    <div class="modal-header">
      <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <!-- <div class="modal-footer">
      <button type="button"
              class="btn btn-outline-secondary"
              (click)="dismiss()"
              *ngIf="!modalConfig.disableDismissButton()"
             >
        {{ modalConfig.dismissButtonLabel }}
      </button>
      <button type="button"
              class="btn btn-round btn-theme-yellow"
              (click)="close()"
              *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
              [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
        {{ modalConfig.closeButtonLabel }}
      </button>
    </div> -->
  </ng-template>