import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { User } from '@app/models/user.model';
import { CartService } from '../app/cart/cart.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private BASE_URL = environment.apiBaseUrl;

  private userSubject: BehaviorSubject<any | null>;
  public userToekn: Observable<any | null>;

  private userInfoSubject: BehaviorSubject<User | any>;
  public userInfo: Observable<User | any>;

  constructor(private httpService: HttpService, private router: Router, private cartService:CartService) {
    this.userSubject = new BehaviorSubject(
      sessionStorage.getItem('accessToken')
    );
    this.userToekn = this.userSubject.asObservable();
    this.userInfoSubject = new BehaviorSubject(
      sessionStorage.getItem('userInfo')
    );
    this.userInfo = this.userInfoSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  public get userInfoData() {
    return this.userInfoSubject.value;
  }

  authorizeUser(user: any) {
    const url = `${this.BASE_URL}/user/signin`;
    return this.httpService.postService(url, user).pipe(
      map((resp:any) => {
        const {firstName,lastName,email,phoneNumber,tokens,tokenExpiresIn,addresses} = resp
        const user: User = {
          firstName,
          lastName,
          email,
          phoneNumber,
          address:addresses
        }
        this.setUserTokens(tokens.accessToken,tokens.refreshToken);
        sessionStorage.setItem('userInfo', JSON.stringify(user));
        this.userInfoSubject.next(JSON.stringify(user))

        // this.startRefreshTokenTimer(tokenExpiresIn);
        return resp;
      })
    );
  }

  setUserTokens(accessToken:string,refreshToken:string) {
    localStorage.setItem('refToken', refreshToken);
    sessionStorage.setItem('accessToken',accessToken);
    this.userSubject.next(accessToken);
  }

  setUserProfile(user:User) {
    this.userInfoSubject.next(JSON.stringify(user))
  }

 setUserInfo(resp:any) {
    const {firstName,lastName,email,phoneNumber,tokens,tokenExpiresIn,addresses} = resp
    const user: User = {
      firstName,
      lastName,
      email,
      phoneNumber,
      address:addresses
    }
    this.setUserTokens(tokens.accessToken,tokens.refreshToken);
    sessionStorage.setItem('userInfo', JSON.stringify(user));
    this.userInfoSubject.next(JSON.stringify(user))
  }

  getUserInfo(){
    const url = `${this.BASE_URL}/user/whoami`;
    return this.httpService.getService(url).pipe(
      map((resp:any)=>{
        const {firstName,lastName,email,phoneNumber,addresses} = resp
        const user: User = {
          firstName,
          lastName,
          email,
          phoneNumber,
          address:addresses
        }
        sessionStorage.setItem('userInfo', JSON.stringify(user));
        this.userInfoSubject.next(JSON.stringify(user));
        return resp;
      })
    )
  }

  updateUserInfo(userInfo:any) {
    const {firstName,lastName,email,phoneNumber,addresses} = userInfo
      const user: User = {
        firstName,
        lastName,
        email,
        phoneNumber,
        address:addresses
      }
      sessionStorage.setItem('userInfo', JSON.stringify(user));
      this.userInfoSubject.next(JSON.stringify(user));
  }

  refreshToken() {
    const url = `${this.BASE_URL}/user/refresh`;
    this.userSubject.next(localStorage.getItem('refToken'));
    return this.httpService.postService(url,null).pipe(
      tap((resp:any) => {
       const { accessToken } = resp;
        sessionStorage.setItem('accessToken',accessToken);
        this.userSubject.next(sessionStorage.getItem('accessToken'));
      })
    );
  }

  logOut(state:any) {
    this.stopRefreshTokenTimer();
    // this.cartService.cartTotal?.next(null);  // removed for testing
    // this.cartService.cartItems?.next(null);  // removed 
    // this.cartService.cartItem?.next(null);  // removed
    localStorage.removeItem('refToken');
    // localStorage.removeItem('cartItems');  // removed
    localStorage.removeItem('loginUserCartInfo');
    localStorage.removeItem('cartItemsList');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('userSavedCartInfo');
    this.userSubject?.next(null);
    this.userInfoSubject?.next(null);
    // this.router.navigate(['/']);
    this.router.navigate(['userlogin'], { queryParams: { returnUrl: state.url }})

  }

  private refreshTokenTimeout: any;

  private startRefreshTokenTimer(time:number) {
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      time
    );
    console.log(this.refreshTokenTimeout)
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
