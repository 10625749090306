import { Injectable } from '@angular/core';
import { HttpService } from '@app/serveces/http/http.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, map, tap, Subject } from 'rxjs';
import { Item, SelectedCartItems } from '@app/models/mealMenuItems';
import { MealMenu } from '@app/models/mealMenuItems';
import { cartItems, menuItems,cartItem, cartTotalModel, cancelDayItem } from '@app/models/cart.model';
import { MealType } from '@app/models/app.enum'
import { AddressPayload, UserAddress } from '@app/models/user.model';
import { couponCode } from '@app/models/cart.model';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';




@Injectable({
  providedIn: 'root',
})
export class CartService {
  private BASE_URL = environment.apiBaseUrl;
  public cartItems: BehaviorSubject<any | null>;
  public cartTotal: BehaviorSubject<any | null>;
  public getCartTotal:Observable<cartTotalModel|null>
  public groupedMenuData: BehaviorSubject <any | null >
  public groupedMenuItems:Observable<any | null >
  private cartItemList:any = [];
  private selMeals:SelectedCartItems | any = [];
  public selectedMeals:BehaviorSubject<SelectedCartItems | any  >
  public getSelectedMeals:Observable<SelectedCartItems> 

  public delvetyAddress$:BehaviorSubject<UserAddress | any>
  public getDelvryAddress$:Observable<any>
  private cartDiscountAppled$:BehaviorSubject<couponCode | any>
  public getDiscountApplied$:Observable<any>

  private groupedMenuServerData$:BehaviorSubject<any | null>
  public groupedMenuServerItem$:Observable<any>
  
  //safe area input
  private safeAreaText = new Subject<string>()

  //accept terms and conditions
  private isAceptedTermsandC$ = new BehaviorSubject<boolean>(false);
  public isTandCn$ = this.isAceptedTermsandC$.asObservable();

  //applied discount coupon
  private discontCouponObj$ = new BehaviorSubject<any>(null);

  constructor(private httpService: HttpService, private datePipe:DatePipe, private authService: AuthenticationService) {
    let cart: any = localStorage.getItem('cartItems');
    if(cart && cart !== null) {
      let date:any = new Date();
      var nextDay = new Date(new Date(date).setDate(date.getDate()+1));
      date = this.datePipe.transform(nextDay,'yyyy-MM-dd');
      cart = JSON.parse(cart)
      cart = cart?.filter((el:any)=>{
        return el.date >= date
      })
    } else {
      cart = [];
    }
    this.cartItems = new BehaviorSubject(cart);
    this.getDiscountPricing();
    // localStorage.setItem('cartItems',JSON.stringify(cart));
    this.cartItems.asObservable();
    this.cartTotal = new BehaviorSubject(null);
    this.getCartTotal = this.cartTotal.asObservable();
    this.groupedMenuData = new BehaviorSubject('');
    this.groupedMenuItems = this.groupedMenuData.asObservable();
    this.selectedMeals = new BehaviorSubject(this.selMeals);
    this.getSelectedMeals = this.selectedMeals.asObservable();
    this.delvetyAddress$ = new BehaviorSubject(null);
    this.getDelvryAddress$ = this.delvetyAddress$.asObservable();
    this.cartDiscountAppled$ = new BehaviorSubject(null);
    this.getDiscountApplied$ = this.cartDiscountAppled$.asObservable();

    this.groupedMenuServerData$ =  new BehaviorSubject('');
    this.groupedMenuServerItem$ = this.groupedMenuServerData$.asObservable();

    // this.sumCartItems();
  }

  createCart() {
    const cartItems:cartItem[] = [];
    this.cartItem.map((cartItem:any)=>{
      const {date,items, mealQuantity, menuId} = cartItem;
      const menuItems:menuItems[] = []
      items.filter((item:Item)=>{
        const { _id } = item
        const menuItem:menuItems = {
          menuItem:_id,
          quantity:item.quantity
        }
        menuItems.push(menuItem)
      })
      const cartitem:cartItem = {
        date,
        mealMenuQuantity:mealQuantity,
        mealMenu:menuId,
        menuItems:menuItems,
        type:MealType.DINNER
      }
      cartItems.push(cartitem)
    })
    const cartItemList:cartItems = {
      cartItems:cartItems
    }
    // localStorage.setItem('cartItemsList',JSON.stringify(cartItemList))
    const url = `${this.BASE_URL}/cart`;
    return this.httpService.postService(url,cartItemList);
  }

  updateCartItem(Item:any) {
    const findIndex = this.cartItem.findIndex((el:any)=>{
      return el.genId === Item.genId && el.date === Item.date
    })
    this.cartItem.splice(findIndex,1,Item)
    // this.sumCartItems();
    this.cartItems.next(this.cartItem);
    this.getDiscountPricing()
    this.cartDiscountAppled$.next(null)
    this.discontCouponObj$.next(null);
    localStorage.setItem('cartItems', JSON.stringify(this.cartItem));
  }

  updateDayWisePricing(resp:any) {
    //cart menuId  rep- mealMenu
    resp.orderItems.forEach((el:any)=>{
      this.cartItem.map((catItem:SelectedCartItems)=>{
        if(el.date === catItem.date && el.mealMenu === catItem.menuId) {
          catItem.deliveryCharge = el.deliveryCharge;
          catItem.finalTotal = el.finalTotal;
          catItem.itemsTotal = el.itemsTotal;
          catItem.fullMealCount= el.fullMealCount;
        }
      })
    })
    this.cartItems.next(this.cartItem);
    localStorage.setItem('cartItems', JSON.stringify(this.cartItem));
  }
  

  setCartItems(cartItem: any) {
    // console.log(cartItem)
    // console.log(this.cartItemList) 
    // this.cartItemList.push(cartItem);
    // console.log(this.cartItemList);
    this.cartItems.next(cartItem);
    localStorage.setItem('cartItems', JSON.stringify(cartItem));
    // this.sumCartItems()
    const loginUser = sessionStorage.getItem('accessToken');
    this.getDiscountPricing();
    this.cartDiscountAppled$.next(null);
    this.discontCouponObj$.next(null);
    if(loginUser) {
      this.createCart()
    }
  }

  setGroupedMenuServerData(resp:any) {
    this.groupedMenuServerData$.next(resp);
  }

  setLocalCart(cartItem:any) {
    this.cartItemList = [];
    this.cartItemList.push(cartItem)
  }

  deleteCartItems() {
    this.cartItemList = [];
    this.cartItems.next(null);
    localStorage.removeItem('cartItems')
  }

  emptyUserCartItems() {
    const url = `${this.BASE_URL}/cart`;
    return this.httpService.deleteService(url).pipe(tap(el=>console.log(el)))
  }

  setSelectedMeals(selecteMeals:SelectedCartItems[]){
    this.selectedMeals.next(selecteMeals);
  }

  setDelveryAddress(address:AddressPayload) {
    this.delvetyAddress$.next(address)
  }

  setSelectedDiscountCoupon(coupon:any) {
    this.discontCouponObj$.next(coupon)
  }

  public get appliedCouponObj() {
    return this.discontCouponObj$.value;
  }

  removeDelveryAddress() {
    this.delvetyAddress$.next(null)
  }

  removeCartItems(cartItem:any) {
    let cartIndex = this.cartItem.findIndex((elm:any)=>{
      return  elm.date === cartItem.date
      })
      
      let findIndex =  this.selectedItem.findIndex((elm:any)=>{
      return  elm.date === cartItem.date
      })
      
      this.cartItem.splice(cartIndex,1);
      this.selectedItem.splice(findIndex,1);
      this.cartItems.next(this.cartItem);

      localStorage.setItem('cartItems', JSON.stringify(this.cartItem));
      let cart: any = localStorage.getItem('cartItems');

      this.cartItemList = null;
      this.cartItemList = JSON.parse(cart)
      this.getDiscountPricing();
      this.cartDiscountAppled$.next(null);
      this.discontCouponObj$.next(null);
      // this.sumCartItems()
      const loginUser = sessionStorage.getItem('accessToken');
      if(loginUser) {
        this.createCart()
      } 
  }

  sumCartItems() {
    let sum = 0;
    if(this.cartItem) {
      this.cartItem.map((el: any) => {
        sum += el.mealPrice * el.mealQuantity;
      });
    }
    this.cartTotal.next(sum);
  }

  public get cartItem() {
    return this.cartItems.value;
  }

  public get selectedItem() {
    return this.selectedMeals.value;
  }

  getDiscountAmount(code:string) {
    const url = `${this.BASE_URL}/discount/discount-amount?code=${code}&totalAmt=${this.cartTotal.value.finalAmount}`;
    return this.httpService.getService(url)
  }

  setGroupedMenuData(data:any) {
    this.groupedMenuData.next(data)
  }

  _getAllCouponCodes() {
    const url = `${this.BASE_URL}/discount`;
    return this.httpService.getService(url)
  }

  setDiscountCoupon(coupon:any) {
    this.cartDiscountAppled$.next(coupon)
  }

  removeCouponCode() {
    const cartTotal = this.cartTotal.value;
    this.cartDiscountAppled$.next(null);
    this.discontCouponObj$.next(null);
    this.cartTotal.next(cartTotal);
  }

  addSafeArea(text:string) {
    this.safeAreaText.next(text);
  }

  getSafeArea() {
    return this.safeAreaText.asObservable();
  }

   getDiscountPricing() {
    const url = `${this.BASE_URL}/cart/pricing`;
    if(this.cartItem && this.cartItem.length) {
      const cartItems:cartItem[] = [];
      this.cartItem.map((cartItem:any)=>{
        const {date,items, mealQuantity, menuId} = cartItem;
        const menuItems:menuItems[] = []
        items.filter((item:Item)=>{
          const { _id } = item
          const menuItem:menuItems = {
            menuItem:_id,
            quantity:item.quantity
          }
          menuItems.push(menuItem)
        })
        const cartitem:cartItem = {
          date,
          mealMenuQuantity:mealQuantity,
          mealMenu:menuId,
          menuItems:menuItems,
          type:MealType.DINNER
        }
        cartItems.push(cartitem)
      })
      const cartItemList:cartItems = {
        cartItems:cartItems
      }
      this.httpService.postService(url,cartItemList).subscribe({
        next:(resp)=>{
          this.cartTotal.next(resp);
          this.updateDayWisePricing(resp);
        },
        error:err=>this.cartTotal?.next(null)
      })
    } else {
      this.cartTotal?.next(null)
    }
    
  }

  setIsAcpCnd(value:boolean) {
    this.isAceptedTermsandC$.next(value);
  }

  cancelDayOrder(payload:cancelDayItem) {
    const url = `${this.BASE_URL}/order/${payload.orderId}/date/${payload.date}/cancel`;
    return this.httpService.postService(url,'');
  }


  closeAllObservables() {
    this.cartDiscountAppled$.next(null);
    this.groupedMenuData.next(null)
    this.cartItems.next([]);
    this.delvetyAddress$.next(null);
    this.cartItemList = []
    this.selectedMeals.next([]);
    this.cartTotal?.next(null);
    this.isAceptedTermsandC$?.next(false);
    this.discontCouponObj$?.next(null)
    // this.cartTotal?.complete();
    localStorage.removeItem('loginUserCartInfo');
  }

  clearLogoutObservables() {
    this.delvetyAddress$?.next(null);
    this.discontCouponObj$?.next(null);
    this.cartDiscountAppled$?.next(null);
  }



}
