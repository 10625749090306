import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }

  openLinkInWindow(link:string) {
    const url = `https://www.${link}`;
    window.open(url,'_blank');
  }

  navigatePageUrl(url:string) {
    this.router.navigate([url])
  }

}
