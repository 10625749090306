import { Injectable } from '@angular/core';
import { Toaster, ToasterOptions, ToasterType } from '@app/models/toaster.model';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor() { }

  private subject = new Subject<Toaster>();
  private defaultId = 'default-alert';

     // enable subscribing to alerts observable
     onAlert(id = this.defaultId): Observable<Toaster> {
      return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }
  
  // convenience methods
  success(message: string, options?: ToasterOptions) {
      this.alert(new Toaster({ ...options, type: ToasterType.Success, message }));
  }
  
  error(message: string, options?: ToasterOptions) {
      this.alert(new Toaster({ ...options, type: ToasterType.Error, message }));
  }
  
  info(message: string, options?: ToasterOptions) {
      this.alert(new Toaster({ ...options, type: ToasterType.Info, message }));
  }
  
  warn(message: string, options?: ToasterOptions) {
      this.alert(new Toaster({ ...options, type: ToasterType.Warning, message }));
  }
  
  // main alert method    
  alert(alert: Toaster) {
      alert.id = alert.id || this.defaultId;
      this.subject.next(alert);
  }
  
  // clear alerts
  clear(id = this.defaultId) {
      this.subject.next(new Toaster({ id }));
  }
}
