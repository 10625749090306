import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Toaster, ToasterType } from '@app/models/toaster.model';
import { ToasterService } from './toaster.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit,OnDestroy {

  toasters: Toaster[] = [];
  toasterSubscription!: Subscription;
  routeSubscription!: Subscription;
  @Input() id = "default-alert";
  @Input() fade = true;

  constructor(private router: Router, private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.toasterSubscription = this.toasterService
      .onAlert(this.id)
      .subscribe((alert) => {
        if (!alert.message) {
          this.toasters = this.toasters.filter((x) => x.keepAfterRouteChange);
          this.toasters.forEach((x) => delete x.keepAfterRouteChange);
          return;
        }
        this.toasters.push(alert);
        if (alert.autoClose) {
          setTimeout(() => this.removeAlert(alert), 6000);
        }
      });

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.toasterService.clear(this.id);
      }
    });
  }


  ngOnDestroy() {
    this.toasterSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Toaster) {
    if (!this.toasters.includes(alert)) return;

    if (this.fade) {
      alert.fade = true;

      setTimeout(() => {
        this.toasters = this.toasters.filter((x) => x !== alert);
      }, 250);
    } else {
      this.toasters = this.toasters.filter((x) => x !== alert);
    }
  }

  cssClass(toaster: Toaster) {
    if (!toaster) return;

    const classes = ['alert', 'alert-dismissible', 'mt-2', 'container', 'col-md-6', 'col-lg-8', 'col-xl-8', 'font-sm'];
            
    const alertTypeClass = {
        [ToasterType.Success]: 'alert-success',
        [ToasterType.Error]: 'alert-danger',
        [ToasterType.Info]: 'alert-info',
        [ToasterType.Warning]: 'alert-warning'
    }

    if (toaster.type !== undefined) {
        classes.push(alertTypeClass[toaster.type]);
    }

    if (toaster.fade) {
        classes.push('fade');
    }

    return classes.join(' ');
}

}
