import { Injectable } from '@angular/core';
import { Category, Item, MealMenu, SelectedCartItems } from '@app/models/mealMenuItems';
import { CartService } from '../cart/cart.service';
import { CategoryNames } from '@app/models/app.enum';


@Injectable({
  providedIn: 'root',
})
export class CartItemService {
  constructor(private cartService: CartService) {}

  createCartItem(item: Item, cartItems: SelectedCartItems[],globalMenuData:MealMenu[]) {

    switch (item.catName) {
      case CategoryNames.MAIN:
        item.sortNumber = 1;
        break;
      case CategoryNames.SIDES:
        item.sortNumber = 2;
        break;
      case CategoryNames.BREAD:
        item.sortNumber = 3;
        break;
    }
    
    const cartInf = cartItems?.find(
      (el: SelectedCartItems): SelectedCartItems | any => {
        if (el.genId === item.genId && el.date === item.date) {
          return el;
        }
      }
    );

    if (cartInf) {
      const cartIndex = cartItems.findIndex(
        (el: SelectedCartItems): SelectedCartItems | any => {
          return el.genId === item.genId && el.date === item.date;
        }
      );

      let findItem = cartInf.items?.find((ct: Item): Item | any => {
        if (ct._id === item._id && ct.date === item.date) {
          ct.quantity = ct.quantity + 1;
          return ct;
        }
      });

      if (findItem) {
        cartItems.splice(cartIndex, 1, cartInf);
        this.cartService.setCartItems(cartItems);
      } else {
        item.quantity = 1;
        cartInf.items?.push(item);
        cartItems.splice(cartIndex, 1, cartInf);
        this.cartService.setCartItems(cartItems);
      }
    } else {
      item.quantity = 1;
      let selectedItems: SelectedCartItems = {
        date: item.date,
        genId: item.genId,
        mealQuantity: 1,
        mealPrice: item.mealPrice,
        items: [item],
        menuId: item.menuId,
      };
      cartItems.push(selectedItems);
      this.cartService.setCartItems(cartItems);
    }
    this.createMenuByCartItems(cartItems,globalMenuData);
  }

  updateCartItem(item: Item, cartItems: SelectedCartItems[],globalMenuData:MealMenu[]) {
    const cartItem = cartItems.find(
      (el: SelectedCartItems) => el.date === item.date
    );
    cartItem?.items?.map((el: Item) => {
      if (el._id === item._id) {
        el.quantity = el.quantity - 1;
      }
    });
    const selItem = cartItem?.items?.find((el: Item) => el._id === item._id);
    this.updateGlobalMenu(globalMenuData,selItem);
    if (selItem?.quantity !== 0) {
      this.cartService.updateCartItem(cartItem);
    } else if (cartItem?.items?.length) {
      const findIndex = cartItem.items.findIndex(
        (el: Item) => el._id === item._id
      );
      cartItem.items?.splice(findIndex, 1);
      if (cartItem.items.length) {
        this.cartService.updateCartItem(cartItem);
      } else {
        this.cartService.removeCartItems(cartItem);
      }
    }

    
  }

  createMenuByCartItems(cartItems:SelectedCartItems[],menuItems:MealMenu[]) {
    if(cartItems && menuItems) {
      cartItems.forEach((cartItem: SelectedCartItems) => {
        menuItems.map((menu: MealMenu) => {
          if (menu.date == cartItem.date) {
            cartItem.items?.forEach((cartItem:Item)=>{
              menu.category.map((category:Category)=>{
                category.items.map((menuItem:Item)=>{
                  if(cartItem._id === menuItem._id) {
                    menuItem.quantity = cartItem.quantity
                    menuItem.catName = cartItem.catName
                  } 
                })
              })
            })
          }
        });
      });
      this.cartService.setGroupedMenuData(menuItems);
    }
  }

  updateGlobalMenu(globalMenuData:MealMenu[],selItem:Item | any) {
    if(globalMenuData && selItem) {
        globalMenuData.map((menu:MealMenu)=>{ 
          if(menu.date === selItem.date) {
            menu.category.map((cat:Category) => {
              if(cat.name === selItem.catName) {
                cat.items.map((dayItem:Item)=> {
                  if(dayItem._id === selItem._id) {
                      dayItem.quantity = selItem.quantity || 0
                  }
                })
              }
            })
          }
        });
      this.cartService.setGroupedMenuData(globalMenuData);
    }
    
  }


}
