import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Router } from '@angular/router';
import { ToasterService } from '@app/components/toaster/toaster.service';
import { resendOtp } from '@app/models/user.model';
import { UserService } from '@app/serveces/app/user/user.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassword:FormGroup

  constructor(private formBUilder:FormBuilder, private toaster:ToasterService, private userService:UserService, private router:Router) { 
    this.forgotPassword = this.formBUilder.group({
      email: ["",[Validators.required,Validators.email]]
    })
  }

  ngOnInit(): void {
  }

  validateUserEmail(payload:resendOtp) {
    if(this.forgotPassword.valid) {
      // console.log(payload)
      this.userService._forgotPasswordLink(payload).subscribe({
        next:(resp:any)=>{
          this.toaster.success(resp.message,{autoClose:true})
          this.forgotPassword.reset();
          setTimeout(() => {
            this.router.navigate(['userlogin'])
          }, 3000);
         },
        error:(err:any)=> {
          if(err && err.message) {
            this.toaster.error(err.message,{autoClose:true})
          } else {
            this.toaster.error('unknown error',{autoClose:true})
          }
        }
      })
    } else {
      this.toaster.error('Required fields should not be empty!',{autoClose:true})
    }
  }

}
