<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="contact-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">How It Works</h2>
        </div>
    </div>
</div>
<div class="container-fluid vh-75">
    <div class="container">
        <div class="my-3">
            <h2 class="page-headings text-center pt-sm-3 pt-lg-3 pt-xl-5 mb-md-5 mb-sm-3">How it works?</h2>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-10 m-auto">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/design-order.png" alt="design order" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">1.Design & Order</h5>
                            <p class="medium-font p-2">Design your Dabba by choosing 1 Main , 1 Side and 1 Rice/Bread. Repeat the above for any extra dabbas you want from the next 28 days</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/freshly-cooked.png" alt="freshly cooked" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">2.Freshly Cooked</h5>
                            <p class="medium-font p-2">We freshly cook your daily orders on the same day, delightfully crafted flavours, just for you!</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/deliveredToDoorstep.jpg" alt="delivered doorstep" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">3.Delivered To Your Doorstep</h5>
                            <p class="medium-font p-2">Let us bring the delight of Make my dabba to your doorstep</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/enoy-meals.png" alt="enjoy meals" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">4.Enjoy Fresh Meals</h5>
                            <p class="medium-font p-2">You enjoy the fresh and authentic Indian meals, with no cooking or clean-up needed</p>
                        </div>
                    </div>
                    
                </div>
                <div class="mt-5 pb-2 text-center">
                    <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                </div>
            </div>
        </div>
    </div>
</div>
