<div class="polocy-cnt">
    <p>
        Welcome to Make My Dabba Ltd ("we," "us," or "Make My Dabba"). These terms and conditions ("Terms") govern your use of our website and services. By accessing or using our website and services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website or services.
    </p>
    <p>
        We reserve the right to change, modify or withdraw the promo code incentives at any time without any prior notice. The details of the scheme are not a contract, nor is it implied to be one. We may amend these Terms & Conditions from time to time. Every time you order Products from us, the Terms & Conditions in force at that time will apply to the Contract between you and us.
    </p>
    <p>
        <b>1. General</b>
    </p>
    <p>
        1.1. Make My Dabba Ltd is an online platform that offers food ordering and delivery services from our own kitchen.
    </p>
    <p>
        1.2. Male My Dabba is a Limited company registered in England and Wales, registration number 14803370, our registered office is: 45 Wolfenden Way, Wakefield, England, WF1 3FA.
    </p>
    <p>
        1.3. Users must create an account to place orders through our website. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
    </p>
    <p>
        <b>2. Our Products</b>
    </p>
    <p>
        2.1. The images of the Products on our Website are for illustrative purposes only. Although we have made every effort to display to the closest match, we cannot guarantee that your computer's display of the colours accurately reflects the colour of the products. Your products may vary from the displayed images. <br>
        2.2. The packaging of the Products may vary from that shown on images on our website.<br>
        2.3. All Products shown on our website are subject to availability.<br>
        2.4. Under certain circumstances we reserve the right to substitute certain products which may form part of your order.
    </p>
    
    <p>
        <b>3. Use of our Website and Privacy Information</b>
    </p>
    <p>
        3.1. We ensure that we abide with applicable law regarding your personal data. Please see our Privacy Policy for more information. <br>
        3.2. Please take the time to read these Policies as they include important terms which apply to you.
    </p>
    
    <p>
        <b>4. Orders and Delivery</b>
    </p>
    <p>
        4.1. Users can place orders for food and beverages through our website. Orders are subject to availability. <br>
        4.2. Make My Dabba Ltd reserves the right to accept or reject orders at its discretion.<br>
        4.3. We may offer substitutions for certain items based on availability, and we will attempt to notify you in such cases.<br>
        4.4. Delivery times are estimates and may vary due to factors beyond our control, including traffic, weather, and kitchen preparation times.
    </p>
    <p>
        <b>5. Payments</b>
    </p>
    <p>
        5.1. Users agree to pay the total amount indicated for each order, including food costs, delivery fees, and applicable taxes.<br>
        5.2. Payments are processed through secure third-party payment gateways. Make My Dabba Ltd does not store or handle credit card information.
    </p>
    <p>
        <b>6. Content and Use</b>
    </p>
    <p>
        6.1. Users agree not to use our website or services for any unlawful or unauthorized purposes.<br>
        6.2. Users are solely responsible for the accuracy and legality of content they submit, including reviews and comments.
    </p>
    <p>
        <b>7. Intellectual Property</b>
    </p>
    <p>
        7.1. The content on our website, including text, graphics, logos, and images, is protected by copyright and other intellectual property laws.<br>
        7.2. Users may not reproduce, modify, distribute, or otherwise use our content without written permission from Make My Dabba Ltd.
    </p>
    <p>
        <b>8. Limitation of Liability</b>
    </p>
    <p>
        8.1. Make My Dabba Ltd is not liable for any direct, indirect, incidental, special, or consequential damages arising from the use of our website or services.
    </p>
    <p>
        <b>9. Events Outside Our Control</b>
    </p>
    <p>
        9.1. We shall not be liable for any failure to perform or delay in performing our obligations due to events beyond our control, including but not limited to strikes, natural disasters, or governmental actions.
    </p>
    <p>
        <b>10. Promotional Offers and Price Changes</b>
    </p>
    <p>
        10.1. From time to time, we may offer promotional discounts or offers. Such offers are subject to terms and conditions and may be modified or withdrawn at our discretion.<br>
        10.2. We reserve the right to change prices for our products and services at any time. Changes will not affect orders that have already been confirmed.
    </p>
    <p>
        <b>11. Food Safety and Allergies</b>
    </p>
    <p>
        11.1. We take food safety seriously. If you have any allergies, you are responsible for checking our website before placing an order to ensure that a Product does not contain a relevant allergen to you. If you are not sure, please contact us at info@makemydabba.co.uk. <br>
        11.2. Please note that our Products may contain allergens including, peanuts, eggs, soybeans, fish, crustaceans, lactose, nuts, celery, mustard, sesame and sulphites, lupin and molluscs. We cannot guarantee that our kitchen is free from allergens.<br>
        11.3. Make My Dabba Ltd is not responsible for any adverse reactions caused by consuming our food.
    </p>
    <p>
        <b>12. Cancellations, Returns and Refunds</b>
    </p>
    <p>
        12.1. Most of the Products on our website are perishable. This means that the right to cancel a Contract does not apply. However, we do understand that sometimes you may need to cancel an order. All cancellations/changes should be made by 6pm the day before delivery. <br>
        12.2. Make My Dabba Ltd strives to provide high-quality products and services. If you are dissatisfied with your order due to reasons within our control, please contact our customer support within 1 hour of receiving your order. We may require photographic evidence of the issue to assess the situation and determine appropriate action.<br>
        12.3. Refunds are issued based on the nature of the issue and at our discretion.<br>
        12.4. Refunds may be granted for the following reasons:
    </p>
    <ul>
        <li><p>Incorrect or missing items in the order</p></li>
        <li><p>Quality issues with the food that render it unfit for consumption</p></li>
        <li><p>Delivery issues caused by Make My Dabba Ltd</p></li>
    </ul>
    <p>12.5. Refunds may not be granted for the following reasons:</p>
    <ul>
        <li>
            <p>Change of mind</p>
        </li>
        <li>
            <p>Incorrect or incomplete delivery address provided by the user</p>
        </li>
        <li>
            <p>Delays caused by events outside our control</p>
        </li>
    </ul>
    <p>
        12.6. If a refund is approved, the refund will be processed within 7 working days. Refunds will be issued through the same payment method used for the original transaction. Refunds may take additional time to appear in your account, depending on your payment provider.
    </p>
    <p><b>13. Delivery</b></p>
    <p>
        13.1. Your order will be fulfilled on the delivery date as per your order. All delivery times are estimated and are not guaranteed.<br>
        13.2. Delivery will be completed when we deliver to your nominated address. If no one is available to take delivery, we will follow the instructions as per the order and this will be left in an area that the delivery driver deems safe. We will also endeavour to follow any delivery instructions you provide but this cannot be guaranteed.<br>
        13.3. From the completion of delivery, the Products will be your responsibility. You own the products once we have received payment in full including any applicable delivery charges.<br>
        13.4. Unfortunately, we do not deliver to addresses outside of our deliverable area. Our deliverable area is pre-determined and acceptance is based on the postal code entered during your order. <br>
        13.5. If a customer refuses a delivery for whatever reason, the parcel will either be destroyed or automatically returned to the sender. Perishable items cannot be resent and refusing an order should be a last resort. Refunds will not be made for deliveries that are refused by the customer to the delivery driver. Any cancellation should be scheduled directly via the customer account or direct to ourselves at info@makemydabba.co.uk before the day of delivery.<br>
        13.6. The customer gives permission for the delivery driver to leave the parcel in a location deemed safe. This is however up to driver discretion and cannot be guaranteed.<br>
        13.7. If the customer is not available at the time of delivery, we will leave your delivery with a neighbour or in a convenient place.
    </p>
    <p>
        <b>14. Privacy</b>
    </p>
    <p>
        14.1. Our Privacy Policy outlines how we collect, use, and protect user data. By using our services, you consent to our data practices as described in the Privacy Policy.<br>
        14.2. Please take the time to read these Policies as they include important terms which apply to you.
    </p>
    <p>
        <b>15. Modifications to Terms</b>
    </p>
    <p>
        15.1. Make My Dabba Ltd reserves the right to modify these Terms at any time. Users will be notified of any changes, and continued use of our website and services constitutes acceptance of the modified Terms.
    </p>
    <p>
        <b>
            16. Contact Us
        </b>
    </p>
    <p>
        16.1. If you have questions or concerns about these Terms, please contact us at info@makemydabba.co.uk
    </p>
</div>