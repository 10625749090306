<div class="container-fluid py-4">
    <div class="container">
        <div class="col-md-8 justify-content-center" style="margin: 0 auto;">
            <h3 class="page-headings text-center mb-3" style="color: #2b6e36;">Thank you for your order!</h3>
            <h5 class="alert-heading text-center mb-3">Your payment has been received</h5>
            <div *ngIf="paymentInfo" class="mb-3 ">
                <div class="m-auto font-sm" style="display: table;">
                    <div class="mb-2"><b>Payment Id:</b>  {{paymentInfo.payment_intent}}</div>
                    <div><b>Payment Status:</b>  {{paymentInfo.redirect_status}}</div>
                </div>
            </div>
            <div *ngIf="paymentInfo" class="text-center">We will send your confirmation to: <b>{{ userInfo.email }}</b> </div>
            <p class="text-center mt-2 font-sm">It might not arrive immediately, make sure to check your spam folder</p>
            <hr>
            <p class="text-center font-sm"><b>Delivery Note:</b> Please note that your order will be delivered between 3PM-7PM on every chosen day.</p>
            <!-- <p class="mb-0 font-sm">View Order history:  <a class="link-primary" (click)="goToMyCart()" role="button">Click Here</a></p>  -->
            <div class="d-flex flex-wrap justify-content-center gap-3 mt-5">
                <button class="btn btn btn-round btn-green text-trns btn-mob btn-width" (click)="navigateToPage('menu-items')">Add More Meals</button>
                <button class="btn btn-round btn-theme-yellow btn-mob text-upper btn-width" (click)="navigateToPage('myorders')">View Order history</button>
            </div>
        </div>
    </div>
</div>