export enum CategoryNames {
    MAIN = "MAIN",
    SIDES = "SIDE",
    BREAD = "Accompaniments"
}

export enum CatIndex {
    MAIN,
    SIDES,
    BREAD
}

export enum GenId {
    GENID = 22
}

export enum MealType {
    DINNER = 'DINNER'
}

export enum MealCycle {
    DAYS = 29
}

export enum FormState {
    UPDATE = "UPDATE",
    CREATE = 'CREATE',
    NOTSET = 'NOTSET'
}

export enum PaymentStatus {
    FAILED = "failed",
    SUCCESS = "succeeded"
}
