import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SpinnerService } from './spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class LoadingSpinnerComponent implements OnInit {

  isLoading:boolean | unknown;
  loaderSubscription: Subscription | undefined;

  constructor(public spinnerService:SpinnerService ) { }

  ngOnInit(): void {
  
  }

}
