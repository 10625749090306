import { Component, OnInit, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { CommonService } from '@app/serveces/app/common/common.service';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { Subscription } from 'rxjs';
import { GenId, MealType, MealCycle } from '@app/models/app.enum';
import { SelectedCartItems } from '@app/models/mealMenuItems';
import { Router } from '@angular/router';
import { CategoryNames, CatIndex } from '@app/models/app.enum';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';

@Component({
  selector: 'app-user-cartitems',
  templateUrl: './user-cartitems.component.html',
  styleUrls: ['./user-cartitems.component.scss']
})
export class UserCartitemsComponent implements OnInit,OnDestroy, AfterViewInit {

  cartItemSubscription: Subscription | undefined;
  groupedMenuSubscription: Subscription | undefined;
  userCartItems:any = [];
  cartItemsList:any = [];
  genId: number = GenId.GENID;
  userSavedCart:any;
  isShowCartItems: boolean = true;
  windowInnerWidth:number;
  loginUser:any;
  addText:string = 's';
  catNames:any = CategoryNames;

  constructor(private commonService: CommonService, private cartService: CartService, private router:Router, private authService:AuthenticationService) {
    this.windowInnerWidth = window.innerWidth;
   }

  ngAfterViewInit(): void {
    this.windowInnerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.loginUser = this.authService.userValue;
    this.cartItemSubscription = this.cartService.cartItems.subscribe({
      next:(resp:any)=>{
        this.cartItemsList = resp
        this.cartItemsList.forEach((element:any) => {
          element.items.forEach((item:any)=>{
            switch (item.catName) {
              case CategoryNames.MAIN:
                item.itemIndex = CatIndex.MAIN
                break;
              case CategoryNames.SIDES:
                item.itemIndex = CatIndex.SIDES
                break;
              case CategoryNames.BREAD:
                item.itemIndex = CatIndex.BREAD
                break;
            }
          })
        element.items.sort((a: any, b: any) =>
         a.itemIndex - b.itemIndex
         );
        });
      }
    })
  }

  goToMenuPage() {
    this.router.navigate(['menu-items'])
  }

  goToCheckOutPage() {
    this.router.navigate(['checkout'])
  }

  removeCartItem(cartItem:any) {
    const conf = confirm('Are you sure want to delete cart item')
    // if(conf) {
    //   this.cartService.removeCartItems(cartItem)
    // }
    alert('this feature is under testing!')
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.windowInnerWidth = window.innerWidth;
    if(window.innerWidth > 992) {
      this.isShowCartItems = true;
    } else {
      this.isShowCartItems = false;
    }
  }

  ngOnDestroy(): void {
    this.cartItemSubscription?.unsubscribe();
    this.groupedMenuSubscription?.unsubscribe();
    if(this.userSavedCart && this.userSavedCart.cartItems) {
      this.userSavedCart.cartItems = [];
    }
  }

}
