import { Component, OnInit, AfterContentInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { User, LoginUser, otpPayload, resendOtp } from '@app/models/user.model'
import { UserService } from '@app/serveces/app/user/user.service';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { CommonService } from '@app/serveces/app/common/common.service';
import { GenId } from '@app/models/app.enum';
import { Item, SelectedCartItems } from '@app/models/mealMenuItems';
import { ConfirmationBoxService } from '@app/components/confirmation-box/confirmation-box.service';
import { ToasterService } from '@app/components/toaster/toaster.service';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})

export class UserLoginComponent implements OnInit,AfterContentInit,OnDestroy {

  isShowRegister?:boolean;
  formTitle?:string;
  registerUser:FormGroup
  loginUser:FormGroup
  guestUserCartItems:any;
  logInUserCartItems:any;
  cartItemSubscription: Subscription | undefined;
  groupedMenuSubscription: Subscription | undefined;
  otpNumber:any;
  isNewUserRegistered:boolean = false;
  isShowResendOtp:boolean = false;
  userEmailIdForOtp:string | any;


  constructor(private formBUilder:FormBuilder, private userService:UserService, private authService:AuthenticationService,  private route: ActivatedRoute, private router: Router, private cartService:CartService, private commonService:CommonService, private confirmation:ConfirmationBoxService, private toaster:ToasterService, private modalService: NgbModal ) {  
    this.formTitle ?  this.formTitle : this.formTitle = "User Login"
    
    if (this.authService.userValue) {
      this.navigatePageUrl('/')
    }

    this.registerUser = this.formBUilder.group({
      email: ["",[Validators.required,Validators.email]],
      password: ["",[Validators.required,Validators.pattern(/^(?=.*?[a-z])(.{13,}|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,12})$/)]], 
      firstName: ["",[Validators.required]],
      lastName: ["",[Validators.required]],
      phoneNumber: ["",[Validators.required,Validators.pattern(/^(?:(?:\+44)|(?:0))(?:\s|-)*7\d{3}(?:\s|-)*\d{6}$/)]],
      acceptPolicy:[false,[Validators.requiredTrue]]
    })

    this.loginUser = this.formBUilder.group({
      email:["",[Validators.required,Validators.email]],
      password:["",[Validators.required,Validators.pattern(/^(?=.*?[a-z])(.{13,}|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,12})$/)]]
    })
   }

  ngOnInit(): void {
    this.cartService.clearLogoutObservables()
    if(this.route.snapshot.queryParams['returnUrl']) {
      const gUrl:string = this.route.snapshot.queryParams['returnUrl'].toString();
      if(gUrl.startsWith('/checkout?')) {
      let reUrl = this.route.snapshot.queryParams['returnUrl'].split('?')[0];
      let accessToken =  this.route.snapshot.queryParams['returnUrl'].split('?')[1].split('&')[0].split("=")[1]
      let refToken =  this.route.snapshot.queryParams['returnUrl'].split('?')[1].split('&')[1].split("=")[1]
      this.authService.setUserTokens(accessToken,refToken);
      setTimeout(() => {
        this._getUserInfo(reUrl)
      },5);
    }
    }
  }

  navigatePageUrl(url:string) {
    this.router.navigate([url])
  }

  private modalRef: NgbModalRef | any

  @ViewChild('privacyPolcy') private modalContent: TemplateRef<any> | undefined;

  openPolocyWindow(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent,{ })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  closePolocyWindow(reason:string) {
    this.modalRef.dismiss(reason)
  }

  ngAfterContentInit(): void {
    // this.getMenuData();
  }

  /* resent otp timer update */
  countDownNum:number = 300;
  timerInterval:any;
  dispTimeFormat:string;

  startCountDown() {
    this.timerInterval = setInterval(()=>{
      if(this.countDownNum > 0) {
        this.countDownNum--
        this.formatTimer();
      } else {
        clearInterval(this.timerInterval)
      }
    },1000)
  }

  formatTimer() {
    const minutes = Math.floor(this.countDownNum / 60);
    const seconds = this.countDownNum % 60;
    this.dispTimeFormat = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
  

  _getUserInfo(rUrl:string) {
    this.authService.getUserInfo().subscribe({
      next:resp=>{
        if(resp) {
          this.navigatePageUrl(rUrl)
        }
      },
      error:error=>{
        this.toaster.error(error.message,{autoClose:true})
      }
    })
  }

  openLogin() {
    this.isShowRegister = false;
    this.formTitle = 'User Login'
  }

  openRegister() {
    this.isShowRegister = true;
    this.formTitle = 'User Registration';
  }

  openGoogleLogin() {
    window.location.href = environment.googleLogin;
  }

  _submitUserRegistration(paylod:User) {
    if(this.registerUser.valid) {
      this.userEmailIdForOtp = paylod.email;
      this.userService._registerUser(paylod).subscribe({
        next:resp=>{
          this.isNewUserRegistered = true;
          this.isShowRegister = false;
            this.countDownNum = 90;
            this.startCountDown()
            // this.startTimer(90);
            //   setTimeout(() => {
            //     clearInterval(this.setUpInterval);
            //     this.timerDisp = null;
            //     this.isShowResendOtp = true;
            //   }, 90000);
          // this.registerUser.reset();
          // const msg = "User Registred Successfully!, You will find the activation link in your inbox.";
          // this.toaster.success(msg,{autoClose:true})
          // this.isShowRegister = false;
        },
        error:error=>{
          if(error && error.message) {
            const msg = error.message;
            this.toaster.error(msg,{autoClose:true})
          }
        }
      })
    } else {
      this.toaster.error("Required Fields should not be empty!")
    }
  }

  getTheOtpValue() {
    if(this.otpNumber.length === 6) {
        const payload:otpPayload = {
          email: this.registerUser.controls['email'].value || this.loginUser.controls['email'].value,
          otp: this.otpNumber.toString()
        }
        this.userService._validateUserOtp(payload).subscribe({
          next:resp=>{  
            this.registerUser.reset();
            this.isNewUserRegistered = true;
            this.isShowRegister = false;
            this.authService.setUserInfo(resp);
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'mycart';
            this.navigatePageUrl(returnUrl)
          },
          error:error=>{
            this.toaster.error(error.message,{autoClose:true});
          }
        })
    }
  }

  resendOtp() {
    const payload:resendOtp = {
      email: this.userEmailIdForOtp
    }
    this.userService._resendOtp(payload).subscribe({
      next:(resp:any)=>{
        this.toaster.success(resp.message,{autoClose:true})
        this.openOtpButton = false;
        this.isNewUserRegistered = true;
        this.countDownNum = 90;
        this.startCountDown();
       },
      error:error=>{ this.toaster.error(error.message,{autoClose:true}) }
    })
  }

  userLoginRequest(payload:LoginUser) {
    return new Promise((resolve,reject)=>{
      this.userEmailIdForOtp = payload.email;
      if(this.loginUser.valid) {
        this.authService.authorizeUser(payload).subscribe({
          next:resp=>{
            resolve(resp)
          },
          error:error=>{
            reject(error)
          }
        })
      }
    })
  }

  getUserCartInfo() {
    return new Promise((resolve,reject)=>{
      this.userService._getUserCartInfo().subscribe({
        next:resp=>resolve(resp),
        error:error=>reject(error)
      })
    })
  }

  userCartItem:any;
  openOtpButton:boolean = false;

  _submitUserLogin(payload:LoginUser) {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'mycart';
    this.userLoginRequest(payload).then(resp=>{
      // console.log(resp)
      // if(resp) return this.getUserCartInfo()
      return;
    })
    .then((resp:any): Promise<any> | any=>{
      if(resp && resp.cartItems.length) {
        this.userCartItem = resp;
        let message = 'Your cart contains saved items!';
        return this.confirmation.confirm('Confirmation',message,'Keep Cart Items','Replace Cart Items')
        //   this.confirmation.confirm("Confirmation",message,'Keep Cart Items','Replace Cart Items')
        //   .then(resp=>{
        // console.log(resp)
        //  })
        // const cnf = confirm('Your cart contains saved items!, Can you replace the cart Items');
        // if(cnf) {
        //   this.getMenuData();
        //   this.cartService.deleteCartItems();
        //   return this.createLoginUserCartItems({ userCartItems: resp })
        // } else {
        //   return this.emptyUserCart();
        // }
      } else {
        const text = 'noCartItems';
        return text;
      }
    })
    .then((cnf:boolean | string ): Promise<any> | any=>{
      switch (cnf) {
        case true:
          // this.getMenuData();
          return this.createLoginUserCartItems({ userCartItems: this.userCartItem })
        case false:
          return this.emptyUserCart();
        case "noCartItems":
          return cnf;
      }
    })
    .then((resp:any)=>{
      if(resp !=="noCartItems" && resp !== false) {
          resp.forEach((el:any)=>{
            this.cartService.setCartItems(el)
          })
          this.navigatePageUrl(returnUrl)
      } else {
          this.navigatePageUrl(returnUrl)
      }
    })
    .catch(error=>{
      let msg:string;
      if(error && error.message) {
        msg = error.message
        this.toaster.error(msg,{autoClose:true})
        if(msg === "User is already registered but email is not verified. Please check your email for activation email or you can request new one") {
          this.openOtpButton = true;
        }
      }
    })
  }

  emptyUserCart() {
    return new Promise((resolve,reject)=>{
      this.cartService.emptyUserCartItems().subscribe({
        next:resp=>resolve('noCartItems')
      })
    })
  }

  createLoginUserCartItems({ userCartItems }: { userCartItems: any; }): Promise<any> {
   return new Promise(async (resolve,reject)=>{
      if(this.menuItemsData) {
        let response: any = [];
        userCartItems.cartItems.forEach((cartItem: any) => {
          this.menuItemsData.map((el: any) => {
            if (cartItem.date === el.date) {
              el = JSON.parse(JSON.stringify(el));
              el.isLockedItem = true;
              el.itemsSelected = cartItem.menuItems.length;
              el.mealQuantity = cartItem.mealMenuQuantity;
              el.addedNewMeal = true;
              cartItem.menuItems.map((selItem: any) => {
                el.category.map((cat: any) => {
                  cat.selectedCategory = cat.name;
                  cat.items.map((item: Item) => {
                    if (item._id === selItem.menuItem) {
                      item.isSelected = true;
                      item.catName = cat.name;
                      cat.selectedItemImg = item.photos[0].url;
                      cat.selectedItemName = item.name;
                    }
                  });
                });
              });
              response.push(el);
            }
          });
        });
        let groupingViaCommonProperty = Object.values(
          response.reduce((acc: any, current: any) => {
            acc[current.date] = acc[current.date] ?? [];
            acc[current.date].push(current);
            return acc;
          }, {})
        );
        let finalArr:any = [];
        for (let i = 0; i < groupingViaCommonProperty.length; i++) {
          let innerArr: any = groupingViaCommonProperty[i];
          let genId = GenId.GENID;
          for (let j = 0; j < innerArr.length; j++) {
            if (j!==0) {
              innerArr[j].genId = genId;
            }
            innerArr[innerArr.length - 1].addedNewMeal = false;
            finalArr.push(innerArr[j])
            genId = GenId.GENID + 1;
          }
        }
        if(finalArr) {
        let selItems:any = []  
        let cart:SelectedCartItems[] = [];
          finalArr.forEach((element:any) => {
            const cartItem:SelectedCartItems = {
              date:element.date,
              genId:element.genId,
              mealQuantity:element.mealQuantity,
              mealPrice:element.price,
              menuId:element._id,
              items:[]
            }
            element.category.map((cat:any)=>{
              cat.items.map((item:Item)=>{
                if(item.isSelected === true) {
                  item.date = element.date;
                  item.genId = element.genId;
                  item.menuId = element.menuId;
                  // items.push(item)
                  cartItem.items?.push(item)
                }
              })
            })
            console.log(cartItem);
            cart.push(cartItem)
            this.menuItemsData.map((menuItem:any,index:number)=>{
              if(element.date === menuItem.date && element.genId === menuItem.genId) {
                if(element.genId === menuItem.genId) {
                  this.menuItemsData.splice(index,1,element)
                } else {
                  menuItem = JSON.parse(JSON.stringify(element))
                  selItems.push(menuItem)
                }
              }
            })
          });
          if(selItems) {
            selItems.sort(
              (a: any, b: any) =>
                new Date(a.date).getTime() - new Date(b.date).getTime()
            );
            selItems.forEach((el: any) => {
              this.menuItemsData.push(el);
            });
          }
          this.cartService.setGroupedMenuData(this.menuItemsData);
          // const cartItems = this.createUserCartItem(finalArr)
          resolve(cart);
        }
      }
   })
  }

  menuItemsData:any;

  getMenuData() {
   this.groupedMenuSubscription = this.cartService.groupedMenuItems.subscribe({
      next: (resp: any) => {
        if (resp) {
           this.menuItemsData = resp;
        } else {
        this.commonService._getGroupedMenuItems().subscribe({
            next: (resp) => {
            this.menuItemsData = resp
            },
          });
        }
      },
    });
  }

startTimer(duration:number) {
  var timer = duration, minutes, seconds;
  this.setUpInterval = setInterval(()=>{
    let min:any = timer / 60;
    let sec:any = timer % 60 ;
      minutes = parseInt(min, 10);
      seconds = parseInt(sec, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      this.timerDisp = `${minutes}:${seconds}` 
      if (--timer < 0) {
          timer = duration;
      }
  },1000);
}

timerDisp:any;
setUpInterval:any;

ngOnDestroy(): void {
  this.cartItemSubscription?.unsubscribe();
  this.groupedMenuSubscription?.unsubscribe();
  this.userCartItem = null;
}

}
