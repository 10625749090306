import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterContentInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { Subscription } from 'rxjs';
import { couponCode } from '@app/models/cart.model';
import { filter, takeUntil } from 'rxjs/operators';
import { Item, MealMenu, SelectedCartItems } from '@app/models/mealMenuItems';
import { GenId } from '@app/models/app.enum';
import { CartItemService } from '@app/serveces/app/cart-item/cart-item.service';
import { CategoryNames } from '@app/models/app.enum';



@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  constructor(private router: Router, private cartService: CartService, private cartItemService:CartItemService) {}

  cartItems: any = [];
  cartTotal: any;
  appliedCoupon?: number;
  cartTtl: any;
  menuData: any;
  catNames:any = CategoryNames;
  addText:string = 's';

  @Input() buttonText?: string;
  @ViewChild('inputValue') counter: ElementRef | undefined;

  cartItemsSubscription: Subscription | undefined;
  cartTotalSubscription: Subscription | undefined;
  couponSubscription: Subscription | undefined;
  dayWiseMenuSubcription: Subscription | undefined;

  ngOnInit(): void {
    this.cartItemsSubscription = this.cartService.cartItems.subscribe({
      next: (resp) => {
        if (resp) {
          this.cartItems = resp;
          this.cartItems.map((cartItem:SelectedCartItems)=>{
            cartItem.items?.sort((a:any,b:any)=>a.sortNumber - b.sortNumber)
          });
          this.cartItems.sort(
            (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()
          );

        }
      },
    });
    // console.log(this.cartItems)
    this.cartTotalSubscription = this.cartService.getCartTotal.subscribe({
      next: (resp) => {
        this.cartTotal = resp || 0;
        this.cartTtl = resp?.finalAmount || 0;
      },
    });

    this.couponSubscription = this.cartService.getDiscountApplied$.subscribe({
      next: (resp: couponCode) => {
        this.appliedCoupon = +resp;
        if (this.appliedCoupon && this.appliedCoupon !== 0) {
          this.cartTtl = this.cartTotal.finalAmount || 0
          this.cartTtl = this.cartTtl - +resp;
        }
      },
    });
  }

  ngAfterContentInit(): void {
    this.dayWiseMenuSubcription = this.cartService.groupedMenuItems.subscribe({
      next: (resp) => {
        this.menuData = resp;
      },
    });
  }

  gotoCheckOut() {}

  removeCartItem(cartItem: any) {
    if (this.menuData) {
      let findMenu = this.menuData.find((elm: MealMenu) => {
        return elm.genId === cartItem.genId && elm.date === cartItem.date;
      });
      if (findMenu.genId === GenId.GENID) {
        findMenu.itemsSelected = 0;
        findMenu.mealQuantity = 1;
        findMenu.isLockedItem = false;
        findMenu.category.map((elm: any) => {
          elm.selectedCategory = undefined;
          elm.selectedItemImg = undefined;
          elm.selectedItemName = undefined;
          elm.items.map((item: any) => {
            item.isSelected = false;
          });
        });
      } else {
        let menuIndex = this.menuData.findIndex((elm: MealMenu) => {
          return elm.genId === cartItem.genId && elm.date === cartItem.date;
        });
        this.menuData.splice(menuIndex, 1);
      }
      const menu = this.menuData.filter((em: any) => {
        if (em.date === cartItem.date) {
          return em;
        }
      });
      menu.sort((a: any, b: any) => a.genId - b.genId);
      menu[menu.length - 1].addedNewMeal = false;
      this.cartService.setGroupedMenuData(this.menuData);
    } else {
      this.cartService.setGroupedMenuData(null);
    }

    this.cartService.removeCartItems(cartItem);
  }

  incrimentValue(dayItem: any) {
    if (this.menuData) {
      this.menuData.map((el: MealMenu) => {
        if (el.genId === dayItem.genId && el.date === dayItem.date) {
          el.mealQuantity = +dayItem.mealQuantity + 1;
        }
      });
    }
    this.cartItems.find((el: any): any | undefined => {
      if (el.genId === dayItem.genId && el.date === dayItem.date) {
        el.mealQuantity = +dayItem.mealQuantity + 1;
        this.cartService.updateCartItem(el);
      }
    });
    this.cartService.setGroupedMenuData(this.menuData);
  }

  decrimentValue(dayItem: any) {
    if (this.menuData) {
      this.menuData.map((el: MealMenu) => {
        if (el.genId === dayItem.genId && el.date === dayItem.date) {
          if (+dayItem.mealQuantity > 1) {
            el.mealQuantity = +dayItem.mealQuantity - 1;
          }
        }
      });
    }
    this.cartItems.filter((el: any) => {
      if (el.genId === dayItem.genId && el.date === dayItem.date) {
        if (+dayItem.mealQuantity > 1) {
          el.mealQuantity = +dayItem.mealQuantity - 1;
          this.cartService.updateCartItem(el);
        }
      }
    });
    this.cartService.setGroupedMenuData(this.menuData);
  }

  goToMenu() {
    this.router.navigate(['menu-items']);
  }

  applyDiscountCoupon(cpn: any) {
    if (!cpn) {
      alert('Coupn code should not be empty');
    } else {
      this.cartService.getDiscountAmount(cpn).subscribe({
        next: (resp) => {
          console.log(resp);
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  createCartItem(item:Item) {
    this.cartItemService.createCartItem(item,this.cartItems,this.menuData)
  }

  updateCartItems(item:Item) {
    this.cartItemService.updateCartItem(item,this.cartItems,this.menuData);
  }

  ngOnDestroy(): void {
    this.cartItemsSubscription?.unsubscribe();
    this.cartTotalSubscription?.unsubscribe();
    this.couponSubscription?.unsubscribe();
    this.dayWiseMenuSubcription?.unsubscribe();
  }
}
