import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../header/header.service';

@Component({
  selector: 'app-fullpage',
  templateUrl: './fullpage.component.html',
  styleUrls: ['./fullpage.component.scss']
})
export class FullpageComponent implements OnInit {

  constructor(public headerService:HeaderService) { }

  headHeight:number = 95;
  

  ngOnInit(): void {

  }

}
