<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Reset Password</h2>
        </div>
    </div>
</div>
<div class="mt-4">
    <div class="align-content-center p-2">
        <div class="col-md-8 col-sm-12 col-lg-7 col-xl-5 ms-auto me-auto">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="resetPassword" (submit)="resetPasswrodRequest(resetPassword.value)">
                        <div class="mb-3">
                            <label for="password" class="form-label">Password<span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="password" formControlName="password"
                                [ngClass]="{'is-invalid': resetPassword.controls['password'].touched && resetPassword.controls['password'].errors}">
                                <label class="invalid-feedback font-sm" *ngIf="resetPassword.get('password')?.errors?.['pattern']">
                                    Password should be min 8 char, and atleast 1 char of lowercase, uppercase, numbers and symbols
                                </label>
                        </div>
                        <div class="mb-3">
                            <label for="passwordConfirm" class="form-label">Confirm Password<span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="Confirm Password" formControlName="passwordConfirm"
                                [ngClass]="{'is-invalid': resetPassword.controls['passwordConfirm'].touched && resetPassword.controls['passwordConfirm'].errors}">
                                <label class="invalid-feedback font-sm" *ngIf="resetPassword.get('passwordConfirm')?.errors?.['confirmPasswordValidator']">
                                    Password does'nt match.
                                </label>
                        </div>
                        <div class="d-flex">
                            <button type="submit" [disabled]="resetPassword.invalid" class="btn btn-round btn-theme-yellow m-auto">Reset Password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> <!-- sendOtp end -->