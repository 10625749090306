import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  private headerHeight = new BehaviorSubject<number>(0)
  public getHeaderHeight = this.headerHeight.asObservable();

  _setHeaderHeight(height:number) {
    this.headerHeight.next(height-2)
  }
}
