import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ModalConfig } from '@app/components/model-window/modal.config';
import { ModelWindowComponent } from '../model-window/model-window.component';
import { DatePipe } from '@angular/common';
import { Item, MealMenu, SelectedCartItems } from '@app/models/mealMenuItems';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { GenId } from '@app/models/app.enum';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-day-wise-menu',
  templateUrl: './day-wise-menu.component.html',
  styleUrls: ['./day-wise-menu.component.scss']
})
export class DayWiseMenuComponent implements OnInit,OnDestroy {

  ModalConfig: ModalConfig = {
    modalTitle: 'Title Here',
    customClass:'my-class',
    disableDismissButton: () => {
      return true;
    },
    closeButtonLabel: 'Ok',
  };

  selectMealsSubcription:Subscription | undefined;
  dayWiseMenuSubcription:Subscription | undefined;
  cartItemsSubscription:Subscription | undefined;


  @Input() dayItem:any;
  @Output() addNewMenu = new EventEmitter<any>()
  @ViewChild('inputValue') counter:ElementRef | undefined
  @Output() newCartItem = new EventEmitter<any>()
  @Output() updateCartItem = new EventEmitter<any>()

  catWiseItems:any;
  menuData:any;
  cartItems:any = [];
  editCatWiseItems:any;

  constructor(private datePipe:DatePipe, private cartService:CartService) { }

  ngOnInit(): void { 
    this.dayWiseMenuSubcription = this.cartService.groupedMenuItems.subscribe({
      next:resp=>{
        this.menuData = resp
      }
    })
    this.selectMealsSubcription = this.cartService.getSelectedMeals.subscribe({
      next:resp=>{
        this.selectedMeals = resp;
      }
    })

    this.updateAddMealStatus(this.dayItem)
  }

  @ViewChild('mdWindow') private modelWindowComponent: ModelWindowComponent | any;
  @ViewChild('editWindow') private editWindowComponent: ModelWindowComponent | any;

  async openModal() {
    return await this.modelWindowComponent.open();
  }

  async openEditModel() {
    return await this.editWindowComponent.open();
  }

  getItemsByCategory(catName:any,catId:string,menuId:string) {
    let items = []
    items = catName.items;
    items.map((item:Item)=>{
      item.catId = catId;
      item.menuId = menuId;
      item.catName = catName.name
    })
    this.catWiseItems = items;
    this.ModalConfig.modalTitle = `${this.datePipe.transform(this.dayItem.date,'EEEE, LLL d')} - ${catName.name}`;
    this.openModal();
  }

  addExtraMenu(dayItem:MealMenu) {
    this.addNewMenu.emit(dayItem);
  }

  selectedMeals:SelectedCartItems | any  = [];
  // cartItems:any = [];

  @ViewChild('procuctWrap',{read:ElementRef}) scrollCantinaer:ElementRef<any>

  itemWidth:number = 0;
  currentIndex = 0;

  scrollToIndex(index: number): void {
    if(window.innerWidth < 768 )
    this.itemWidth = this.scrollCantinaer?.nativeElement.children[0].offsetWidth;  
      if (index >= 0 && index < this.dayItem.category.length) {
          this.currentIndex = index;
          this.scrollCantinaer.nativeElement.style.transform = `translateX(-${index * this.itemWidth}px)`;
      }
  }

  onRotateNext(index:number) {
    this.scrollToIndex(index);
  }
  
  onRotatePrevious(index:number) {
    this.scrollToIndex(index);
  }

  createCartItem(event:any,item:Item) {
    let selectedItems:SelectedCartItems = {
      date: item.date,
      genId: item.genId,
      mealQuantity:1,
      mealPrice:item.mealPrice,
      items:[],
      menuId:item.menuId
    }

    let findItem = this.selectedMealFIndItem(item)
    if(findItem) {
      let findCat = findItem.items.find((el: any) => {
        return el.catId === item.catId;
      });
      if (findCat) {
        let index = findItem.items.findIndex((el: any) => {
          return el.catId === item.catId;
        });
        findItem.items.splice(index, 1, item);
      } else {
        findItem.items.push(item);
      }
    } else {
      selectedItems.items?.push(item);
      this.selectedMeals.push(selectedItems)
    }

    this.catWiseItems.map((el:any)=>{
      if(el._id===event) {
        el.isSelected = true;
      }else{
        el.isSelected = false;
      }
    })
    
    findItem = this.selectedMealFIndItem(item)
    
    let catItem = this.menuData.find((elm:MealMenu)=>{
     if(elm._id === item.menuId && elm.genId === item.genId) {
      elm.itemsSelected = findItem.items.length
     }
      return elm._id === item.menuId && elm.genId === item.genId
    })
    let index:number = 0;
    catItem.category.map((el:any,num:number)=>{
      if(el._id === item.catId && el.genId === item.genId){
        el.selectedItemName = item.name;
        el.selectedCategory = el.name;
        el.selectedItemImg = item.photos[0].url
        index = num;
      }
    })

    this.cartService.setGroupedMenuData(this.menuData);
    this.modelWindowComponent.close();

    if(index <= 1) {
      this.scrollToIndex(index+1)
    } else {
      this.scrollToIndex(0)
    }

    if(catItem.itemsSelected === 3) {
      this.scrollToIndex(0)
      this.addMealToCart(catItem);
    }
    // this.catWiseItems = JSON.parse(JSON.stringify(this.catWiseItems))
  }

  selectedMealFIndItem(item:Item) {
   const finItem = this.selectedMeals.find((el: SelectedCartItems) => {
      return el.date === item.date && el.genId == item.genId;
    });
    return finItem;
  }

  addMealToCart(menuItem:any) {
    const findMeal: SelectedCartItems = {
      ...this.selectedMealFIndItem(menuItem),
      mealPrice: menuItem.price,
      mealQuantity: menuItem.mealQuantity,
    };
    this.newCartItem.emit(findMeal)
    this.menuData.map((el:any)=>{
      if(el.genId === menuItem.genId && el.date === menuItem.date){
          el.isLockedItem = true; 
      }
    })
    this.cartService.setGroupedMenuData(this.menuData);
  }

  dynamicAddCart(menuItem:MealMenu) {
    const selMenu = this.menuData.find((el:MealMenu)=>{
      return menuItem.genId === el.genId && menuItem.date === el.date
    })

    const finalMeal:SelectedCartItems = {
      ...selMenu,
      mealPrice:menuItem.price,
      mealQuantity: menuItem.mealQuantity
    }
    selMenu.isLockedItem = true;
    this.cartService.setCartItems(finalMeal);
    this.cartService.setGroupedMenuData(this.menuData)
  }

  removeCartItem(dayItem:MealMenu) {
   this.cartService.removeCartItems(dayItem)
   this.removeSelectedMeal(dayItem);
  }

  removeSelectedMeal(dayItem:MealMenu) {
   let selIndex =  this.selectedMeals.findIndex((elm:SelectedCartItems)=>{
      return elm.genId === dayItem.genId && elm.date === dayItem.date
    })
   this.selectedMeals.splice(selIndex,1) 
   this.removeMenuItem(dayItem);
   this.updateAddMealStatus(dayItem)
  }

  updateAddMealStatus(dayItem:MealMenu) {
   const menu = this.menuData.filter((em:any)=>{
      if(em.date === dayItem.date ) {
        em.addedNewMeal = true;
       return em
      }
     })
  menu.sort((a: any, b: any) =>a.genId - b.genId); 
  menu[menu.length-1].addedNewMeal = false;
  this.cartService.setGroupedMenuData(this.menuData);
  }

  getMealCount(e:any,dayItem:MealMenu) {
    this.menuData.map((el:MealMenu)=>{
      if(el.genId === dayItem.genId && el.date === dayItem.date){
        if(e.target.value==="0" || !e.target.value ) {
          e.target.value = 1;
        }
          el.mealQuantity = +e.target.value
      }
    })
    this.cartService.setGroupedMenuData(this.menuData);
  }

  incrimentValue(dayItem:MealMenu) {
    this.menuData.map((el:MealMenu)=>{
      if(el.genId === dayItem.genId && el.date === dayItem.date){
        el.mealQuantity = +el.mealQuantity + 1
      }
    })
    this.cartService.cartItem.find((el:any): any | undefined=>{
      if(el.genId === dayItem.genId && el.date === dayItem.date){
          el.mealQuantity = +el.mealQuantity + 1
          this.updateCartItem.emit(el)
      }
    })
    this.cartService.setGroupedMenuData(this.menuData);
  }

  decrimentValue(dayItem:MealMenu) {
    this.menuData.map((el:MealMenu)=>{
      if(el.genId === dayItem.genId && el.date === dayItem.date){
          if(+el.mealQuantity > 1) {
            el.mealQuantity = +el.mealQuantity - 1;
          }
      }
    })
    this.cartService.cartItem.filter((el:any)=>{
      if(el.genId === dayItem.genId && el.date === dayItem.date){
        if(+el.mealQuantity > 1) {
          el.mealQuantity = +el.mealQuantity - 1;
          this.updateCartItem.emit(el)
        }
    }
    })
    this.cartService.setGroupedMenuData(this.menuData);
  }

  removeMenuItem(dayItem:MealMenu) {
    let findMenu = this.menuData.find((elm:MealMenu)=>{
      return elm.genId === dayItem.genId && elm.date === dayItem.date
     })
    if(findMenu.genId === GenId.GENID) {
      findMenu.itemsSelected = 0;
      findMenu.mealQuantity = 1;
      findMenu.isLockedItem  = false;
      findMenu.category.map((elm: any) => { 
        elm.selectedCategory = undefined;
        elm.selectedItemImg = undefined;
        elm.selectedItemName = undefined;
        elm.items.map((item: any) => {
          item.isSelected = false;
        });
      });
     } else {
      let menuIndex = this.menuData.findIndex((elm:MealMenu)=>{
        return elm.genId === dayItem.genId && elm.date === dayItem.date
       })
       this.menuData.splice(menuIndex,1)
     }
     this.updateAddMealStatus(dayItem)
     this.cartService.setGroupedMenuData(this.menuData);
  }

  editItemsByCategory(catName:any,catId:string,menuId:string) {
    // catName = JSON.parse(JSON.stringify(catName));   
    catName.items.map((item:any)=>{
    if(item.name === catName.selectedItemName) {
      item.isSelected = true;
    } else {
      item.isSelected = false;
    }
   })
    let items = []
    items = catName.items;
    items.map((item:Item)=>{
      item.catId = catId;
      item.menuId = menuId;
      item.catName = catName.name
    })
    this.editCatWiseItems = items;
    this.ModalConfig.modalTitle = `${this.datePipe.transform(this.dayItem.date,'EEEE, LLL d')} - ${catName.name}`;
    this.openEditModel();
  }

  editCartItem(itemId:string,item:Item) {
     this.menuData.map((elm:MealMenu)=>{
      // return elm.genId === item.genId && elm.date === item.date
      if(elm.genId === item.genId && elm.date === item.date) {
        elm.category.filter(el=>{
            if(el._id === item.catId) {
              el.selectedItemImg = item.photos[0].url;
              el.selectedItemName = item.name;
            }
            el.items.filter((meItem:Item)=>{
              meItem.isSelected = false;
              if(meItem._id === itemId) {
                meItem.isSelected = true;
              }
            })
        })
      }
     })
     const cartItem = this.cartService.cartItem.find((el:any)=>{
      if(el.genId === item.genId && el.date === item.date) {
        return el;
      }
     })
     
     let ctIndex = cartItem.items.findIndex((val:any)=>{
        return val.catId === item.catId;
    })
    
    cartItem.items.splice(ctIndex,1,item); 
    this.updateCartItem.emit(cartItem);
    this.cartService.setGroupedMenuData(this.menuData); 
    this.editWindowComponent.close();
  }

  ngOnDestroy(): void {
    this.selectMealsSubcription?.unsubscribe();
    this.dayWiseMenuSubcription?.unsubscribe();
    this.cartService.setSelectedMeals(this.selectedMeals)
  }
  
}
