import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { PaymentService } from '@app/serveces/app/payment/payment.service';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';
import { Order } from '@app/models/cart.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModelWindowComponent } from '@app/components/model-window/model-window.component';
import { ModalConfig } from '@app/components/model-window/modal.config';
import { AddressPayload, UserAddress } from '@app/models/user.model';
import { UserService } from '@app/serveces/app/user/user.service';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ToasterService } from '@app/components/toaster/toaster.service';
import { Router } from '@angular/router';
import { SpinnerService } from '@app/components/loading-spinner/spinner.service';



declare var Stripe: any;

@Component({
  selector: 'app-proceed-payment',
  templateUrl: './proceed-payment.component.html',
  styleUrls: ['./proceed-payment.component.scss'],
})
export class ProceedPaymentComponent implements OnInit {
  userInfo: any;
  userAddress: any = [];
  @ViewChild('paymentWindow') private paymentWindow:
    | TemplateRef<any>
    | undefined;
  private modalRef: NgbModalRef | any;
  @ViewChild('card-elementv') cardElement: ElementRef | undefined;
  @ViewChild('mdWindow') private modelWindowComponent:
    | ModelWindowComponent
    | any;

  ModalConfig: ModalConfig = {
    modalTitle: 'Change Address',
    customClass: 'my-class',
    disableDismissButton: () => {
      return true;
    },
    closeButtonLabel: 'Ok',
  };

  card: any;
  stripe: any;

  paymentHandler: any = null; // removable
  selectedAddress!: AddressPayload;
  isShowCartItems: boolean = true;

  constructor(
    private authService: AuthenticationService,
    private cartService: CartService,
    private paymentService: PaymentService,
    private modalService: NgbModal,
    private userService: UserService,
    private toaser:ToasterService,
    private router:Router,
    private spinner:SpinnerService
  ) {
    this.userInfo = JSON.parse(this.authService.userInfoData);
  }
  cartItem: Order | any;
  ngOnInit(): void {
    const item: any = sessionStorage.getItem('userSavedCartInfo');
    this.cartItem = JSON.parse(item);
    if(!this.cartItem) {
      this.router.navigate(['checkout'])
    }
    this.cartService.getDelvryAddress$.subscribe({
      next: (resp: any) => {
        this.selectedAddress = resp;
        this.cartItem.deliveryLocation = this.selectedAddress?._id;
      },
    });
    this.getUserAddresses();
    sessionStorage.removeItem('userSavedCartInfo');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth > 992) {
      this.isShowCartItems = true;
    } else {
      this.isShowCartItems = false;
    }
  }

  // @HostListener('window:beforeunload',['$event'])
  // showMessage($event:any) {
  //    $event.returnValue='Your data will be lost!';
  //    console.log('my work')
  // }

  openDiscoutCodes(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.paymentWindow, {
        windowClass: this.ModalConfig.customClass,
        backdrop: 'static',
        keyboard: false,
      });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  async openModal() {
    return await this.modelWindowComponent.open();
  }

  selectAddress(i: number) {
    this.userAddress.map((el: AddressPayload) => {
      el.isSelected = false;
    });
    this.userAddress[i].isSelected = true;
    this.cartService.setDelveryAddress(this.userAddress[i]);
    this.modalService.dismissAll();
  }

  getUserAddresses() {
    this.userService
      ._getUserAddresses()
      .pipe(
        map((resp: AddressPayload | any) => {
          resp.isSelected = false;
          return resp;
        })
      )
      .subscribe({
        next: (resp) => {
          this.userAddress = resp;
          if (!this.selectedAddress && this.userAddress) {
            this.userAddress[0].isSelected = true;
            this.cartService.setDelveryAddress(this.userAddress[0]);
          } else {
            this.userAddress.map((el: any) => {
              if (el._id === this.selectedAddress?._id) {
                el.isSelected = true;
              }
            });
          }
        },
      });
  }

  placeOrder() {
    const payload: Order = this.cartItem;
    this.paymentService.placeOrder(payload).subscribe({
      next: (resp: any) => {
        const { clientSecret } = resp;
        const stripe = Stripe(environment.stripePublicKey);
        const appearance = {
          theme: 'flat',
          variables: { colorPrimaryText: '#262626' },
        };
        const options = {
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        };
        this.openDiscoutCodes();
        const elements = stripe.elements({ clientSecret, appearance });
        const paymentElement = elements.create('payment', options);
        paymentElement.mount('#payment-element');

        const form: any = document.getElementById('payment-form');

        form.addEventListener('submit', async (event: any) => {
          event.preventDefault();

          const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
              return_url: environment.stripeRedirectUrl,
            },
          });

          if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            const messageContainer: any =
            document.querySelector('#error-message');
            messageContainer.textContent = error.message;
            this.toaser.error(error.message,{autoClose:true});
            
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        });
      },
      error:(err:any)=>{
        if(err || err.message) {
          this.spinner.showLoader();
          this.toaser.error(err.message,{autoClose:true})
          setTimeout(() => {
            this.spinner.hideLoader();
            sessionStorage.removeItem('userSavedCartInfo')
            this.router.navigate(['checkout'])
          }, 3000);
        }
      }
    });
  }
}
