import { Injectable } from '@angular/core';
import { HttpService } from '@app/serveces/http/http.service';
import { environment } from '@environments/environment';
import { User, PostalCodeAccess,UserAddress, AddressPayload, otpPayload, resendOtp, resetPassword} from '@app/models/user.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FormState } from '@app/models/app.enum';
import { cateringEnquiry } from '@app/models/user.model';
import { contactUsForm,editPhoneNumber } from '@app/models/user.model';



@Injectable({
  providedIn: 'root'
})

export class UserService {

  private Base_Url = environment.apiBaseUrl

  private activePostcode$:BehaviorSubject<PostalCodeAccess | any>
  public getPostalCodeAccess$:Observable <PostalCodeAccess |any>
  

  constructor(private httpService:HttpService, private http: HttpClient) { 
    const del:PostalCodeAccess | any = localStorage.getItem('isDeliverable')
    this.activePostcode$ = new BehaviorSubject( JSON.parse(del) );
    this.getPostalCodeAccess$ = this.activePostcode$.asObservable();
  }

  setAcitvePostalCode(poastlData:PostalCodeAccess) {
    this.activePostcode$.next(poastlData);
    localStorage.setItem('isDeliverable',JSON.stringify(poastlData))
  }

  _registerUser(payload:User) {
    const url = `${this.Base_Url}/user/signup`;
    return this.httpService.postService(url,payload);
  }

  _getUserCartInfo() {
    const url = `${this.Base_Url}/cart`;
    return this.httpService.getService(url);
  }

  _checkPostalCode(value:string) {
    const url = `${this.Base_Url}/restaurant/check-delivery/${value}`;
    return this.httpService.getService(url);
  }

  _saveUserAddress(payload: AddressPayload, state: string): Observable<Object> | any {

    if(!payload.address.latitude && !payload.address.longitude) {
      payload.address.latitude = "0";
      payload.address.longitude = "0";
    }
    
    switch (state) {
      case FormState.CREATE:
        let url = `${this.Base_Url}/user/address`;
        delete payload.address._id;
        return this.httpService.postService(url, payload);

      case FormState.UPDATE:
        let upurl = `${this.Base_Url}/user/address/${payload.address._id}`;
        return this.httpService.updateService(upurl, payload);
    }

    //https://api.postcodes.io/postcodes/
  // return  new Promise((resolve,reject)=>{
  //     this._checkLogLat(payload.postCode).subscribe({
  //       next:(resp:any)=>{
  //         const { result } = resp;
  //         resolve(result)
  //       }
  //     });
  //   }).then((resp:any): Observable<HttpResponse<any>> | any=>{
  //     const {latitude,longitude } = resp
  //     payload.latitude = latitude.toString();
  //     payload.longitude = longitude.toString();

  //     switch (state) {
  //       case FormState.CREATE:
  //         let url = `${this.Base_Url}/user/address`;
  //         delete payload._id;
  //         return this.httpService.postService(url, payload);

  //       case FormState.UPDATE:
  //         let upurl = `${this.Base_Url}/user/address/${payload._id}`;
  //         return this.httpService.updateService(upurl, payload);
  //     }
  //   })
  }

  private async getLongLatPublicApi(postalCode:number) {
    return new Promise((resolve,reject)=>{
      const url = `https://api.postcodes.io/postcodes/${postalCode}`;
      let headers = new HttpHeaders();
		   this.http.get<HttpResponse<any>>(`${url}`,{ headers }).subscribe({
        next:resp=>resolve(resp)
       })
    })
  }

  _updateUserAddress(payload:UserAddress) {
    console.log(payload)
  }

  _getUserOrderHistory() {
    const url = `${this.Base_Url}/order/history`;
    return this.httpService.getService(url)
  }

  _checkLogLat(postCode:string) {
    const url = `https://api.postcodes.io/postcodes/${postCode}`;
    let headers = new HttpHeaders();
		return this.http.get<HttpResponse<any>>(`${url}`,{ headers })
  }

  _getUserAddresses() {
    const url = `${this.Base_Url}/user/address/all`;
    return this.httpService.getService(url);
  }

  _validateUserOtp(payload:otpPayload) {
    const url = `${this.Base_Url}/user/verify/otp`;
    return this.httpService.postService(url,payload)
  }

  _resendOtp(payload:resendOtp) {
    const url = `${this.Base_Url}/user/resend-otp`;
    return this.httpService.postService(url,payload)
  }

  _forgotPasswordLink(payload:resendOtp) {
    const url = `${this.Base_Url}/user/forgot-password`;
    return this.httpService.postService(url,payload);
  }

  _resetPassword(payload:resetPassword,token:string) {
    const url = `${this.Base_Url}/user/complete-forgot-password`;
    // const headers = { 'Authorization': 'Bearer my-token' }
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`)
		return this.http.put(`${url}`, payload, { headers });
  }

  _submitContactForm(payload:contactUsForm) {
    const url = `${this.Base_Url}/restaurant/contact-us`;
    return this.http.post(url,payload)
  }

  _saveUserPhoneNumber(payload:editPhoneNumber) {
    const url = `${this.Base_Url}/user`;
    return this.httpService.patchService(url,payload);
  }

  _submitCateringEnquiry(payload:cateringEnquiry) {
    const url = `${this.Base_Url}/catering`;
    return this.httpService.postService(url,payload)
  }

}
