// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl:'https://devapi.makemydabba.co.uk',
  googleLogin: 'https://devapi.makemydabba.co.uk/user/login/google',
  googlePlaceKey:'AIzaSyD753nTYNljHRQtNMkBgpyDIkWhZCYuWu0',
  stripePublicKey:'pk_test_51NDkAlHLxnZoCHoZRQ23FuRqxS8gxIhtjRwxollMjYdxYvbkelp7sWGIisj1nEi0UH9Qk0Yb79R8kNpH0bIjxrOY003knHA3YY',
  stripeRedirectUrl:'https://dev.makemydabba.co.uk/payment-status-handler',
  googleAnalytics: {
    streamId: 7843468402,
    measurementId: 'G-33YJEG68N3'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
