export const addressTypes:AddressTypes[] = [
    {
        _id:1,
        adName: 'HOME'
    },
    {
        _id:2,
        adName: 'WORK'
    },
    {
        _id:3,
        adName: 'OTHER'
    }
]

export interface AddressTypes {
    _id:number,
    adName:string
}