<div class="container-fluid py-4">
    <div class="container">
        <div class="col-md-8 justify-content-center" style="margin: 0 auto;">
            <h3 class="page-headings text-center mb-3">Your payment has been unsuccessful</h3>
            <div class="text-center"><p>Please click below to use a different payment method</p></div>
            <!-- <p class="mb-0 font-sm">View Order history:  <a class="link-primary" (click)="goToMyCart()" role="button">Click Here</a></p>  -->
            <div class="d-flex flex-wrap justify-content-center gap-3 mt-5">
                <button class="btn btn-round btn-theme-yellow btn-mob text-uppercase btn-width" (click)="goToCheckOut()" >Click and continue</button>
            </div>
        </div>
    </div>
</div>