import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from '@app/components/toaster/toaster.service';
import { resetPassword } from '@app/models/user.model';
import { UserService } from '@app/serveces/app/user/user.service';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit,OnDestroy {

  resetPassword:FormGroup;
  resetToken:string
  queryParamSubscreption$: Subscription

  constructor(private formBuilder:FormBuilder, private router:Router, private activateRoute:ActivatedRoute, private userService:UserService,
    private toaster:ToasterService) { 
    this.resetPassword = this.formBuilder.group({
      password: ["",[Validators.required,Validators.pattern(/^(?=.*?[a-z])(.{13,}|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,12})$/)]], 
      passwordConfirm:["",[Validators.required]], 
    },
    { validators: [ConfirmPasswordValidator('password','passwordConfirm')] }
    )
  }

  ngOnInit(): void {
    this.queryParamSubscreption$ = this.activateRoute.queryParams.subscribe({
      next:(resp:any)=>{
        if(resp && resp.token ) {
          this.resetToken = resp.token;
        } else {
          this.router.navigate(['/'])
        }
      }
    })
  }

  resetPasswrodRequest(payload:resetPassword) {
    if(this.resetPassword.valid) {
      this.userService._resetPassword(payload,this.resetToken).subscribe({
        next:(resp:any)=>{
          if(resp && resp.message) {
            this.toaster.success(resp.message,{autoClose:true});
            setTimeout(() => {
              this.router.navigate(['userlogin'])
            }, 1500);
          }
        },
        error:(err:any)=>{
          if(err && err.message) {
            this.toaster.error(err.message,{autoClose:true})
          }
        }
      })
    } else {
      this.toaster.error('Required fields should not be empty!')
    }
  }

  ngOnDestroy(): void {
    this.queryParamSubscreption$?.unsubscribe();
  }

}
