<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="contact-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Terms and Conditions</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-4">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 m-auto">
                <app-terms-text></app-terms-text>
            </div>
        </div>
    </div>
</div>
