import { Component, EventEmitter, Injectable, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import { ModalConfig } from './modal.config'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-model-window',
  templateUrl: './model-window.component.html',
  styleUrls: ['./model-window.component.scss']
})

@Injectable()

export class ModelWindowComponent implements OnInit {

  @Input() public modalConfig: ModalConfig | any
  @ViewChild('modal') private modalContent: TemplateRef<ModelWindowComponent> | undefined

  @Output() returnValue = new EventEmitter<boolean>();

  private modalRef: NgbModalRef | any

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent,{windowClass: this.modalConfig.customClass, backdrop : 'static', keyboard : false})
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.returnValue.emit(true)
      this.modalRef.close(result)
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.dismiss(result)
    }
  }

}
