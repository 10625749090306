<div class="day-wise-cnt" *ngIf="!dayItem.isLockedItem; else lockedItem">
    <div class="product-wrapper shadowRight">
        <div class="items"  #procuctWrap>
            <div class="product-container" swipe (next)="scrollToIndex(currentIndex+1)"  (previous)="scrollToIndex(currentIndex-1)" *ngFor="let cat of dayItem.category;">
                <div role="button" class="position-relative" (click)="getItemsByCategory(cat,cat._id,dayItem._id)">
                    <div *ngIf="cat.selectedItemImg ; else noImg">
                        <div class="item-img margin-auto">
                            <img [src]="cat.selectedItemImg" alt="ItemImg" />
                        </div>
                    </div>
                    <p *ngIf="cat.selectedItemName" class="text-center cat-item-name item-font-size my-0">{{
                        cat.selectedItemName | titlecase }}</p>
                    <ng-template #noImg>
                        <div class="item-img margin-auto mt-1">
                            <img src="../../../assets/images/default-food-img-33.jpg" alt="ItemImg" />
                        </div>
                    </ng-template>
                    <p class="text-center m-0 py-2 disp-cat-name">Choose&nbsp; ONE &nbsp;<span style="text-transform: capitalize; color: #fff;">{{ cat.name | lowercase }}</span></p>
                </div>
            </div>
        </div>
    </div>
    <div class="mob-scroll-btns">
        <span role="button" (click)="scrollToIndex(currentIndex-1)"> <i class="bi bi-arrow-left-circle"></i></span>
        <div class="radio-buttons">
            <div class="radio-button" *ngFor="let item of dayItem.category; let i = index"
                [class.active]="i === currentIndex" (click)="scrollToIndex(i)"></div>
        </div>
        <span role="button" (click)="scrollToIndex(currentIndex+1)"> <i class="bi bi-arrow-right-circle"></i></span>
    </div>
    <div class="product-buttons">
        <div class="col-md-12 gap-sm row col-xl-12 align-self-center justify-content-center" style="margin-left: 1px;" >  
            <!-- <div class="input-group button-size p-0" *ngIf="dayItem.itemsSelected === 3">
                <span class="input-group-text" (click)="decrimentValue(dayItem)" role="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                    </svg>
                </span>
                <input type="text" #inputValue (change)="getMealCount($event,dayItem)" class="form-control px-2 font-sm text-center" [value]="dayItem.mealQuantity" />
                <span class="input-group-text" (click)="incrimentValue(dayItem)" role="button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                </span>
            </div>
            <button type="button" class="btn btn-green px-2 text-capitalize font-sm mid-button" [ngClass]="{'bnt-text-white': dayItem.itemsSelected === 3 }" [disabled]="dayItem.itemsSelected < 2" (click)="addMealToCart(dayItem)">Add Meal to Cart</button> -->
            <button type="button" class="btn btn-danger btn-sm min-btn-size" *ngIf="dayItem.itemsSelected >= 2" (click)="removeSelectedMeal(dayItem)">
                <i class="bi bi-trash"></i>
            </button>
            <button type="button" class="btn btn-danger btn-sm min-btn-size" *ngIf="dayItem.genId!==22 && dayItem.itemsSelected < 2" (click)="removeMenuItem(dayItem)">
                <i class="bi bi-trash"></i>
            </button>
        </div>  
            <!-- <button class="btn btn-primary" [disabled]="true" (click)="addExtraMenu(dayItem)">Add New</button> -->
    </div>
</div>
<ng-template #lockedItem>
    <div class="day-wise-cnt">
        <div class="product-wrapper">
            <div class="items"  #procuctWrap>
            <div class="product-container" swipe (next)="onRotateNext(currentIndex+1)"  (previous)="onRotatePrevious(currentIndex-1)" *ngFor="let cat of dayItem.category">
                <div class="position-relative" role="button" (click)="editItemsByCategory(cat,cat._id,dayItem._id)" >
                    <div *ngIf="cat.selectedItemImg ; else noImg">
                        <div class="item-img margin-auto">
                            <img [src]="cat.selectedItemImg" alt="ItemImg" />
                        </div>
                    </div>
                    <p *ngIf="cat.selectedItemName" class="text-center cat-item-name item-font-size my-0">{{ cat.selectedItemName | titlecase }}</p>
                    <ng-template #noImg>
                        <div>
                            <div class="item-img margin-auto">
                                <img src="../../../assets/images/default-food-img-33.jpg" alt="ItemImg" />
                            </div>
                        </div>
                    </ng-template>
                    <p class="text-center m-0 py-2 disp-cat-name">Click to change&nbsp;&nbsp;<span style="text-transform: capitalize; color: #fff;">{{ cat.name | lowercase }}</span></p>
                </div>
            </div>
        </div>
        </div>
        <div class="mob-scroll-btns">
            <span role="button" (click)="scrollToIndex(currentIndex-1)"> <i class="bi bi-arrow-left-circle"></i></span>
            <div class="radio-buttons">
                <div class="radio-button" *ngFor="let item of dayItem.category; let i = index"
                    [class.active]="i === currentIndex" (click)="scrollToIndex(i)"></div>
            </div>
            <span role="button" (click)="scrollToIndex(currentIndex+1)"> <i class="bi bi-arrow-right-circle"></i></span>
        </div>
        <div class="product-buttons">
            <div class="col-md-12 gap-sm row col-xl-12 align-self-center justify-content-center" style="margin-left: 1px;" >
                <button type="button" class="btn btn-outline-success btn-sm mb-2 border border-dark text-dark mid-button" style="font-size: small;" [disabled]="true" (click)="addMealToCart(dayItem)"> {{dayItem.mealQuantity}} - Meal<span class="text-dark" *ngIf="dayItem.mealQuantity > 1">'s</span>&nbsp;Added</button>
                <div class="input-group button-size p-0">
                    <span class="input-group-text" *ngIf="dayItem.mealQuantity > 1" (click)="decrimentValue(dayItem)" role="button">
                        <i class="bi bi-dash font-14-btn"></i>
                    </span>
                    <span class="input-group-text btn-danger" role="button" (click)="removeCartItem(dayItem)" *ngIf="dayItem.mealQuantity === 1"><i class="bi bi-trash font-14-btn text-danger"></i></span>
                    <input type="text" #inputValue [readOnly]="true" class="form-control px-2 font-sm text-center" [value]="dayItem.mealQuantity" />
                    <span class="input-group-text" (click)="incrimentValue(dayItem)" role="button">
                        <i class="bi bi-plus font-14-btn"></i>
                    </span>
                </div>
                <button type="button" class="btn btn-green px-2 text-capitalize font-sm bnt-text-white mid-button" *ngIf="!dayItem.addedNewMeal" [disabled]="dayItem.addedNewMeal" (click)="addExtraMenu(dayItem)">Add extra dabba</button>
            </div>
        </div>
    </div>
</ng-template>
<app-model-window #mdWindow [modalConfig]="ModalConfig">
    <div class="row pd-mobile">
        <div class="col-md-4 col-lg-3 col-sm-6 col-xs-12 my-3" *ngFor="let item of catWiseItems">
            <app-item-view [Item]="item" (createCartItem)="createCartItem($event,item)">
            </app-item-view>
        </div>
    </div>
</app-model-window>
<app-model-window #editWindow [modalConfig]="ModalConfig">
    <div class="row pd-mobile">
        <div class="col-md-4 col-lg-3 col-sm-6 col-xs-12 my-3" *ngFor="let item of editCatWiseItems">
            <app-item-view [Item]="item" (editCartItem)="editCartItem($event,item)" >
            </app-item-view>
        </div>
    </div>
</app-model-window>