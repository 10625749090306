import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { PaymentStatus } from '@app/models/app.enum';

@Component({
  selector: 'app-payment-status-handler',
  templateUrl: './payment-status-handler.component.html',
  styleUrls: ['./payment-status-handler.component.scss']
})
export class PaymentStatusHandlerComponent implements OnInit {

  paymentInfo:any;

  constructor(private activatedRoute: ActivatedRoute, private router:Router) {
    this.activatedRoute.queryParams.subscribe((params) => {
        this.paymentInfo = params;
        if(!this.paymentInfo || !this.paymentInfo.payment_intent || !this.paymentInfo.redirect_status) {
          this.router.navigate(['checkout'])
        } 
    });

    if(this.paymentInfo.redirect_status === PaymentStatus.FAILED) {
        this.router.navigate(['payment-failed'],{queryParams:this.paymentInfo})
    }
    
    if(this.paymentInfo.redirect_status === PaymentStatus.SUCCESS) {
        this.router.navigate(['payment-succeeded'],{queryParams:this.paymentInfo})
    }
   }

  ngOnInit(): void {
    
  }

}
