<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">My Orders</h2>
        </div>
    </div>
</div>
<div class="container-fluid bg-light py-4 vh-50">
    <div class="date-select-desg py-3 position-relative">
        <div class="form-group">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6">
                        <label class="col-form-label ms-3 page-titles-font set-label-size py-1">Order History:</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-white py-3" *ngIf="userOrderItems && userOrderItems.length; else noData">
        <div class="product-cnt" *ngFor="let mainItem of userOrderItems; let dayIndex = index">
            <div class="container">
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-lg-10 col-xl-8 my-3">
                        <div class="card" style="border-radius: 10px;">
                            <div class="card-header p-3">
                                <h5 class="text-muted title-ord mb-0">Order Info</h5>
                            </div>
                            <div class="card-body" *ngFor="let dayItem of mainItem.orderItems">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <p class="lead fw-normal mb-0" style="color: #2e2e2e;">{{ dayItem.date | date:'EEEE, LLL d' }}</p>
                                    <div ngbDropdown class="d-inline-block">
                                        <p role="button" class="text-muted mb-0" id="dropDownMenu" ngbDropdownToggle><i class="bi bi-three-dots-vertical"></i></p>
                                        <div class="menu-dr" ngbDropdownMenu aria-labelledby="dropDownMenu">
                                            <button ngbDropdownItem (click)="cancelDayOrder(mainItem._id,dayItem.date,dayItem.orderStatus)"><i class="bi bi-bag-x-fill me-2 small"></i>Cancel Order</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-0 border">
                                    <div class="card-body">
                                        <div class="row gap-3">
                                            <div class="order-info">
                                                <span><b>Order Status: </b><span class="ord-sts">{{ dayItem.orderStatus }}</span></span>
                                            </div>
                                            <div class="col-md-3" *ngFor="let menuItem of dayItem.menuItems">
                                                <div class="position-relative" *ngIf="menuItem.photos">
                                                    <img [src]="menuItem.photos[0].url" class="img-items">
                                                    <p class="text-center item-name item-font-size position-absolute my-0">{{ menuItem.name | titlecase }}</p>
                                                    <span class="me-2 font-sm"><b>Item.Qty: </b> <span>{{ menuItem.quantity }}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row order-info justify-content-between mt-2">
                                            <div class="font-mid">
                                                <span><b class="me-2" >Day Total:</b> <span>{{dayItem.finalTotal | currency:'GBP' }}</span></span>
                                            </div>
                                            <span *ngIf="dayItem.orderStatus !== 'cancelled'; else cancl"><b class="me-2">Payment Status:</b> {{ mainItem.paymentStatus }}</span>
                                            <ng-template #cancl>
                                                <span><b class="me-2">Payment Status:</b> To be refunded</span>
                                            </ng-template>
                                            <div class="font-sm">
                                                <div> <b>Delivery Address:</b>
                                                    <p class="m-0">
                                                        {{ mainItem.address.address.address1 }} ,
                                                        {{ mainItem.address.address.address2}},
                                                        {{ mainItem.address.address.city}},
                                                        {{ mainItem.address.address.postCode}}.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card-footer border-0 ord-footer p-3">
                                <p class="d-flex align-items-center justify-content-end text-muted mb-0">Total Paid: <span class="h5 mb-0 ms-2 text-muted">{{ mainItem.finalAmount | currency:'GBP' }}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noData>
        <div class="p-4 bg-white">
            <p class="text-center">No Items Found</p>
        </div>
    </ng-template>
</div>