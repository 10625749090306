<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">My Basket</h2>
        </div>
    </div>
</div>
<div class="container-fluid vh-75 py-4 bg-light">
    <div class="container p-0">
        <div class="row"  *ngIf="windowInnerWidth > 992" >
            <div class="col-md-8">
                <div class="date-select-desg py-3 position-relative">
                <div class="form-group">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-3">
                                <label class="col-form-label ms-3 page-titles-font set-label-size py-1">My Basket:</label>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="bg-white p-4" *ngIf="cartItemsList && cartItemsList.length; else noData">
                    <div class="product-cnt pt-3" *ngFor="let dayItem of cartItemsList; let dayIndex = index">
                        <div class="d-flex">
                            <div class="me-auto position-relative" *ngIf="dayItem.genId === genId"><h5 class="page-titles"> {{ dayItem.date | date:'EEEE, LLL d' }} </h5></div>
                        </div>
                        <div class="day-wise-cnt">
                            <div class="product-wrapper" style="overflow-x: auto; flex-wrap: wrap; gap: 15px;" >
                                <div class="product-container" style="margin-right: 0;" *ngFor="let item of dayItem.items"> 
                                    <div role="button" class="position-relative">
                                        <div class="margin-auto" *ngIf="item.photos; else noImg">
                                            <div class="item-img margin-auto">
                                                <img [src]="item.photos[0].url" alt="ItemImg"/>
                                            </div>
                                        </div>
                                        <p *ngIf="item.name" class="text-center cat-item-name item-font-size my-0">{{ item.name | titlecase }}</p>
                                        <span class="font-sm p-2"><b>Item.Qty:</b>{{ item.quantity }}</span>
                                        <span class="font-sm me-auto" style="display: inline-block; font-size: 0.75rem; width: 55%; text-align: right;">{{ item.catName == catNames.BREAD ? item.catName : (item.catName+addText | titlecase ) }}</span>
                                        <ng-template #noImg>
                                            <div class="margin-auto">
                                                <div class="item-img margin-auto">
                                                    <img src="../../../assets/images/defalutImg.png" alt="ItemImg"/>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <!-- <p class="text-center m-0 py-1 disp-cat-name"><b>{{ item.catName | uppercase }}</b></p>  -->
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="product-buttons margin-mob-btn">
                                <div class="col-md-12 gap-sm row col-xl-12 align-self-center justify-content-center">
                                    <button type="button" class="btn btn-outline-success btn-sm mid-button border border-dark text-dark" style="font-size: small;" [disabled]="true" > {{dayItem.mealQuantity}} - Meal<span class="text-dark" *ngIf="dayItem.mealQuantity > 1">'s</span> &nbsp;Added</button>
                                    <button type="button" class="btn btn-danger min-btn-size btn-sm btn-danger p-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" (click)="removeCartItem(dayItem)" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <ng-template #noData>
                    <div class="p-4 bg-white min-ht">
                        <p class="text-center text-muted">No Items Found</p>
                        <div class="mt-5 pb-2 text-center">
                            <button type="button" (click)="goToMenuPage()" class="btn btn-round btn-green">Order Now</button>
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="col-md-12 col-lg-4 mt-lg-0 mt-sm-5 position-cart-cnt show-cart-items" *ngIf="isShowCartItems">
                <div class="date-select-desg py-2 position-relative">
                    <label class="col-form-label ms-3 page-titles-font set-label-size">Basket</label>
                </div>
                <app-product-list>
                    <div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 10px; margin-top: 15px;">
                        <div class="d-flex justify-content-center" *ngIf="cartItemsList && cartItemsList.length">
                            <div class="m-auto">
                                <button (click)="goToMenuPage()" [style.textTransform]="'capitalize'" class="btn btn-round btn-green">Add More Meals</button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button type="button" [disabled]="!cartItemsList || !cartItemsList.length" (click)="goToCheckOutPage()" class="btn btn-round btn-theme-yellow">Go to Checkout</button>
                        </div>
                    </div>
                </app-product-list>
                
            </div>
        </div>
        <div class="col-md-12" *ngIf="windowInnerWidth <= 992">
            <p class="text-center" style="font-size: 0.85rem; background-color: #e7e7e7; padding: 5px 0;" >Delivery - Between 3pm-7pm on every chosen day</p>
            <app-product-list>
                <app-coupon-code *ngIf="loginUser && cartItemsList && cartItemsList.length"></app-coupon-code>
            </app-product-list>
        </div>
    </div>
</div>