import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MealCycle } from '@app/models/app.enum';


@Injectable({
  providedIn: 'root'
})
export class ServiceDatesService {

  constructor(private datePipe:DatePipe) { }

private  getDaysBetweenDates(startDate:any,endDate: any) {
    const days = [],
    months = new Set(),
    years = new Set();
    const dateMove = new Date(startDate);
    let date = startDate;

    // const today = new Date();
    // const yyyy = today.getFullYear();
    // let mm = today.getMonth() + 1;
    // let dd = today.getDate();
    // let date = `${yyyy}-${mm}-${dd}`;

    // const tomorrow = new Date(date);
    // let tmr = tomorrow.setDate(tomorrow.getDate() + 1);
    // const dateMove = new Date(tmr);
    
    while (date < endDate) {
      date = dateMove.toISOString().slice(0, 10);
      months.add(date.slice(0, 7));
      years.add(date.slice(0, 4));
      days.push(date);
      dateMove.setDate(dateMove.getDate() + 1); // increment day
    }
    return { years: [...years], months: [...months], days }; // return arrays
  }

  getFourWeekDates() {
   const dates = this.getMealCycleDates()
    const endDate =  dates.endDate;
    const startDate = dates.startDate;
    const { days } = this.getDaysBetweenDates(startDate,endDate)
    return days;
  }

  getMealCycleDates() {
    var date = new Date();
    var nextDay = new Date(new Date(date).setDate(date.getDate()+1));
    const endDate =  this.datePipe.transform(date.setDate(date.getDate() + (MealCycle.DAYS-1)),'YYYY-MM-dd');
    const startDate = this.datePipe.transform(nextDay,'YYYY-MM-dd');
    return { startDate:startDate,endDate:endDate }
  }

  getSevenDayList() {
    const days = this.getFourWeekDates();
    const dayList = this.chunckDataArray(days,7)
    const weeks:any = [];
    dayList.forEach((elm:any)=>{
      weeks.push({
        start:elm[0],
        end:elm[elm.length-1],
        dates:elm
      })
    })
    return weeks;
  }

 private chunckDataArray(array:any, size:number) {
    var results = [];
    while (array.length) {
      results.push(array.splice(0, size));
    }
    return results;
  }
}
