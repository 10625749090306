import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';
import { PaymentStatus } from '@app/models/app.enum';



@Component({
  selector: 'app-payment-succeeded',
  templateUrl: './payment-succeeded.component.html',
  styleUrls: ['./payment-succeeded.component.scss']
})
export class PaymentSucceededComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router:Router, private cartService:CartService, private authService:AuthenticationService ) { }
  paymentInfo:any;
  userInfo:any;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userInfo = JSON.parse(this.authService.userInfoData);
        this.paymentInfo = params;
        if(!this.paymentInfo || !this.paymentInfo.payment_intent || !this.paymentInfo.redirect_status || !this.userInfo) {
          this.router.navigate(['checkout'])
        } 
    });

    if(this.paymentInfo.redirect_status !== PaymentStatus.SUCCESS) {
      this.router.navigate(['payment-succeeded'],{queryParams:this.paymentInfo})
    }
    
    localStorage.removeItem('cartItems');
    sessionStorage.removeItem('userSavedCartInfo');
    localStorage.removeItem('loginUserCartInfo');
    this.cartService.closeAllObservables();
  }

  navigateToPage(url:string) {
    this.cartService.closeAllObservables();
    this.router.navigate([url])

  }

  clearSessions() {
    localStorage.removeItem('cartItems');
    sessionStorage.removeItem('userSavedCartInfo');
    localStorage.removeItem('loginUserCartInfo');
    this.cartService.closeAllObservables();
    // this.router.navigate(['myorders'])
  }

}
