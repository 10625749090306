import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductMenuComponent } from './product-menu/product-menu.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { FullpageComponent } from './components/fullpage/fullpage.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { ItemViewComponent } from './components/item-view/item-view.component';
import { DatePipe } from '@angular/common';
import { UserCheckoutComponent } from './user/user-checkout/user-checkout.component';
import { UserLoginComponent } from './user/user-login/user-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelWindowComponent } from './components/model-window/model-window.component';
import { DayWiseMenuComponent } from './components/day-wise-menu/day-wise-menu.component';
import { JwtInterceptor } from './serveces/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './serveces/interceptors/error.interceptor';
import { ProceedPaymentComponent } from './user/proceed-payment/proceed-payment.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@environments/environment';
import { UserAddressComponent } from './user/user-address/user-address.component';
import { UserCartitemsComponent } from './user/user-cartitems/user-cartitems.component';
import { UserOrdersComponent } from './user/user-orders/user-orders.component';
import { PaymentSucceededComponent } from './payment-succeeded/payment-succeeded.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { ConfirmationBoxComponent } from './components/confirmation-box/confirmation-box.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { UserOtpValidateComponent } from './user/user-otp-validate/user-otp-validate.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { MobileCartItemsComponent } from './components/mobile-cart-items/mobile-cart-items.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoaderInterceptor } from './serveces/interceptors/loader.interceptor';
import { SwipeDirective } from './directives/swipe.directive';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { CouponCodeComponent } from './components/coupon-code/coupon-code.component';
import { CurrentMenuComponent } from './current-menu/current-menu.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { TermsTextComponent } from './components/terms-text/terms-text.component';
import { PrivacyTextComponent } from './components/privacy-text/privacy-text.component';
import { PaymentStatusHandlerComponent } from './payment-status-handler/payment-status-handler.component';
import { CateringEnquiryComponent } from './catering-enquiry/catering-enquiry.component';
import { MenusViewComponent } from './menus-view/menus-view.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ProductListComponent,
    ProductMenuComponent,
    AboutUsComponent,
    ContactUsComponent,
    HomeComponent,
    FullpageComponent,
    FaqPageComponent,
    ItemViewComponent,
    UserCheckoutComponent,
    UserLoginComponent,
    ModelWindowComponent,
    DayWiseMenuComponent,
    ProceedPaymentComponent,
    UserAddressComponent,
    UserCartitemsComponent,
    UserOrdersComponent,
    PaymentSucceededComponent,
    PaymentFailedComponent,
    ConfirmationBoxComponent,
    ToasterComponent,
    UserOtpValidateComponent,
    HowItWorksComponent,
    MobileCartItemsComponent,
    LoadingSpinnerComponent,
    SwipeDirective,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CouponCodeComponent,
    CurrentMenuComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    TermsTextComponent,
    PrivacyTextComponent,
    PaymentStatusHandlerComponent,
    CateringEnquiryComponent,
    MenusViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googlePlaceKey,
      libraries: ['places'],
    }),
    GooglePlaceModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics.measurementId),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
