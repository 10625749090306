import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from '@app/components/loading-spinner/spinner.service';


@Injectable()

export class LoaderInterceptor implements HttpInterceptor {
    // private totalRequests = 0;

    constructor(private spinnerService:SpinnerService ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // this.totalRequests++
        this.spinnerService.showLoader();
        return next.handle(req).pipe(
            finalize(()=>{
                this.spinnerService.hideLoader();
            })
        )   
    }
}