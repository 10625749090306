import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { PaymentStatus } from '@app/models/app.enum';


@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit {

  paymentInfo:any;

  constructor(private router:Router, private activatedRoute: ActivatedRoute) { 
    this.activatedRoute.queryParams.subscribe((params) => {
      this.paymentInfo = params;
      if(!this.paymentInfo || !this.paymentInfo.payment_intent || !this.paymentInfo.redirect_status) {
        this.router.navigate(['checkout'])
      } 
    });
    if(this.paymentInfo.redirect_status !== PaymentStatus.FAILED) {
      this.router.navigate(['checkout'],{queryParams:this.paymentInfo})
  }
  }

  ngOnInit(): void {
  }

  goToCheckOut() {
    this.router.navigate(['checkout'])
  }

}
