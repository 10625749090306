import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  postService(url: string, data: any): Observable<HttpResponse<any>> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json')
		return this.http.post<HttpResponse<any>>(`${url}`, data, { headers });
	}

	getService(url: string): Observable<Object> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json')
    // .set('Access-Control-Allow-Credentials', 'true');
		return this.http.get<HttpResponse<any>>(`${url}`,{ headers });
	}

	updateService(url: string, data?: any): Observable<Object> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json')
		return this.http.put(`${url}`, data, { headers });
	}

	deleteService(url: string): Observable<Object> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json')	
		return this.http.delete(`${url}`, { headers });
	}

	patchService(url: string, data?: any): Observable<Object> {
		let headers = new HttpHeaders().set('Content-Type', 'application/json')	
		return this.http.patch(`${url}`, data, { headers });
	}
  
}
