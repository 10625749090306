import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductMenuComponent } from './product-menu/product-menu.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomeComponent } from './home/home.component';
import { FullpageComponent } from './components/fullpage/fullpage.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { UserCheckoutComponent } from './user/user-checkout/user-checkout.component';
import { UserLoginComponent } from './user/user-login/user-login.component';
import { AuthGuard } from './serveces/guard/auth.guard';
import { ProceedPaymentComponent } from './user/proceed-payment/proceed-payment.component';
import { UserCartitemsComponent } from './user/user-cartitems/user-cartitems.component';
import { UserOrdersComponent } from './user/user-orders/user-orders.component';
import { PaymentSucceededComponent } from './payment-succeeded/payment-succeeded.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { CurrentMenuComponent } from './current-menu/current-menu.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PaymentStatusHandlerComponent } from './payment-status-handler/payment-status-handler.component';
import { CateringEnquiryComponent } from './catering-enquiry/catering-enquiry.component';
import { MenusViewComponent } from './menus-view/menus-view.component';


const routes: Routes = [
  {
    path:'',
    redirectTo:'home',
    pathMatch:'full'
  },
  {
    path: '',
    component: FullpageComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        pathMatch: 'full',
        title:'Make My Dabba'
      },
      {
        path:'menu-items',
        component:MenusViewComponent,
        pathMatch:'full',
        title:'Menu Items'
      },
      {
        path:'daymenus',
        component:ProductMenuComponent,
        pathMatch:'full',
        title:'Menu Items'
      },
      {
        path:'contact-us',
        component:ContactUsComponent,
        pathMatch:'full',
        title:'Contact Us'
      },
      {
        path:'about-us',
        component:AboutUsComponent,
        pathMatch:'full',
        title:'About Make My Dabba'
      },
      {
        path:'catering',
        component:CateringEnquiryComponent,
        pathMatch:'full',
        title:'Catering Enquiry'
      },
      {
        path:'faqs',
        component:FaqPageComponent,
        pathMatch:'full',
        title:'Faqs'
      },
      {
        path:'how-it-works',
        component:HowItWorksComponent,
        pathMatch:'full',
        title:'How It Works'
      },
      {
        path:'checkout',
        component:UserCheckoutComponent,canActivate:[AuthGuard],
        pathMatch:'full',
        title:'Checkout'
      },
      {
        path:'mycart',
        component:UserCartitemsComponent,
        pathMatch:'full',
        title:'My Basket'
      },
      {
        path:'myorders',
        component:UserOrdersComponent,canActivate:[AuthGuard],
        pathMatch:'full',
        title:'My Orders'
      },
      {
        path:'proceed-payment',
        component:ProceedPaymentComponent,canActivate:[AuthGuard],
        pathMatch:'full',
        title:'Proceed To Pay'
      },
      {
        path:'userlogin', component:UserLoginComponent, pathMatch:'full', title:'Login | Signup'
      },
      {
        path:'forgotpassword', component:ForgotPasswordComponent, pathMatch:'full', title:'Forgot Password'
      },
      {
        path:'resetpassword', component:ResetPasswordComponent, pathMatch:'full', title:'Reset Password'
      },
      {
        path:'current-menu', component:CurrentMenuComponent, pathMatch:'full', title:'Current Menu'
      },
      {
        path:'payment-succeeded',component:PaymentSucceededComponent,pathMatch:'full',title:'Payment Success'
      },
      {
        path:'payment-failed',component:PaymentFailedComponent,pathMatch:'full', title:'Payment Failed'
      },
      {
        path:'payment-status-handler',component:PaymentStatusHandlerComponent,pathMatch:'full', title:'Payment Status'
      },
      {
        path:'termsandconditions',component:TermsAndConditionsComponent,pathMatch:'full', title:'Terms and Conditions'
      },
      {
        path:'privacypolicy',component:PrivacyPolicyComponent,pathMatch:'full', title:'Privacy Policy'
      },
    ],
  },
 
  {
    path:"**",
    redirectTo:'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration:'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
