<div class="polocy-cnt">
    <small class="text-end d-block">Effective Date: 01/08/2023</small>
    <p><b>1. Introduction</b></p>
    <p>Welcome to Make My Dabba Ltd ("we," "us," or "Make My Dabba"). We are committed to protecting your personal
        information and your right to privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard
        your information when you use our website.</p>
    <p><b>2. Information We Collect</b></p>
    <ul>
        <li>
            <p>Personal Information: When you register for an account, we may collect certain personal information such as
                your name, email address, postal address, phone number, and other information you provide during the
                registration process.</p>
        </li>
        <li>
            <p>
                Payment Information: If you make a purchase, we collect payment information, including credit card and
                billing details. This information is securely processed and stored by our third-party payment processors.
            </p>
        </li>
        <li>
            <p>
                Usage Information: We may collect information about how you interact with our website, such as your browsing
                patterns, the pages you view, and the links you click. This helps us improve our website and customize your
                experience.
            </p>
        </li>
        <li>
            <p>
                Cookies and Tracking Technologies: We may use cookies and similar tracking technologies to enhance your
                experience on our website. You can control cookies through your browser settings.
            </p>
        </li>
    </ul>
    <p><b>3. How We Use Your Information</b></p>
    <ul>
        <li>
            <p>
                Provide Services: We use the information to provide you with the services you requested, including account
                registration, order processing, and customer support.
            </p>
        </li>
        <li>
            <p>
                Communication: We may send you transactional emails, promotional messages, and other updates related to our
                products and services. You can opt out of marketing communications at any time.
            </p>
        </li>
        <li>
            <p>
                Personalization: We use your information to personalize your experience on our website and recommend
                products that may interest you.
            </p>
        </li>
    </ul>
    <p><b>4. Data Sharing and Disclosure</b></p>
    <ul>
        <li>
            <p>
                Service Providers: We may share your information with third-party service providers who help us operate our
                website, process payments, and provide other related services.
            </p>
        </li>
        <li>
            <p>
                Legal Compliance: We may disclose your information when required by law or in response to a valid request
                from government authorities.
            </p>
        </li>
    </ul>
    <p><b>5. Your Rights</b></p>
    <p>
        You have certain rights regarding your personal information, including the right to access, correct, and delete your
        data. You can manage your account settings to update your information or delete your account.
    </p>
    <p><b>6. Data Security</b> </p>
    <p>
        We take data security seriously and implement measures to protect your information from unauthorized access and
        disclosure.
    </p>
    <p>
        <b>7. Changes to this Privacy Policy</b>
    </p>
    <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective date
        will be updated accordingly.
    </p>
    <p>
        <b>8. Contact Us</b>
    </p>
    <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at info@makemydabba.co.uk
    </p>
</div>
