import { Injectable } from '@angular/core';
import { GenId, MealType } from '@app/models/app.enum';
import { Category, Item, MealMenu } from '@app/models/mealMenuItems';
import { HttpService } from '@app/serveces/http/http.service';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { ServiceDatesService } from '@app/serveces/menu-dates/service-dates.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private Base_Url = environment.apiBaseUrl

  constructor(private httpService:HttpService,private dateList:ServiceDatesService,) { }

  _getGroupedMenuItems() {
    const url = `${this.Base_Url}/menu?populate=true&grouped=true`;
    const dates = this.dateList.getFourWeekDates();
    return this.httpService.getService(url).pipe(
      map(resp=>{
        const data: any = [];
        data.push(resp);
        let ItemDate: any;
        let mealMenus:any = [];
        dates.forEach((element: string) => {
          data.map((el: any) => {
            if (el[element]) {
              ItemDate = element;
              el[element].menus.forEach((mealMenu: MealMenu) => {
                if (mealMenu.mealMenuType == MealType.DINNER) {
                  mealMenu.date = ItemDate;
                  const price = mealMenu.price;
                  mealMenu.genId = GenId.GENID;
                  mealMenu.itemsSelected = 0;
                  mealMenu.mealQuantity = 1;
                  mealMenu.isLockedItem = false;
                  mealMenu.addedNewMeal = false;
                  mealMenu.category.map((category: Category) => {
                    category.date = ItemDate;
                    category.genId = GenId.GENID;
                    category.items.map((item: Item) => {
                      item.isSelected = false;
                      item.genId = GenId.GENID;
                      item.date = ItemDate;
                      item.mealPrice = price;
                      item.quantity = 0;
                      item.menuId = mealMenu._id;
                    });
                    // return category;
                  });
                  mealMenus.push(mealMenu)
                }
              });
            }
          });
        });
        return mealMenus;
      })
    )
  }

  _getPopulatedMenuItems() {
    const url = `${this.Base_Url}/menu?populate=true`;
    return this.httpService.getService(url)
  }

  _getCategoryList() {
    const url = `${this.Base_Url}/category`;
    return this.httpService.getService(url);
  }
}
