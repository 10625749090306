<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Forgot Password</h2>
        </div>
    </div>
</div>
<div class="mt-4">
    <div class="align-content-center p-2">
        <div class="col-md-8 col-sm-12 col-lg-7 col-xl-4 ms-auto me-auto">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="forgotPassword" (submit)="validateUserEmail(forgotPassword.value)">
                        <div class="mb-4">
                            <label for="exampleInputEmail1" class="form-label">Email address<span class="text-danger">*</span></label>
                            <input type="email" class="form-control" placeholder="Email" formControlName="email" [ngClass]="{'is-invalid': forgotPassword.controls['email'].touched && forgotPassword.controls['email'].errors}">
                        </div>
                        <div class="d-flex">
                            <button type="submit" [disabled]="!forgotPassword.valid" class="btn btn-round btn-theme-yellow m-auto">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> <!-- sendOtp end -->