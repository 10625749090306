<div class="mt-2 mb-3 ">
    <small class="font-min text-muted">Discount Coupon</small>
    <div class="position-relative" *ngIf="appliedCouponCode==null else appliedCpn">
        <form name="form" #cpn="ngForm" (ngSubmit)="cpn.form.valid && applyDiscountCoupon(cpn.value)" novalidate>                            
            <input type="text" class="form-control position-relative" required #userCouponCode="ngModel" name="userCouponCode" [(ngModel)]="appliedCoupon.code" maxlength="10"  placeholder="COUPON CODE"
            [ngClass]="{'is-invalid': cpn.submitted && userCouponCode.errors }"
            />
            <label class="invalid-feedback font-sm" style="font-size: 0.75rem" *ngIf="cpn.submitted && userCouponCode.errors">Enter a valid Coupon Code</label>
            <div style="right: 5px; top: 1px; font-size: 14px; " class="me-auto position-absolute mt-1">
                <button type="submit" style="border-radius: 5px;"  class="btn btn-sm btn-theme-yellow ms-auto secondaryButton">Apply</button>
            </div>
        </form>
    </div>
    <ng-template #appliedCpn>
        <div class="position-relative" *ngIf="appliedCouponCode!=null">
            <input type="text" class="form-control position-relative" disabled readonly [value]="appliedCouponCode.code" />
            <div style="right: 5px; top: 1px; font-size: 14px " class="me-auto position-absolute mt-1">
                <button type="submit" style="border-radius: 5px;" (click)="removeCouponCode()" class="btn btn-sm btn-theme-yellow ms-auto secondaryButton">Remove</button>
            </div>
        </div>
    </ng-template>
    <div *ngIf="isShowAvlCpn" class="cp-items mt-2 bg-white p-2 border-light">
        <span class="title-cp">Available Coupons</span>
        <!-- <ul *ngFor="let coupon of discountCoupons | async">
            <li> 
                <div style="display: flex;">
                    <span>{{coupon.code | uppercase}}</span>
                    <span role="button" (click)="copyToClipBoard(coupon.code)" class="copyBtn" [title]="copyText" class="ms-auto">
                        <i [ngClass]="{'bi-clipboard':!isCopied, 'bi-clipboard-check': isCopied} " class="bi bi-clipboard copyBtn"> </i></span>
                </div> 
            </li>
        </ul> -->
    </div>
</div>
<ng-template #couponWindow>
    <div class="modal-body">
        <h4 class="page-titles-font text-center mt-2 mb-2">You Got</h4>
        <h1 class="page-titles-font text-center copun-applied"> {{ appliedValue | currency:'GBP' }} </h1>
        <h4 class="page-titles-font text-center mt-2">Discount</h4>
    </div>
</ng-template>
