import { Component, OnInit, OnDestroy,HostListener, ViewChild, ElementRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router,NavigationStart } from '@angular/router';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { UserService } from '@app/serveces/app/user/user.service';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';
import { Subscription } from 'rxjs';
import { HeaderService } from './header.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,OnDestroy,AfterViewChecked,AfterViewInit {

  constructor(private authService:AuthenticationService, private router:Router, private cartService:CartService, private headerService:HeaderService ) { }
  
  isLoginUser:boolean = false;
  userTokenSubscription: Subscription | undefined;
  routeSubscription: Subscription | undefined;
  cartItemsSubscription$:Subscription | undefined ;
  userInfo:any;
  navbarNav:string = 'navbarNav';
  cartItemsCount:number = 0;
  @ViewChild('header',{read:ElementRef}) headContainer:ElementRef<any>

  ngOnInit(): void {
    this.userTokenSubscription = this.authService.userToekn.subscribe((resp:any)=>{
      this.isLoginUser = (resp) ? true : false
    });
    this.authService.userInfo.subscribe({
      next:resp=>{
        this.userInfo = JSON.parse(resp);
      }
    })
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let navEl:any = document.getElementById("navbarNav");
        navEl.classList.remove('show');
      }
    });
    this.cartItemsSubscription$ = this.cartService.cartItems.subscribe({
      next:resp=>{
        if(resp) {
          this.cartItemsCount = resp.length;
        }
      }
    })
    // this.userInfo = JSON.parse(this.authService.userInfoData);
  }

  navigatePage(page:string) {
    this.router.navigate([page])
  }

  ngAfterViewInit(): void {
    this.headerService._setHeaderHeight(this.headContainer.nativeElement.offsetHeight);
  }

  ngAfterViewChecked(): void {
    this.headerService._setHeaderHeight(this.headContainer.nativeElement.offsetHeight);
  }

  ngOnDestroy(): void {
    this.userTokenSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.cartItemsSubscription$?.unsubscribe();
  }

  logOutUser() {
    this.cartService.clearLogoutObservables();
    this.authService.logOut(this.router.routerState.snapshot);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if(window.innerWidth > 992) {
      let navEl:any = document.getElementById("navbarNav");
      navEl.classList.remove('show');
    }
  }

  openNav() {
   let navEl = document.getElementById("navbarNav")
   if(navEl?.classList.contains('show')) {
    navEl.classList.remove('show');
   } else {
    navEl?.classList.add('show');
   }
  }

}
