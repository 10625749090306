<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="contact-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Catering Enquiry</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-4 bg-about-us">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 m-auto">
                <div class="aboutus-text-cnt">
                    <h2 class="page-headings text-center pt-2 mb-4">CATERING ENQUIRY</h2>
                    <div class="col-md-11 col-sm-12 m-auto p-3" style="background-color: #fafafacb;">
                        <form [formGroup]="cateringEnquiry" (submit)="_submitCateringEnquiry(cateringEnquiry.value)">
                            <div class="row">
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Full Name<span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Full Name" formControlName="name" [ngClass]="{'is-invalid': cateringEnquiry.controls['name'].touched && cateringEnquiry.controls['name'].errors}" />
                                            <label class="invalid-feedback font-sm"*ngIf="cateringEnquiry.get('name')?.errors?.['maxlength']">
                                                Maximum limit is exceeded
                                            </label>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Email Address<span class="text-danger">*</span>:</label>
                                        <input type="text" class="form-control" placeholder="Email Address" formControlName="email" [ngClass]="{'is-invalid': cateringEnquiry.controls['email'].touched && cateringEnquiry.controls['email'].errors}" />
                                            <label class="invalid-feedback font-sm"*ngIf="cateringEnquiry.get('email')?.errors?.['email']">
                                                Invalid email address
                                            </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Phone Number<span class="text-danger">*</span>:</label>
                                        <input type="text" class="form-control" placeholder="Phone Number" formControlName="phoneNumber" [ngClass]="{'is-invalid': cateringEnquiry.controls['phoneNumber'].touched && cateringEnquiry.controls['phoneNumber'].errors}" />
                                            <label class="invalid-feedback font-sm"*ngIf="cateringEnquiry.get('phoneNumber')?.errors?.['pattern']">
                                                invalid phone number
                                            </label>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Postal Code<span class="text-danger">*</span>:</label>
                                        <input type="text" class="form-control" placeholder="Postal Code" formControlName="postcode" [ngClass]="{'is-invalid': cateringEnquiry.controls['postcode'].touched && cateringEnquiry.controls['postcode'].errors}" />
                                            <label class="invalid-feedback font-sm"*ngIf="cateringEnquiry.get('postcode')?.errors?.['pattern']">
                                                Invalid postal code
                                            </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Address<span class="text-danger">*</span>:</label>
                                        <input type="text" class="form-control" placeholder="Address" formControlName="address" [ngClass]="{'is-invalid': cateringEnquiry.controls['address'].touched && cateringEnquiry.controls['address'].errors}" />
                                            <label class="invalid-feedback font-sm"*ngIf="cateringEnquiry.get('address')?.errors?.['maxlength']">
                                                invalid phone number
                                            </label>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Catering Date and Time:</label>
                                        <input type="datetime-local" [min]="todayDate" class="form-control" formControlName="cateringDateTime" [ngClass]="{'is-invalid': cateringEnquiry.controls['cateringDateTime'].touched && cateringEnquiry.controls['cateringDateTime'].errors}" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Number of Adults:</label>
                                        <input type="number" min="0" class="form-control" formControlName="adultCount" [ngClass]="{'is-invalid': cateringEnquiry.controls['adultCount'].touched && cateringEnquiry.controls['adultCount'].errors}" />
                                    </div>
                                </div>
                                <div class="col-md-6 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Number of Children:</label>
                                        <input type="number" min="0" class="form-control" formControlName="childCount" [ngClass]="{'is-invalid': cateringEnquiry.controls['childCount'].touched && cateringEnquiry.controls['childCount'].errors}" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Do you require Buffet? (Included serving spoons,charges apply):</label>
                                        <div class="d-flex gap-3">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="isBuffetNeeded" type="radio" name="isBuffetNeeded" [value]="false" [attr.checked]="true">
                                                <label class="form-check-label">No</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="isBuffetNeeded" type="radio" name="isBuffetNeeded" [value]="true">
                                                <label class="form-check-label">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Do you require staff to serve? (charges apply):</label>
                                        <div class="d-flex gap-3">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="isStaffNeeded" type="radio" name="isStaffNeeded" [value]="false" [attr.checked]="true">
                                                <label class="form-check-label">No</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="isStaffNeeded" type="radio" name="isStaffNeeded" [value]="true">
                                                <label class="form-check-label">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <div class="form-group">
                                        <label class="form-label">Do you require Delivery?</label>
                                        <div class="d-flex gap-3">
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="requireDelivery" type="radio" name="requireDelivery" [value]="0" [attr.checked]="true">
                                                <label class="form-check-label">No</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" formControlName="requireDelivery" type="radio" name="requireDelivery" [value]="1">
                                                <label class="form-check-label">Yes</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 text-center">
                                <button type="submit" class="btn btn btn-round btn-green text-trns btn-mob btn-width">Submit Enquiry</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>