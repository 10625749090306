import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '@app/components/header/header.service';
import { Category, Item, MealMenu, SelectedCartItems } from '@app/models/mealMenuItems';
import { CartItemService } from '@app/serveces/app/cart-item/cart-item.service';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { CommonService } from '@app/serveces/app/common/common.service';
import { ServiceDatesService } from '@app/serveces/menu-dates/service-dates.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CategoryNames } from '@app/models/app.enum';
import { NgbDatepickerConfig, NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menus-view',
  templateUrl: './menus-view.component.html',
  styleUrls: ['./menus-view.component.scss']
})
export class MenusViewComponent implements OnInit,AfterViewChecked,AfterViewInit  {

  weeksList: any;
  selectedWeek:any;
  isShowCartItems:boolean = true;
  cartItems: any = [];
  mealMenuItems: MealMenu[] = [];
  cartItemSubscription: Subscription | undefined;
  groupedMenuSubscription: Subscription | undefined;
  cartItemsSubscription: Subscription | undefined;
  subMenuTop:number
  startDay:any;
  selectedDate:any;
  endDay:any;
  calStartDay:any;
  calEndDay:any;
  catNames:any = CategoryNames;

  isDateDisabled:any
  json = {
    disable: [1],
    disabledDates: [
      { year: 2020, month: 8, day: 13 }
    ],
  };

  constructor(public headerService:HeaderService, private weekWiseList: ServiceDatesService, private cartService: CartService,private commonService: CommonService, private cartItemService:CartItemService, private router:Router, private datePipe:DatePipe,  private calendar: NgbCalendar ) {
    const dates = this.weekWiseList.getMealCycleDates();

    this.isDateDisabled = (date: NgbDateStruct) =>{
      return this.json.disabledDates.find((x) =>
          new NgbDate(x.year, x.month, x.day).equals(date) ||
          this.json.disable.includes(calendar.getWeekday(new NgbDate(date.year, date.month, date.day)))
      ) ? true : false;
    }
    
    this.startDay = dates.startDate;
    this.endDay = dates.endDate;
    const dayByDate = this.getDayName(this.startDay);
    const calStartDate = new Date(this.startDay);
    const calEndDate = new Date(this.endDay)
    this.calStartDay  = { year:calStartDate.getFullYear(),month: calStartDate.getMonth()+1, day: calStartDate.getDate()}
   
    if(dayByDate == 'Monday') {
      const day = new Date(this.startDay)
      const startdt = new Date(this.startDay)
      startdt.setDate(day.getDate()+1);
      this.startDay = this.datePipe.transform(startdt,"YYYY-MM-dd");
      this.calStartDay  = { year:startdt.getFullYear(),month: startdt.getMonth()+1, day: startdt.getDate()}
    }

    this.menuDateSelected = this.calStartDay;
    this.calEndDay  = { year:calEndDate.getFullYear(),month: calEndDate.getMonth()+1, day: calEndDate.getDate() }
   }

   @ViewChild('dateHeader',{read:ElementRef}) headContainer:ElementRef<any>

  ngOnInit(): void {
    this.weeksList = this.weekWiseList.getSevenDayList();
    this.selectedWeek = this.weeksList[0];
    this.selectedDate = this.startDay;
    this.groupedMenuSubscription = this.cartService.groupedMenuItems.subscribe({
      next: (resp: any) => {
          if(resp) {
            this.globalDayWiseMenuData = resp;
            // this.getGroupedItemData(resp);
            this.getMenuByDate(this.selectedDate);
          } else {
              this.getGroupedMenu();
          }
      }
    });

    this.cartItemsSubscription = this.cartService.cartItems.subscribe({
      next: (resp) => {
        if (resp) {
          this.cartItems = resp;
        }
      },
    });
  }

  ngAfterViewInit(): void {
    this.headerService.getHeaderHeight.subscribe({
      next:resp=>this.subMenuTop = resp + this.headContainer.nativeElement.offsetHeight
    })
  }

  ngAfterViewChecked(): void {
    this.headerService.getHeaderHeight.subscribe({
      next:resp=>this.subMenuTop = resp + this.headContainer.nativeElement.offsetHeight
    })
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if(window.innerWidth > 992) {
      this.isShowCartItems = true;
    } else {
      this.isShowCartItems = false;
    }
  }

  globalDayWiseMenuData: MealMenu[] = [];
  menuDateSelected:any;


  getSelectedDay() {
    // console.log(this.menuDateSelected)
    // this.selectedWeek = this.weeksList[e];
    // this.getGroupedItemData(this.globalDayWiseMenuData);
    const dateSelected = `${this.menuDateSelected.year}-${this.menuDateSelected.month}-${this.menuDateSelected.day}`;
    const finalDate = this.datePipe.transform(dateSelected,'YYYY-MM-dd')
    this.selectedDate = finalDate;
    this.getMenuByDate(finalDate);
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }


  getGroupedItemData(menuItems:MealMenu[]) {
    this.mealMenuItems = [];
    menuItems.filter((elm: MealMenu) => {
      if (
        elm.date >= this.selectedWeek.start &&
        elm.date <= this.selectedWeek.end
      ) {
        this.mealMenuItems.push(elm);
      }
    });
    this.mealMenuItems = this.mealMenuItems.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    // console.log(this.mealMenuItems)
  }

  getMenuByDate(date:any) {
    this.mealMenuItems = [];
    this.globalDayWiseMenuData.filter((elm:MealMenu) => {
      if(date === elm.date) {
        this.mealMenuItems.push(elm)
      }
    })
  }

  getGroupedMenu() {
    this.commonService._getGroupedMenuItems().subscribe({
      next: (resp) => {
      //  this.globalDayWiseMenuData = resp;
       this.createMenuByCartItems(resp)
      },
      error: (error) => {
        alert(error.error.message.message);
      },
    });
  }

  gotoCheckOut(): void {
    if (this.cartItems.length) {
      this.router.navigate(['checkout']);
    } else {
      alert('Cart should not be empty!!');
    }
  }

  createCartItem(item:Item,catName:string) { 
  item.catName = catName;
  this.cartItemService.createCartItem(item,this.cartItems,this.globalDayWiseMenuData);
  }

  updateCartItem(item:Item) {
    this.cartItemService.updateCartItem(item,this.cartItems,this.globalDayWiseMenuData);
  }

  createMenuByCartItems(menuItems:MealMenu[]) {
    if(this.cartItems) {
      this.cartItems.forEach((cartItem: SelectedCartItems) => {
        menuItems.map((menu: MealMenu) => {
          if (menu.date == cartItem.date) {
            cartItem.items?.forEach((cartItem:Item)=>{
              menu.category.map((category:Category)=>{
                category.items.map((menuItem:Item)=>{
                  if(cartItem._id === menuItem._id) {
                    menuItem.quantity = cartItem.quantity
                    menuItem.catName = cartItem.catName
                  }
                })
              })
            })
          }
        });
      });
      this.cartService.setGroupedMenuData(menuItems);
    }
  }

  getDayName(dateString:string) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
}

}
