import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { UserService } from '@app/serveces/app/user/user.service';
import { cateringEnquiry } from '@app/models/user.model';
import { ToasterService } from '@app/components/toaster/toaster.service';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-catering-enquiry',
  templateUrl: './catering-enquiry.component.html',
  styleUrls: ['./catering-enquiry.component.scss']
})
export class CateringEnquiryComponent implements OnInit {

  cateringEnquiry:FormGroup

  constructor(private formBUilder:FormBuilder, private userService:UserService, private toaster:ToasterService, private datePipe:DatePipe) { 
    this.cateringEnquiry = this.formBUilder.group({
      name: ["",[Validators.required,Validators.maxLength(50)]],
      email: ["",[Validators.required,Validators.email]],
      phoneNumber: ["",[Validators.required,Validators.pattern(/^(?:(?:\+44)|(?:0))(?:\s|-)*7\d{3}(?:\s|-)*\d{6}$/)]],
      postcode: ["",[Validators.required,Validators.pattern(/^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/)]],
      address: ["",[Validators.required,Validators.maxLength(250)]],
      cateringDateTime: [""],
      adultCount: [0],
      childCount: [0],
      requireDelivery: [0],
      isBuffetNeeded: [false],
      isStaffNeeded: [false]
    })
  }

  todayDate:any;

  ngOnInit(): void {
    this.todayDate = this.datePipe.transform(new Date(),'YYYY-MM-dd');
    console.log(this.todayDate)
  }

  _submitCateringEnquiry(payload:cateringEnquiry) {
    if(this.cateringEnquiry.valid) {
        this.userService._submitCateringEnquiry(payload).subscribe({
          next:(resp)=>{
            this.toaster.success("Your enquiry has been submitted successfully!",{autoClose:true})
            this.cateringEnquiry.reset();
            this.cateringEnquiry.patchValue({
              adultCount: 0,
              childCount: 0,
              requireDelivery:0,
              isBuffetNeeded: false,
              isStaffNeeded: false
            })
          },
          error:err=>this.toaster.error("Request Submission has been failed! try again.",{autoClose:true})
        })
    } else {
      this.toaster.error("Required fields should not be empty!",{autoClose:true})
    }
  }

}
