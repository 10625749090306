import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/serveces/app/user/user.service';
import { map } from 'rxjs/operators';
import { ConfirmationBoxService } from '@app/components/confirmation-box/confirmation-box.service';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { cancelDayItem } from '@app/models/cart.model';
import { ToasterService } from '@app/components/toaster/toaster.service';


@Component({
  selector: 'app-user-orders',
  templateUrl: './user-orders.component.html',
  styleUrls: ['./user-orders.component.scss']
})
export class UserOrdersComponent implements OnInit {

  userOrderItems:any;

  constructor(private userService:UserService,private confirmation:ConfirmationBoxService, private cartService:CartService, private toaster:ToasterService) { }

  ngOnInit(): void {
    this.getUserOrderHistory();
  }

  getUserOrderHistory() {
    this.userService._getUserOrderHistory().pipe(
      // map((resp:any)=>{
      //   const items = [...resp];
      //   items.sort((a: any, b: any) =>
      //    new Date(a.updatedAt).getDate() - new Date(b.createdAt).getDate()
      //    );
      //   return items
      // })
    ).subscribe({
      next:(resp:any)=>{
        this.userOrderItems = resp.reverse();
      },
      error:(error:any)=>{
        console.log(error)
      }
    })
  }

  cancelDayOrder(orderId:string,date:string,ordStatus:string){
    if(ordStatus === 'cancelled') {
      this.toaster.error('We have already cancelled this order!',{autoClose:true});
      return;
    }
    const payload:cancelDayItem = {
      orderId,
      date
    }
    let message = 'Are you sure you want to cancel?';
    this.confirmation.confirm("Confirmation",message,'Ok','Cancel').then(resp=>{
      if(resp) {
        this.cartService.cancelDayOrder(payload).subscribe({
          next:(resp:any)=>{
            this.getUserOrderHistory();
            this.toaster.success(resp.message,{autoClose:true})
          },
          error:(err:any)=> {
            this.toaster.error(err.message,{autoClose:true})
          }
        })
      }
    })
  }

}
