<header id="header" #header class="fixed-top pt-2">
    <div class="logo me-auto">
        <div class="logo-container" style="z-index: 999;">
            <a href="#" class="d-inline-block"  role="button" (click)="navigatePage('home')" routerLink="home">
                <img src="../../../assets/images/logo.svg" alt="logo" />
            </a>
        </div>
    </div>
    <div class="container justify-content d-flex gap-5 align-items-center position-relative">
        <nav id="navbar" class="navbar navbar-expand-lg">
            <button class="navbar-toggler" (click)="openNav()" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="true" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a href="#" routerLink="home" class="nav-link">Home</a>
                    </li>
                    <li>
                        <a href="#" routerLink="current-menu" class="nav-link">Current Menu</a>
                    </li>
                    <li>
                        <a href="#" routerLink="about-us" class="nav-link">About Us</a>
                    </li>
                    <li>
                        <a href="#" routerLink="how-it-works" class="nav-link">How it works</a>
                    </li>
                    <li>
                        <a href="#" routerLink="faqs" class="nav-link">FAQS</a>
                    </li>
                    <li>
                        <a href="#" routerLink="catering" class="nav-link">catering</a>
                    </li>
                    <li>
                        <a href="#" routerLink="contact-us" class="nav-link">Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="d-flex gap-2">
                <!-- <button (click)="_loginToAccount()" role="button" class="btn btn-round pad-user text-uppercase btn-green"></button> -->
                <div role="button" (click)="navigatePage('mycart')" class="position-relative">
                    <span *ngIf="cartItemsCount > 0" class="badge badge-info">{{ cartItemsCount }} <span class="heartbeat"></span></span>
                    <i class="bi bi-cart4 me-2 font-26-icn"></i>
                </div>
                <div *ngIf="!isLoginUser" role="button" (click)="navigatePage('userlogin')">
                    <i class="bi bi-person-circle font-26-icn" ></i>
                </div>
            <div *ngIf="isLoginUser">
                <div ngbDropdown class="d-inline-block">
                    <div role="button" id="dropdownBasic1" ngbDropdownToggle>
                        <i class="bi bi-person-circle font-26-icn" ></i>
                    </div>
                    <div style="font-size: 14px;" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <p style="padding: 0 10px; font-size: 0.85rem; margin: 0; text-align: left; text-transform: capitalize; font-weight: normal;">
                            <b *ngIf="userInfo"> {{ userInfo.firstName }} {{ userInfo.lastName }}</b>
                        </p>
                        <p *ngIf="userInfo" style="padding: 5px 10px; font-size: 0.85rem; margin: 0; text-align: center; text-transform: lowercase; font-weight: normal;">
                            {{userInfo.email}}
                        </p>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)="navigatePage('myorders')">
                            <i class="bi bi-view-list me-2"></i>My Orders
                        </button>
                        <button ngbDropdownItem (click)="logOutUser()">
                            <i class="bi bi-box-arrow-right me-2"></i>Log Out
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center py-2 pad-mob-nt bg-notification"> 
        <p class="m-0 font-sm font-mob announce-mob marquee"><b>30%</b> off first order with code MMD30 + <b>{{ 1.20 | currency:'GBP' }}</b> off on every extra dabba same day</p>
    </div>
</header>