import { Component, HostListener, OnInit, TemplateRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { Router } from '@angular/router';
import { UserService } from '@app/serveces/app/user/user.service';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';
import { CommonService } from '@app/serveces/app/common/common.service';
import { AddressPayload, UserAddress, editPhoneNumber } from '@app/models/user.model';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from '@app/components/toaster/toaster.service';
import { couponCode } from '@app/models/cart.model';
import { Order } from '@app/models/cart.model';
import { FormState } from '@app/models/app.enum';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ModalConfig } from '@app/components/model-window/modal.config';
import { ModelWindowComponent } from '@app/components/model-window/model-window.component';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";



@Component({
  selector: 'app-user-checkout',
  templateUrl: './user-checkout.component.html',
  styleUrls: ['./user-checkout.component.scss']
})
export class UserCheckoutComponent implements OnInit, OnDestroy,AfterViewInit {

  buttonText?:string = "Place Order";
  userInfo:any;
  groupedMenuItem:any;
  isAddNewAddress:boolean = false;
  selectedAddress?:AddressPayload;
  @ViewChild('modal') private modalContent: TemplateRef<any> | undefined;
  @ViewChild('couponWindow') private couponWindow: TemplateRef<any> | undefined;
  private modalRef: NgbModalRef | any
  discountCoupons:any;
  appliedCoupon?:couponCode;
  formState!:string;
  userAddressEdit!:AddressPayload;
  cartItems:any;
  userAddress:any;
  userPhoneNumber:string | undefined;
  isShowCartItems:boolean = true;
  isEditPhoneNumber:boolean = false;
  safeArea:string | undefined;
  windowTitle:string | undefined;
  couponCodeName:couponCode = {
    _id:'',
    code:'',
    discountAmont:0
  };
  isActivePhoneNumber:boolean = true;
  privacyPolicy:FormGroup;
  windowInnerWidth:number;

  //subscriptions

  delvaryAddressSubscription$:Subscription | undefined;
  discountCouponSubscription$:Subscription | undefined;
  cartItemsSubscription$:Subscription | undefined;
  safeAreaSubscription$:Subscription | undefined;

  ModalConfig: ModalConfig = {
    modalTitle: 'Title Here',
    customClass:'my-class',
    disableDismissButton: () => {
      return true;
    },
    closeButtonLabel: 'Ok',
  };

  constructor(private userService:UserService, private authService:AuthenticationService, private cartService:CartService, private router:Router, private commonService:CommonService, private modalService: NgbModal, private toaster:ToasterService,private formBUilder:FormBuilder) { 
    this.windowInnerWidth = window.innerWidth;
    this.privacyPolicy = this.formBUilder.group({
      termsAndConditions:[false,[Validators.requiredTrue]]
    })
    this.userInfo = JSON.parse(this.authService.userInfoData);
    if(this.userInfo.phoneNumber == null || !this.userInfo.phoneNumber) {
      this.isActivePhoneNumber = false;
    }
    this.userPhoneNumber = this.userInfo.phoneNumber;
    const item: any = sessionStorage.getItem('userSavedCartInfo');
    if(item) {
      const safeArea = JSON.parse(item);
      this.safeArea = safeArea.safePlace;
    }
  }
  ngAfterViewInit(): void {
    this.windowInnerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getUserAddresses();

    this.privacyPolicy.get('termsAndConditions')?.valueChanges.subscribe({
      next:resp=>this.cartService.setIsAcpCnd(resp)
    })
    // this.getAllCouponCodes();
    this.delvaryAddressSubscription$ = this.cartService.getDelvryAddress$.subscribe({
      next:(resp:AddressPayload)=>{
        this.selectedAddress = resp;
      }
    })
    this.discountCouponSubscription$ = this.cartService.getDiscountApplied$.subscribe({
      next:(resp:couponCode)=>{
        this.appliedCoupon = resp;
      }
    })
    this.cartItemsSubscription$ = this.cartService.cartItems.subscribe({
      next: (resp) => {
        if (resp) {
          this.cartItems = resp.sort(
            (a: any, b: any) =>
              new Date(a.date).getTime() - new Date(b.date).getTime()
          );
        }
      },
    });

    this.safeAreaSubscription$ = this.cartService.getSafeArea().subscribe({
      next: (resp:string) => {
          this.safeArea = resp
      }
      
    })
    
  }

  @ViewChild('mdWindow') private modelWindowComponent: ModelWindowComponent | any;

  async openModal() {
    return await this.modelWindowComponent.open();
  }

  private modalRefTerms: NgbModalRef | any

  @ViewChild('privacyPolcy') private termsWindow: TemplateRef<any> | undefined;

  openPolocyWindow(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRefTerms = this.modalService.open(this.termsWindow,{ })
      this.modalRefTerms.result.then(resolve, resolve)
    })
  }
  closePolocyWindow(reason:string) {
    this.modalRefTerms.dismiss(reason)
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.windowInnerWidth = window.innerWidth;
    if(window.innerWidth > 992) {
      this.isShowCartItems = true;
    } else {
      this.isShowCartItems = false;
    }
  }

  createCart() {
    this.cartService.createCart()
    .subscribe({
      next:(resp:any)=>{
        const order:Order = {
          cartId: resp._id,
          addressId:this.selectedAddress?._id,
          contactNumber:this.userInfo.phoneNumber,
          discount: this.cartService.appliedCouponObj?._id,
          safePlace: this.safeArea || ''
        }
        // console.log(order)
        sessionStorage.setItem('userSavedCartInfo',JSON.stringify(order))
        this.router.navigate(['proceed-payment'])
      },
      error:error=>{
        if(error.message) {
          this.toaster.error(error.message,{autoClose:true})
        }
      }
    })
  }

  getUserCartInfo() {
    this.userService._getUserCartInfo()
    .subscribe({
      next:(res:any)=>{
        const {cartItems} = res;
       if(cartItems && this.groupedMenuItem) {
        cartItems.foreach((ctItem:any)=>{
          this.groupedMenuItem.map((elm:any)=>{
            if(ctItem.mealMenu === elm._id) {
              console.log(elm)
            }
          })
        })
       }
      },
      error:error=>console.log(error)
    })
  }

  setCurrentOrderPhone(value:any) {
    this.userInfo.phoneNumber =  value.userPhoneNumber;
    this.authService.setUserProfile(this.userInfo);
    this.isEditPhoneNumber = false;
    this.isActivePhoneNumber = true;
  }

  setUserPhoneNumber(value:any) {
    const payload:editPhoneNumber = {
      phoneNumber: value.userPhoneNumber
    }
    this.userService._saveUserPhoneNumber(payload).subscribe({
      next:resp=>{
        if(resp) {
          this.authService.updateUserInfo(resp)
          this.userInfo.phoneNumber =  value.userPhoneNumber;
          this.authService.setUserProfile(this.userInfo);
          this.isEditPhoneNumber = false;
          this.isActivePhoneNumber = true;
        }
      },
      error:err=>this.toaster.error(err.message,{autoClose:true})
    })
  }

  stopEditPhone() {
    this.userInfo.phoneNumber = this.userPhoneNumber
    this.isEditPhoneNumber = false;
  }

  changePhoneNumber() {
    this.isEditPhoneNumber = true;
    this.userPhoneNumber = this.userInfo.phoneNumber;
  }

  setCartInfo() {
    let cart = this.cartService.cartItem
  }

  goToMenupage () {
    this.router.navigate(['menu-items'])
  }

  getGroupedMenu() {
    this.commonService._getGroupedMenuItems().subscribe({
      next: (resp:any) => {
        this.groupedMenuItem = resp;
        // this.cartService.setGroupedMenuData(resp);
      },
      error: (error:any) => {
        console.log(error);
        alert(error.error.message.message);
      },
    });
  }

  addNewAddress() {
    this.windowTitle = 'Add New Address';
    this.isAddNewAddress = true;
    this.formState = FormState.CREATE;
    this.modalRef = this.modalService.open(this.modalContent,{ windowClass: 'custom-ngb-modal-window', backdrop : 'static', keyboard : false})
  }

  closeNewAddress(event:boolean) {
    this.modalRef.dismiss('reason')
    this.isAddNewAddress = event;
    this.formState = FormState.NOTSET;
    
  }

  openEditAddress(address:AddressPayload) {
    // this.closeNewAddress(false);
    this.windowTitle = 'Edit Address';
    setTimeout(() => {
      this.userAddressEdit = address;
      this.formState = FormState.UPDATE;
      this.isAddNewAddress = true;
      this.modalRef = this.modalService.open(this.modalContent,{ windowClass: 'custom-ngb-modal-window', backdrop : 'static', keyboard : false})
    },5);
    
  }

  getUserAddresses() {
    this.userService._getUserAddresses().pipe(
      map((resp:AddressPayload | any )=>{
        resp.isSelected = false;
        // this.cartService.removeDelveryAddress();
        return resp
      })
    ).subscribe({
      next:(resp)=>{
        this.userAddress = resp;
        if(this.selectedAddress) {
          this.selectAddress(this.selectedAddress._id || "")
        }
        if(!this.selectedAddress && this.userAddress.length && this.userInfo.address.default) {
          this.selectAddress(this.userInfo.address.default)
        } 
      }
    })
  }

  addedAddress(address:AddressPayload) {
    address.isSelected = false;
    const findItem = this.userAddress.find((el:AddressPayload)=>{
      return  el._id === address._id
    })

    if(findItem) {
      let findIndex = this.userAddress.findIndex((el:AddressPayload)=>{
        return  el._id === address._id
      })
      this.userAddress.splice(findIndex,1)
    }
    
    this.userAddress.unshift(address);
    const aId = address._id || "";
    this.selectAddress(aId);
  }

  selectAddress(i:string) {
    this.userAddress.map((el:AddressPayload)=>{
        el.isSelected = false;
    })
    let selAdd = this.userAddress.find((el:AddressPayload)=>{
      return el._id === i;
    })
    this.userService._checkPostalCode(selAdd.address.postCode.trim().toUpperCase()).subscribe({
      next:(resp:any)=>{  
        const { message, deliverable } = resp;
        if(deliverable) {
          selAdd.isSelected = true;
          this.cartService.setDelveryAddress(selAdd)
        } else {
          this.cartService.removeDelveryAddress();
          this.toaster.error(message,{autoClose:true})
        }
      },
      error:error=>this.toaster.error(error.message,{autoClose:true})
    })
  }

  getAllCouponCodes() {
    this.cartService._getAllCouponCodes().subscribe({
      next:(resp:any)=>{
        this.discountCoupons = resp;
      }
    })
  }

  openDiscoutCodes(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent,{ backdrop : 'static', keyboard : false})
      this.modalRef.result.then(resolve, resolve)
    })
  }

  closeWindow(text:string) {
    this.modalRef.dismiss(text)
    this.modalRef.dismiss('reason')
    this.isAddNewAddress = false;
    this.formState = FormState.NOTSET;
  }

  applyDiscountCoupon(coupon:any) {
    console.log(coupon)
    // this.cartService.getDiscountAmount(coupon._id).subscribe({
    //   next:(resp:any)=>{
    //     this.appliedCoupon = {
    //       _id:coupon._id,
    //       code:coupon.code,
    //       discountAmont:resp
    //     }
    //     this.cartService.setDiscountCoupon(this.appliedCoupon)
    //     this.modalRef.dismiss();
    //     this.modalRef = this.modalService.open(this.couponWindow,{ backdrop : 'static', keyboard : false})
    //     setTimeout(() => {
    //       this.modalRef.dismiss();
    //     }, 3500);
    //   }
    // })
  }

  checkDeliverable(paylod:any) {
    this.userService._checkPostalCode(paylod.postalCode.trim().toUpperCase()).subscribe({
      next:(resp:any)=>{  
        const { message, deliverable } = resp;
        console.log(resp)
      },
      error:error=>console.log(error)
    })
  }
  

  ngOnDestroy(): void {
    this.delvaryAddressSubscription$?.unsubscribe();
    this.discountCouponSubscription$?.unsubscribe();
    this.cartItemsSubscription$?.unsubscribe();
    this.safeAreaSubscription$?.unsubscribe();
  }

}
