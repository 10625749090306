import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { UserService } from '@app/serveces/app/user/user.service';
import { contactUsForm } from '@app/models/user.model';
import { ToasterService } from '@app/components/toaster/toaster.service';


@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactUsForm:FormGroup

  constructor(private formBUilder:FormBuilder, private userService:UserService, private toaster:ToasterService) { 
    this.contactUsForm = this.formBUilder.group({
      firstName:["",[Validators.required,Validators.maxLength(50)]],
      lastName:["",[Validators.required,Validators.maxLength(50)]],
      email:["",[Validators.required,Validators.email]],
      phoneNumber:["",[Validators.required,Validators.pattern(/^(?:(?:\+44)|(?:0))(?:\s|-)*7\d{3}(?:\s|-)*\d{6}$/)]],
      message:["",[Validators.required,Validators.maxLength(200)]]
    })
  }

  ngOnInit(): void {
  }

  _submitUserMessage(payload:contactUsForm) {
    if(this.contactUsForm.valid) {
      this.userService._submitContactForm(payload).subscribe({
        next:(resp:any)=> {
          this.toaster.success('Message received successfully!',{autoClose:true});
          this.contactUsForm.reset();
        },
        error:err=>this.toaster.error(err.message,{autoClose:true})
      })
    } else {
      this.toaster.error('Required fields should not be empty!',{autoClose:true})
    }
  }

}
