import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/serveces/app/common/common.service';
import { CategoryNames } from '@app/models/app.enum';
import { ServiceDatesService } from '@app/serveces/menu-dates/service-dates.service';
import { HeaderService } from '@app/components/header/header.service';


@Component({
  selector: 'app-current-menu',
  templateUrl: './current-menu.component.html',
  styleUrls: ['./current-menu.component.scss']
})
export class CurrentMenuComponent implements OnInit {

  constructor(private commonService:CommonService, private dateList: ServiceDatesService, public headerService:HeaderService) {
    this.weeksList = this.dateList.getSevenDayList();
    this.selectedWeek = this.weeksList[0];
   }

  weeksList: any;
  selectedWeek:any;
  searchContent:string[] = [CategoryNames.MAIN,CategoryNames.SIDES,CategoryNames.BREAD,'All'];
  currentIndex:number = 0;
  catNames:any = CategoryNames
  addText:string = 's';

  mainMenuItems:any = [];
  sideMenuItems:any = [];
  breadMenuItems:any = [];
  allMenuItems:any = [];

  ngOnInit(): void {
    this.commonService._getGroupedMenuItems().subscribe({
      next:resp=>{
        resp.forEach((el:any)=>{
          el.category.forEach(
            (cat:any)=>{
              switch (cat.name) {
                case CategoryNames.MAIN:
                  this.mainMenuItems.push(cat);
                  break;
                case CategoryNames.SIDES:
                  this.sideMenuItems.push(cat);
                  break;
                case CategoryNames.BREAD:
                  this.breadMenuItems.push(cat);
                  break;
              }
              this.allMenuItems.push(cat);
            }
          )
        })
        this.filterBySearchItem(0)
      }

    })
   
  }

  filterBySearchItem(i:number) {
    this.currentIndex = i;
    const srchItem = this.searchContent[i];
    switch (srchItem) {
      case CategoryNames.MAIN:
        this.getGroupedItemData(this.mainMenuItems)
        break;
      case CategoryNames.SIDES:
        this.getGroupedItemData(this.sideMenuItems)
        break;
      case CategoryNames.BREAD:
        this.getGroupedItemData(this.breadMenuItems)
        break;  
      case 'All':
        this.getGroupedItemData(this.allMenuItems)
    }
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  getSelectedWeek(e: any) {
    this.selectedWeek = this.weeksList[e];
    this.filterBySearchItem(this.currentIndex)
  }

  mealMenuItems:any = [];

  getGroupedItemData(menuItems:any) {
    this.mealMenuItems = [];
    menuItems.filter((elm: any) => {
      if (
        elm.date >= this.selectedWeek.start &&
        elm.date <= this.selectedWeek.end
      ) {
        this.mealMenuItems.push(elm);
      }
    });
    this.mealMenuItems = this.mealMenuItems.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }

  allergenInfo(event:any) {
    if(event.target.nextElementSibling.className=='hide-alg'){
      event.target.nextElementSibling.classList.add('show-alg')
      event.target.classList.add('rotate')
      event.target.nextElementSibling.classList.remove('hide-alg')
    } else {
      event.target.nextElementSibling.classList.add('hide-alg')
      event.target.classList.remove('rotate')
      event.target.nextElementSibling.classList.remove('show-alg')
    }
  }

}
