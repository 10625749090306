<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="contact-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">About Us</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-4 bg-about-us">
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-6 col-lg-4 m-auto">
                <div class="aboutus-img-cnt">
                    <img src="../../assets/images/chef-img.png" alt="aboutus image" srcset="">
                </div>
            </div> -->
            <div class="col-md-12 col-lg-8 m-auto">
                <div class="aboutus-text-cnt">
                    <h2 class="page-headings text-center pt-2 mb-5">ABOUT MAKE MY DABBA</h2>
                    <p>Welcome to Make My Dabba, where we deliver authentic Indian cuisine right to your
                        doorstep. Our freshly cooked and thoughtfully curated meals celebrate the rich heritage and
                        culinary traditions of India. Experience the vibrant spices, diverse regional specialties, and
                        warm hospitality from the comfort of your home.</p>
                    <p>
                        Life can be hectic, and we understand the need for convenient dining options. With our reliable
                        delivery service, you can enjoy a freshly prepared Indian meal every day without the hassle of
                        cooking.
                    </p>
                    <p>
                        Our team of talented chefs brings years of experience and love to your table, recreating the
                        wholesome taste of Indian home cooking. Handpicked finest ingredients ensure authenticity and
                        flavour in every dish.
                    </p>
                    <p>
                        Explore our extensive menu, offering a wide range of options for every palate and dietary
                        preference. From aromatic biryanis to fragrant curries, we cater to all tastes.
                    </p>
                    <p>
                        Quality and hygiene are paramount to us. Each meal is prepared with dedication in a clean and
                        sanitized environment. Our eco-friendly packaging keeps your food fresh until it reaches your
                        door.
                    </p>
                    <p>
                        Customer satisfaction is our priority. We go the extra mile to exceed your expectations,
                        providing exceptional service and prompt delivery.
                        Join us on a culinary journey to the vibrant streets of India. Let food bring us together as we
                        create an unforgettable dining experience for you.
                    </p>
                    <p>
                        Place your order today and experience the convenience, authenticity, and delight of Make My
                        Dabba.
                    </p>
                    <div class="mt-5 pb-2 text-center">
                        <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                    </div>
                </div>
                <!-- <div class="pt-3 about-list">
                    <ul>
                        <li>People who want to lose weight</li>
                        <li>Athlets and those who lead an active lifestyle</li>
                        <li>Lorem ipsum dolor sit</li>
                        <li>People who want to lose weight</li>
                        <li>fasting</li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- <div class="container-fluid sub-page-banner about-page-bg position-relative">
    <div class="container">
        <div class="d-flex gap-3 py-4">
            <div class="col-3 icon-c-round position-relative">
                <h2 class="page-titles-font text-center color-dark"><b>1500+</b></h2>
                <p class="text-center mb-0 color-secondary font-mid">Happy Clients</p>
            </div>
            <div class="col-3 icon-c-round position-relative">
                <h2 class="page-titles-font text-center color-dark"><b>500+</b></h2>
                <p class="text-center mb-0 color-secondary font-mid">Orders Everyday</p>
            </div>
            <div class="col-3 icon-c-round position-relative">
                <h2 class="page-titles-font text-center color-dark"><b>15+</b></h2>
                <p class="text-center mb-0 color-secondary font-mid">Professionals</p>
            </div>
            <div class="col-3">
                <h2 class="page-titles-font text-center color-dark"><b>365</b></h2>
                <p class="text-center mb-0 color-secondary font-mid">Days Open</p>
            </div>
        </div>
    </div>
</div> -->