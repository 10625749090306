<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Design Your Dabba</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-3 pd-sm-0 bg-light">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 padding-mob" *ngIf="weeksList && weeksList.length!==0">
                <div class="date-select-desg py-2 position-sticky zindex-sticky box-shadow" [style.top.px]="headerService.getHeaderHeight | async">
                    <div class="form-group">
                        <div class="col-md-11 col-sm-12 col-lg-11">
                            <div class="row justify-content-center" style="align-items: center;">
                                <div class="col-md-5 col-sm-6 col-lg-4">
                                    <label class="col-form-label ms-1 page-titles-font set-label-size">Select a week and choose your day(s):</label>
                                </div>
                                <div class="col-md-7 col-sm-12 col-lg-7">
                                    <form class="form-inline">
                                        <select class="form-select" #dt (change)="getSelectedWeek(dt.value)">
                                            <option *ngFor="let week of weeksList; let i = index" value="{{i}}">
                                                {{ week.start | date:'EEEE, LLL d'}} - {{week.end | date:'EEEE, LLL d'}}
                                            </option>
                                        </select>
                                    </form>
                                </div>
                            </div>
                            <span class="item-sel-text"><b>Choose 1 Main -> Choose 1 Side -> Choose 1 Rice/Bread</b></span>
                        </div>
                    </div>
                </div>
                <div class="bg-white px-4 pb-4 pd-sm" style="height: fit-content;">
                    <div class="product-cnt pt-3" *ngFor="let dayItem of mealMenuItems; let dayIndex = index">
                        <div class="d-flex">
                            <div class="me-auto position-relative" *ngIf="dayItem.genId === genId">
                                <h5 class="page-titles"> {{ dayItem.date | date:'EEEE, LLL d' }} </h5>
                            </div>
                        </div>
                        <app-day-wise-menu [dayItem]="dayItem" (newCartItem)="newCartItem($event)" (updateCartItem)="updateCartItem($event)" (addNewMenu)="addNewMenu($event)"></app-day-wise-menu>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 mt-lg-0 mt-sm-5 position-cart-cnt show-cart-items" *ngIf="isShowCartItems">
                <div class="date-select-desg py-2 px-3 position-relative">
                    <label class="col-form-label page-titles-font set-label-size" style="display: flex; justify-content: space-between;"><span>Basket</span>  <span style="font-size: 1rem;">Delivery - Between 3pm-7pm on every chosen day</span> </label>
                </div>
                <app-product-list>
                    <div class="mt-2 py-2 d-flex justify-content-center">
                        <button type="button" [disabled]="!cartItems.length" (click)="gotoCheckOut()" class="btn btn-round btn-theme-yellow">Go to Checkout</button>
                    </div>
                </app-product-list>
            </div>
        </div>
    </div>
</div>