import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbAccordionConfig, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
  providers: [NgbAccordionConfig],
})
export class FaqPageComponent implements OnInit {

  constructor(private router:Router, accConfig: NgbAccordionConfig,private modalService: NgbModal) { 
    accConfig.closeOthers = true;
  }

  ngOnInit(): void {
  }

  private modalRefTerms: NgbModalRef | any

  @ViewChild('videoCnt') private videoWindow: TemplateRef<any> | undefined;

  openVideoWindow(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRefTerms = this.modalService.open(this.videoWindow,{ })
      this.modalRefTerms.result.then(resolve, resolve)
    })
  }
  closeVideoWindow(reason:string) {
    this.modalRefTerms.dismiss(reason)
  }

  navigatePageUrl(url:string) {
    this.router.navigate([url]);
  }

}
