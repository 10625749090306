<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="contact-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Contact Us</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-2 vh-75 bg-contact-us">
    <div class="container">
        <div class="my-2 col-md-10 m-auto contact-info-cnt text-center">
            <div class="col-md-12 col-lg-5 m-auto">
                <h3 class="page-titles-font text-uppercase"> get in touch</h3>
                <!-- <p><span class="font-mid">7 Weaver St, Burley, Leeds LS4 2AU, United Kingdom</span></p> -->
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-phone me-2 icon-red" viewBox="0 0 16 16">
                        <path
                            d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                        <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg><b>0800 999 1238</b>
                </p>
                <!-- <p class="font-mid">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock me-2 icon-green" viewBox="0 0 16 16">
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                          </svg>
                        Monday to Saturday: 9:00am to 6:00pm </p> -->
                <p class="font-mid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-envelope me-2 icon-red" viewBox="0 0 16 16">
                        <path
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                    info@makemydabba.com
                </p>
                <p><span class="font-mid">If you have a general query then please visit our <b><i>FAQ
                                Page</i></b></span></p>
            </div>
            <div class="col-md-12 col-lg-6 text-center m-auto mt-5">
                <h3 class="page-titles-font text-uppercase mb-2">Leave a message</h3>
                <div>
                    <form [formGroup]="contactUsForm" (submit)="_submitUserMessage(contactUsForm.value)">
                        <div class="row">
                            <div class="col-md-6 mb-2">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="First Name*"
                                        formControlName="firstName" [ngClass]="{'is-invalid': contactUsForm.controls['firstName'].touched && contactUsForm.controls['firstName'].errors}" />
                                        <label class="invalid-feedback font-sm"*ngIf="contactUsForm.get('firstName')?.errors?.['maxlength']">
                                            Maximum limit is exceeded
                                        </label>
                                </div>
                            </div>
                            <div class="col-md-6 mb-2">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Last Name*"
                                        formControlName="lastName" [ngClass]="{'is-invalid': contactUsForm.controls['lastName'].touched && contactUsForm.controls['lastName'].errors}" />
                                        <label class="invalid-feedback font-sm"*ngIf="contactUsForm.get('lastName')?.errors?.['maxlength']">
                                            Maximum limit is exceeded
                                        </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <input type="email" class="form-control" placeholder="Email*" formControlName="email" [ngClass]="{'is-invalid': contactUsForm.controls['email'].touched && contactUsForm.controls['email'].errors}" />
                        </div>
                        <div class="form-group mb-2">
                            <input type="text" class="form-control" placeholder="Phone Number*"
                                formControlName="phoneNumber" [ngClass]="{'is-invalid': contactUsForm.controls['phoneNumber'].touched && contactUsForm.controls['phoneNumber'].errors}" />
                                <label class="invalid-feedback font-sm"*ngIf="contactUsForm.get('phoneNumber')?.errors?.['pattern']">
                                    Enter a valid Phone Number
                                </label>
                        </div>
                        <div class="form-group mb-2">
                            <textarea class="form-control" rows="4" placeholder="Your Message*"
                                formControlName="message" [ngClass]="{'is-invalid': contactUsForm.controls['message'].touched && contactUsForm.controls['message'].errors}"></textarea>
                                <label class="invalid-feedback font-sm"*ngIf="contactUsForm.get('message')?.errors?.['maxlength']">
                                    Allowed 200 characters only
                                </label>
                            <small class="ms-2 text-left" style="color: black;">Your email address will not be published.</small>
                        </div>
                        <div class="mt-3 text-center">
                            <button type="submit" class="btn btn btn-round btn-green text-trns btn-mob btn-width">Send Message</button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>