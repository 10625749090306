import { Injectable } from '@angular/core';
import { HttpService } from '@app/serveces/http/http.service';
import { environment } from '@environments/environment';
import { Order } from '@app/models/cart.model';


declare const Stripe: any

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private Base_Url = environment.apiBaseUrl;

  constructor(private httpService:HttpService) { }

  placeOrder(payload:Order) {
    const url = `${this.Base_Url}/order`;
    return this.httpService.postService(url,payload)
  }
}
