<div class="position-relative container-bg">
    <div class="container">
        <div class="banner-wrap">
            <div class="banner-cnt">
                <h1 class="title-head text-center">Bringing the Flavors of India to Your Doorstep!</h1>
                <P class="desp-text">Three easy steps to a perfect Dabba(Meal)</P>
                <div class="order-steps">
                    <div class="step-box">
                        <span>1</span>
                        <p class="m-0 title-step">Choose <br>Mains</p>
                    </div>
                    <div class="step-box">
                        <span>2</span>
                        <p class="m-0 title-step">Choose <br>Sides</p>
                    </div>
                    <div class="step-box">
                        <span>3</span>
                        <p class="m-0 title-step">Choose <br>Accompaniments</p>
                    </div>
                </div>
                <p class="bnr-text">Prices for our 3 course dabba start from <b style="font-size: 1.2rem;">{{9.99 | currency:'GBP'}}</b>* </p>
                <!-- <p class="bnr-text">Prices start from <b style="font-size: 1.2rem;">{{9.99 | currency:'GBP'}}</b>* per Dabba</p> -->
                <div class="mt-4 text-center">
                    <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid py-4">
    <div class="container">
        <div class="my-3">
            <h2 class="page-headings text-center pt-lg-3 pt-xl-5">Why Choose Us?</h2>
            <p class="text-center">Fresh, authentic and tasty Indian meals delivered to your door step everyday</p>
            <div class="col-md-12 col-lg-12 col-xl-10" style="margin: 0 auto;">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-6 col-lg-4 py-2">
                        <div class="card card-body min-card-ht">
                            <div class="icon-img text-center">
                                <img src="../../assets/images/daily-fresh-icon.png" alt="fresh food icon">
                            </div>
                            <h5 class="page-titles-font mb-3 mt-2">Daily freshness</h5>
                            <p class="medium-font">Daily fresh cooking, delightfully crafted flavours, just for you!</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 py-2">
                        <div class="card card-body min-card-ht">
                            <div class="icon-img text-center">
                                <img src="../../assets/images/authenticity-icon.png" alt="fresh food icon">
                            </div>
                            <h5 class="page-titles-font mb-3 mt-2">Authenticity</h5>
                            <p class="medium-font">Guaranteeing that you receive meals bursting with authentic Indian
                                flavours, just as they are meant to be enjoyed</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 py-2">
                        <div class="card card-body min-card-ht">
                            <div class="icon-img text-center">
                                <img src="../../assets/images/variety-icon.png" alt="fresh food icon">
                            </div>
                            <h5 class="page-titles-font mb-3 mt-2">Variety</h5>
                            <p class="medium-font">From North to South, and East to West of India, taste the difference! A
                                new menu every day</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 py-2">
                        <div class="card card-body min-card-ht">
                            <div class="icon-img text-center">
                                <img src="../../assets/images/premium-ingredients-icon.png" alt="fresh food icon">
                            </div>
                            <h5 class="page-titles-font mb-3 mt-2">Premium Ingredients</h5>
                            <p class="medium-font">Finest, authentic Indian ingredients from trusted suppliers ensure the highest quality in every dish</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 py-2">
                        <div class="card card-body min-card-ht">
                            <div class="icon-img text-center" style="width: 110px;">
                                <img src="../../assets/images/delivery-icon-2.png" alt="fresh food icon">
                            </div>
                            <h5 class="page-titles-font mb-3 mt-2">Hassle-Free Delivery</h5>
                            <p class="medium-font">Sit back and relax as we deliver the goodness of Indian food right to your doorstep</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 py-2">
                        <div class="card card-body min-card-ht">
                            <div class="icon-img text-center">
                                <img src="../../assets/images/packaging-icon.png" alt="fresh food icon">
                            </div>
                            <h5 class="page-titles-font mb-3 mt-2">Sustainable Packaging</h5>
                            <p class="medium-font">Savor guilt-free with eco-conscious practices and sustainable packaging, contributing to a greener future</p>
                        </div>
                    </div>
                </div>
                <div class="mt-4 pb-2 text-center">
                    <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                </div>
            </div>
        </div>
    </div>
</div> <!-- why choose us -->
<div class="container-fluid pb-4" style="background-color: #3da73b;">
    <h2 class="page-headings text-center mb-4 pt-2 hw-order-hd">How to order?</h2>
    <div class="container position-relative">
            <div class="col-md-12 col-lg-12 col-xl-10 col-sm-12 m-auto">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-6 col-lg-3 hw-order line-img">
                        <div class="text-center">
                            <div class="m-auto" style="width: 235px;">
                                <img src="../../assets/images/laptop.png" alt="design order" >
                            </div>
                            <h5 class="page-titles-font mb-2 mt-3">Step-1</h5>
                            <p class="medium-font p-2 m-auto" style="width: 50%;" >Choose your delivery day - any day for next 28 days
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 hw-order line-dwn">
                        <div class="text-center">
                            <h5 class="page-titles-font mb-2 mt-3">Step-2</h5>
                            <p class="medium-font p-2 m-auto" style="width: 60%;">Design your meals <br> Pick any Mains, Sides and Accompaniments</p>
                            <div class="m-auto" style="width: 235px;">
                                <img src="../../assets/images/south-meal.png" alt="freshly cooked" >
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 hw-order line-img-2">
                        <div class="text-center">
                            <div class="m-auto" style="width: 150px;">
                                <img src="../../assets/images/package-menu.png" alt="delivered doorstep" >
                            </div>
                            <h5 class="page-titles-font mb-2 mt-3">Step-3</h5>
                            <p class="medium-font p-2 m-auto" style="width: 60%;">Repeat the above two steps for any day for up to next 28 days</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 hw-order">
                        <div class="text-center">
                            <h5 class="page-titles-font mb-2 mt-3">Step-4</h5>
                            <p class="medium-font p-2 m-auto" style="width: 60%;">Check out by creating an account. If you are already an existing customer, you can login to your account to complete the check out process</p>
                            <div class="m-auto" style="width: 225px;">
                                <img src="../../assets/images/delver-img.png" alt="enjoy meals" >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 text-center">
                    <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                </div>
            </div>
    </div>
</div>
<div class="container-fluid pt-md-2 pt-lg-4 sub-page-banner about-theme-bg position-relative">
    <div class="container">
        <div class="row gap-sm-3 align-items-center justify-content-center">
            <div class="col-md-4">
                <div class="hm-about-Img">
                    <img src="../../assets/images/banner/MakeMyDaba-our-mission.jpg" alt="about image" />
                </div>
            </div>
            <div class="col-md-7 col-lg-6 col-sm-8 pb-md-2 pb-lg-2 pb-xl-0">
                <div class="about-msg ms-md-4">
                    <h2 class="page-headings md-sm-2 mb-md-4">OUR MISSION</h2>
                    <p>
                        At our core, we are driven by the belief that everyone deserves access to delicious, authentic
                        Indian meals, even amidst the hectic pace of modern life. Our mission is to make this vision a
                        reality by offering freshly cooked and carefully curated dishes that encapsulate the true
                        essence of Indian cuisine. We understand that busy schedules can often leave little time for
                        meal preparation, leading to compromised dining experiences. Hence, we have made it our purpose
                        to bridge this gap and provide a seamless solution for those seeking convenient yet flavourful
                        options.
                    </p>
                    <div class="mt-4 pb-2 text-center">
                        <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid py-4">
    <div class="container">
        <div class="my-3">
            <h2 class="page-headings text-center pt-sm-3 pt-lg-3 pt-xl-5 mb-md-5 mb-sm-3">How it works?</h2>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xl-10 m-auto">
                <div class="row justify-content-center align-items-center">
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/design-order.png" alt="design order" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">1.Design & Order</h5>
                            <p class="medium-font p-2">Design your Dabba by choosing any Mains, Sides and Accompaniments. Repeat the above for any extra dabbas you want from the next 28 days.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/freshly-cooked.png" alt="freshly cooked" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">2.Freshly Cooked</h5>
                            <p class="medium-font p-2">We freshly cook your daily orders on the same day, delightfully crafted flavours, just for you!</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/deliveredToDoorstep.jpg" alt="delivered doorstep" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">3.Delivered To Your Doorstep</h5>
                            <p class="medium-font p-2">Let us bring the delight of Make my dabba to your doorstep</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 py-2">
                        <div class="text-center hwork-cnt">
                            <div class="card-img">
                                <img src="../../assets/images/enoy-meals.png" alt="enjoy meals" >
                            </div>
                            <h5 class="page-titles-font mt-md-2 mt-xl-3">4.Enjoy Fresh Meals</h5>
                            <p class="medium-font p-2">You enjoy the fresh and authentic Indian meals, with no cooking or clean-up needed</p>
                        </div>
                    </div>
                    
                </div>
                <div class="mt-5 pb-2 text-center">
                    <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid pt-5 sub-page-banner what-out-menu position-relative">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-4 col-lg-4">
                <div class="hm-about-Img">
                    <img src="../../assets/images/banner/MakeMyDabba-India.jpg" alt="about image" />
                </div>
            </div>
            <div class="col-md-8 col-lg-8 col-xl-6">
                <div class="about-msg ms-4">
                    <h2 class="page-headings mb-4">WHATS ON OUR MENU?</h2>
                    <p>Embark on a dynamic culinary journey with our ever-changing rotating menus inspired by the diverse flavours and regional cuisines of India. Indulge in authentic dishes that celebrate the rich heritage and natural way of eating in each destination, making every meal an unforgettable adventure. From the vibrant streets of Mumbai to the serene backwaters of Kerala, our menus showcase the essence of each region's culinary treasures. With a perfect blend of traditional spices, locally sourced ingredients, and time-honoured cooking techniques, every dish tells a unique story and leaves a lasting impression on your taste buds.</p>
                    <p class="mt-2" >Check out our menu and let us take you on a tantalizing journey through the mesmerizing world of Indian cuisine.
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-4 pb-2 text-center">
            <button type="button" (click)="goToProduct()" class="btn btn-round btn-green">Order Now</button>
        </div>
    </div>
</div>
<ng-template #modal>
    <form [formGroup]="checkPostalCode" (ngSubmit)="checkDeliverable(checkPostalCode.value)">
        <div class="modal-header">
            <h5 class="modal-title">Check to see if we deliver to your Leeds neighbourhood</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeWindow('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <input type="text" class="form-control" formControlName="postalCode" (change)="chagePostalCode($event)" (blur)="chagePostalCode($event)" placeholder="Enter Your Postal Code"
                    [ngClass]="{'is-invalid': checkPostalCode.controls['postalCode'].touched && checkPostalCode.controls['postalCode'].errors}" />
                <label class="invalid-feedback" *ngIf="checkPostalCode.get('postalCode')?.errors?.['pattern']">
                    Enter a valid postal code
                </label>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-round btn-theme-yellow">Check Deliverable</button>
        </div>
    </form>
</ng-template>
<app-model-window #mdWindow [modalConfig]="ModalConfig">
    <div class="row">
        <p class="text-center">{{ postCodeMessage }}</p>
    </div>
</app-model-window>