<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Checkout</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-4 bg-light">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 padding-mob">
                <div class="date-select-desg py-2 position-relative">
                    <label class="col-form-label ms-3 page-titles-font set-label-size"> User Info:</label>
                </div>
                <div class="product-cnt p-4 bg-white">
                    <div class="card">
                        <div class="card-body">
                            <div class="row font-sm">
                                <div><b> {{ userInfo.firstName }} {{ userInfo.lastName }}</b> </div>
                                <span class="me-auto"><b>Email:</b> {{userInfo.email}} </span>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 position-relative" *ngIf="!isActivePhoneNumber; else hidePhone">
                        <div class="my-2 col-md-8 col-sm-12">
                            <div class="col-md-6 col-sm-12 col-form-label page-titles-font set-label-size"><span class="text-danger">*</span>Contact Number: </div>
                                <div class="col-md-6 col-sm-12 row">
                                    <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && setUserPhoneNumber(f.value)" novalidate>
                                        <input type="text" name="userPhoneNumber" required #userPhoneNumber="ngModel" [(ngModel)]="userInfo.phoneNumber" placeholder="Enter Phone Number" class="form-control form-control-sm"
                                            pattern="^(?:(?:\+44)|(?:0))(?:\s|-)*7\d{3}(?:\s|-)*\d{6}$"
                                            [ngClass]="{'is-invalid': f.submitted && userPhoneNumber.errors }" />
                                        <label class="invalid-feedback font-sm" style="font-size: 0.75rem" *ngIf="f.submitted && userPhoneNumber.errors"> Enter a valid Phone Number </label>
                                        <button class="btn btn-sm btn-theme-yellow secondaryButton col-6 mt-2" type="submit">Add Phone Number</button>
                                    </form>
                                </div>
                            <span class="note-pn mt-2"><b class="me-2">Note:</b> Phone number is a required field.</span>
                        </div>
                    </div>
                    <ng-template #hidePhone>
                        <div class="my-2 position-relative" *ngIf="!isEditPhoneNumber; else noPhone">
                            <label class="col-form-label page-titles-font set-label-size"><span class="text-danger">*</span>Contact Number: </label> 
                            <span class="me-auto"> {{ userInfo.phoneNumber }} </span>
                            <button (click)="changePhoneNumber()" class="btn btn-sm btn-theme-yellow ms-auto secondaryButton">Change Phone Number</button> 
                        </div>
                        <ng-template #noPhone>
                            <div class="my-2 col-md-8 col-sm-12">
                                <div class="col-md-6 col-sm-12 col-form-label page-titles-font set-label-size"><span class="text-danger">*</span>Contact Number: </div>
                                    <div class="col-md-6 col-sm-12 row">
                                        <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && setCurrentOrderPhone(f.value)" novalidate>
                                            <input type="text" name="userPhoneNumber" required #userPhoneNumber="ngModel" [(ngModel)]="userInfo.phoneNumber" placeholder="Enter Phone Number" class="form-control form-control-sm"
                                                pattern="^(?:(?:\+44)|(?:0))(?:\s|-)*7\d{3}(?:\s|-)*\d{6}$"
                                                [ngClass]="{'is-invalid': f.submitted && userPhoneNumber.errors }" />
                                            <label class="invalid-feedback font-sm" style="font-size: 0.75rem" *ngIf="f.submitted && userPhoneNumber.errors"> Enter a valid Phone Number </label>
                                            <button class="btn btn-sm btn-theme-yellow secondaryButton col-6 mt-2" type="submit">Update Phone</button>
                                            <button class="btn btn-sm btn-theme-yellow secondaryButton col-4 mt-2 ms-2" (click)="stopEditPhone()">Cancel</button>
                                        </form>
                                    </div>
                                <span class="note-pn mt-2"><b class="me-2">Note:</b> Phone number is a required field.</span>
                            </div>
                        </ng-template>
                    </ng-template>
                    <div class="row" style="justify-content: space-between;">
                        <div class="col-md-7 col-sm-12">
                            <div class=" mt-2 position-relative row flex-wrap">
                                <div class="col-md-6 col-sm-12 col-form-label page-titles-font set-label-size"><span class="text-danger">*</span>Delivery Address:</div>
                                <div class="col-md-6 col-m-12 text-sm-start text-lg-end text-md-end mt-sm-0 mt-md-2" *ngIf="!isAddNewAddress">
                                    <button class=" btn btn-sm btn-theme-yellow secondaryButton" (click)="addNewAddress()" >
                                        <i class="bi bi-plus-circle-fill me-2"></i> Add New Address
                                    </button>
                                </div>
                            </div>
                            <div class="mx-ht" *ngIf="userAddress && userAddress.length; else noAddress">
                                <div class="col-md-12 col-sm-12 col-lg-12 mt-2" *ngFor="let addr of userAddress; let i = index;">
                                    <div class="card" >
                                        <div class="card-body font-sm add-cnt" (click)="selectAddress(addr._id)" [ngClass]="{'selected': addr.isSelected }" role="button">
                                            <span><b>Address:</b> {{ addr.address.address1 | titlecase  }}, {{addr.address.address2 | titlecase  }} </span>
                                            <span><b>City:</b> {{ addr.address.city  | titlecase }} </span>
                                            <span><b>Postcode:</b> {{ addr.address.postCode }}</span>
                                        </div>
                                        <div class="d-flex col-12 p-1 bg-light border-top"> 
                                            <span class="me-auto font-sm ms-2">Type: {{addr.type}} </span>
                                            <a role="button" *ngIf="addr.isSelected" (click)="openEditAddress(addr)" class="btn btn-sm btn-theme-yellow ms-auto secondaryButton py-1">Edit Address</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #noAddress>
                            <div class="col-md-6 row mb-2 px-2">
                                <div class="p-3 bg-light font-sm text-secondary">No Address found!  </div>
                                <span class="note-pn mt-2"><b class="me-2">Note:</b> Address is a required field. </span> 
                            </div>
                        </ng-template>
                        <div class="col-md-5 col-sm-12">
                            <div class="ms-auto text-lg-end">
                                <div class="mt-2">
                                    <div class="col-md-6 col-sm-12 col-form-label page-titles-font set-label-size text-start">Safe Area:</div>
                                    <div class="mt-2">
                                        <textarea class="form-control" #input="ngModel" [(ngModel)]="safeArea"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="windowInnerWidth <= 992">
                                <div class="col-md-6 col-sm-12 col-form-label page-titles-font set-label-size text-start">Terms and Conditions:</div>
                                <form [formGroup]="privacyPolicy">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" formControlName="termsAndConditions" >
                                        <label class="form-check-label" for="flexCheckDefault">
                                            <span class="text-danger">*</span>I accept the <a role="button" (click)="openPolocyWindow()" class="link-primary">Terms and Conditions of Service</a>
                                        </label>
                                        <label class="invalid-feedback font-sm" *ngIf="privacyPolicy.get('termsAndConditions')?.errors?.['required']">
                                            Accept Terms and Conditions is required
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 mt-lg-0 mt-sm-5 position-cart-cnt show-cart-items" *ngIf="isShowCartItems" >
                <div class="date-select-desg py-2 position-relative">
                    <label class="col-form-label ms-3 page-titles-font set-label-size">Basket</label>
                </div>
                <app-product-list [buttonText]="buttonText">
                    <app-coupon-code></app-coupon-code>
                    <!-- <div class="form-group mt-2 mb-3 ">
                        <small class="font-min text-muted">Discount Coupon</small>
                        <div class="position-relative">
                            <form name="form" #cpn="ngForm" (ngSubmit)="cpn.form.valid && applyDiscountCoupon(cpn.value)" novalidate>                            
                                <input type="text" class="form-control position-relative" required #userCouponCode="ngModel" name="userCouponCode" [(ngModel)]="couponCodeName.code"  placeholder="COUPON CODE"
                                [ngClass]="{'is-invalid': cpn.submitted && userCouponCode.errors }"
                                />
                                <label class="invalid-feedback font-sm" style="font-size: 0.75rem" *ngIf="cpn.submitted && userCouponCode.errors">Enter a valid Coupon Code</label>
                                <div style="right: 5px; top: 1px; font-size: 14px; " class="me-auto position-absolute mt-1">
                                    <button type="submit" style="border-radius: 5px;"  class="btn btn-sm btn-theme-yellow ms-auto secondaryButton">Apply</button>
                                </div>
                            </form>
                        </div>
                    </div> -->
                    <!-- (click)="openDiscoutCodes()" -->
                    <!-- (click)="applyDiscountCoupon(cpn.value)" -->
                    <!-- <div class="checkout-btn pb-2">
                        <span class="font-mid" style="color: #3aa138;"><svg _ngcontent-ubh-c120="" width="16" height="17"
                                viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                                <circle _ngcontent-ubh-c120="" cx="8" cy="8.5" r="8" fill="#3aa138" class="ng-tns-c120-0"></circle>
                                <path _ngcontent-ubh-c120=""
                                    d="M12.8536 4.65954C12.6583 4.44682 12.3417 4.44682 12.1464 4.65954L6.15614 11.1852L3.85356 8.67684C3.65831 8.46412 3.34175 8.46414 3.14645 8.67684C2.95118 8.88954 2.95118 9.23439 3.14645 9.44711L5.80258 12.3405C5.99778 12.5532 6.31458 12.5531 6.50969 12.3405L12.8536 5.42983C13.0488 5.21713 13.0488 4.87226 12.8536 4.65954Z"
                                    fill="white" class="ng-tns-c120-0"></path>
                            </svg>
                            You've got free delivery (-$5)
                        </span>
                    </div> -->
                    <div class="mt-3" *ngIf="windowInnerWidth > 992">
                        <div class="col-md-6 col-sm-12 col-form-label page-titles-font set-label-size text-start">Terms and Conditions:</div>
                        <form [formGroup]="privacyPolicy">
                            <div class="form-check desk-check-txt">
                                <input class="form-check-input" type="checkbox" formControlName="termsAndConditions" >
                                <label class="form-check-label" for="flexCheckDefault">
                                    <span class="text-danger">*</span>I accept the <a role="button" (click)="openPolocyWindow()" class="link-primary">Terms and Conditions of Service</a>
                                </label>
                                <label class="invalid-feedback font-sm" *ngIf="privacyPolicy.get('termsAndConditions')?.errors?.['required']">
                                    Accept Terms and Conditions is required
                                </label>
                            </div>
                        </form>
                    </div>
                    <div style="display: flex; flex-wrap: wrap; gap: 10px; justify-content: center; margin-top: 15px;">
                        <div class="d-flex justify-content-center">
                            <div><a role="button" (click)="goToMenupage()" [style.textTransform]="'capitalize'" class="btn btn-round btn-green">Add More Meals</a></div>
                        </div>
                        <div class="d-flex justify-content-center">
                            <button  type="button" *ngIf="!privacyPolicy.valid || !cartItems || !userInfo.phoneNumber || !selectedAddress" [disabled]="!privacyPolicy.valid || !cartItems || !userInfo.phoneNumber || !selectedAddress" class="btn px-5 btn-round btn-theme-yellow">Place Order</button>
                            <button type="button" *ngIf="privacyPolicy.valid && cartItems && userInfo.phoneNumber && selectedAddress" (click)="createCart()" class="btn px-5 btn-round btn-theme-yellow">Place Order</button>
                        </div>
                    </div>                    
                </app-product-list>
            </div>
        </div>
    </div>
</div>
<!-- <ng-template #modal>
    <div class="modal-header">
        <h5 class="page-titles-font set-label-size">Available Coupons</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeWindow('Cross click')"></button>
    </div>
    <div class="modal-body">
        <ul class="cp-block" *ngIf="discountCoupons.length; else noCoupon">
            <li *ngFor="let coupon of discountCoupons">  
                <span>{{coupon.code}}</span>
                <span><button class="btn btn-sm btn-success" (click)="applyDiscountCoupon(coupon)" style="border-radius: 25px; padding: 2px 12px; font-size: 12px;">Apply</button> </span>
            </li>
        </ul>
        <ng-template #noCoupon>
            <p class="m-0 py-4 text-center">
                <span class="text-center font-sm ">No Coupons Found</span>
            </p>
        </ng-template>
    </div>
</ng-template> -->
<ng-template #couponWindow>
    <div class="modal-body">
        <h4 class="page-titles-font text-center mt-2 mb-2">You Got</h4>
        <h1 class="page-titles-font text-center" style="color: #3aa138; background-image: url(../../../assets/images/success.gif); background-repeat: no-repeat; background-position: 95px; background-size: 125px; "> {{ this.appliedCoupon?.discountAmont | currency:'GBP' }} </h1>
        <h4 class="page-titles-font text-center mt-2">Discount</h4>
    </div>
</ng-template>
<ng-template #modal>
    <div class="modal-header">
        <h5 class="page-titles-font set-label-size">{{ windowTitle }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeWindow('Cross click')"></button>
    </div>
    <div class="modal-body pb-mob">
        <app-user-address *ngIf="isAddNewAddress" [formState]="formState" [userAddressEdit]="userAddressEdit" (addedAddress)="addedAddress($event)" (closeNewAddress)="closeNewAddress($event)"></app-user-address>
    </div>
</ng-template>
<ng-template #privacyPolcy>
    <div class="modal-header">
		<h6 class="modal-title" id="modal-basic-title">Terms and Conditions of Service</h6>
		<button type="button" class="btn-close" aria-label="Close" (click)="closePolocyWindow('close window')" ></button>
	</div>
    <div class="modal-body">
        <app-terms-text></app-terms-text>
    </div>
</ng-template>
