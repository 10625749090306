import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { UserService } from '@app/serveces/app/user/user.service';
import { AddressPayload, PostalCodeAccess } from '@app/models/user.model';
import { MapsAPILoader } from '@agm/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormState } from '@app/models/app.enum';
import { addressTypes } from '@app/models/addressTypes';

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.scss']
})
export class UserAddressComponent implements OnInit {

  addressForm:FormGroup;
  isApiLoaded = false;
  options: any = {
    types: ["address"],
    componentRestrictions: { country: 'UK' },
  }
  isDeliverable:boolean = false;
  @Input() formState:any
  @Output() closeNewAddress = new EventEmitter<boolean>()
  @Output() addedAddress = new EventEmitter<any>()
  @Input() userAddressEdit!:AddressPayload;
  addressTypes:any;
  @ViewChild('palceText') placeText: ElementRef

  constructor(private formBUilder:FormBuilder,private mapsAPILoader: MapsAPILoader,private userService:UserService) { 
    this.addressTypes = addressTypes
    this.addressForm = this.formBUilder.group({
      _id:[''],
      address1: ['',[Validators.required,Validators.maxLength(120)]],
      address2: [''],
      city: ['',[Validators.required,Validators.maxLength(30)]],
      postCode: ['',[Validators.required,Validators.pattern(/^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2}))$/)]],
      latitude: [''],
      longitude: [''],
      type:['',[Validators.required]]
    });
    this.userService.getPostalCodeAccess$.subscribe({
      next:(resp:PostalCodeAccess)=>{ 
        const { postalCode } = resp;
        this.addressForm.patchValue({
          postCode:postalCode
        })
      }
    })
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() =>{
      this.isApiLoaded = true
    })
    if(this.formState === FormState.UPDATE && this.userAddressEdit) {
      this.editAddress(this.userAddressEdit);
    }
    this.addressForm.controls['postCode'].valueChanges.subscribe({
      next:(resp:any)=>{
        if(this.addressForm.controls['postCode'].valid) {
          this.getDeliverableStaus()
        }
      }
    })
  }

  submitAddressForm(payload:any) {
    if(this.addressForm.valid) {
      delete payload.type
      const addressPayload:AddressPayload = {
        address:payload,
        type:this.addressForm.controls['type'].value
      }
      addressPayload.address.postCode = addressPayload.address.postCode.trim().toUpperCase()
      this.userService._saveUserAddress(addressPayload, this.formState).subscribe({
          next: (resp: any) => {
            this.addedAddress.emit(resp);
            this.addressForm.reset();
            this.closeNewAddress.emit(false);
          },
          error: (error: any) => {
            console.log(error);
          },
      });
    }
  }

  editAddress(payload:AddressPayload) {
    this.addressForm.patchValue({
      _id:payload._id,
      address1: payload.address.address1,
      address2: payload.address.address2,
      city: payload.address.city,
      postCode: payload.address.postCode,
      type:payload.type,
      latitude:payload.address.latitude,
      longitude:payload.address.longitude
    })
    this.isDeliverable = false;
  }

  getDeliverableStaus() {
    if(this.addressForm.controls['postCode'].valid) {
      this.isDeliverable = true;
      this.userService._checkPostalCode(this.addressForm.controls['postCode'].value.trim().toUpperCase()).subscribe({
        next:(resp:any)=>{
          const {message,deliverable} = resp;
          this.isDeliverable = !deliverable;
          if(deliverable.toString()==='false') {
            this.addressForm.controls['postCode'].setValue('');
            alert(message);
          }
        },
        error:(error:any)=>{
          console.log(error)
        }
      })
    }
    
  }

  getAddress(e:any) {
    let add = e.target.value.split(',')[0];
    if(!add) {
      add = e.target.value;
    }
    this.addressForm.patchValue({
      address1:add
    })
  }

  AddressChange(address:Address) {
      let add2:any
      let add1:any
      const addLineOne = this.placeText.nativeElement.value.split(',')[0];
        this.addressForm.patchValue({
        address1:addLineOne
      })
    address.address_components.forEach((element:any) => {
    
      if(element.types.includes('locality') || element.types.includes('route')) {
        add1 = `${element.long_name} ,`
      }

      if(element.types.includes('administrative_area_level_2')) {
        add2 = `${element.long_name}`
      }

      if(element.types.includes('postal_code')) {
        this.addressForm.patchValue({
          postCode:element.long_name
        })
      }
      if(element.types.includes('postal_town')) {
        this.addressForm.patchValue({
          city:element.long_name
        })
      }
    });

    let finalAdd2 = `${add1 || '' } ${add2 || ''}`;
    this.addressForm.patchValue({
      address2: finalAdd2.trim()
    })
    this.addressForm.patchValue({
      // address2:address.formatted_address,
      latitude:address.geometry.location.lat(),
      longitude:address.geometry.location.lng()
    })
  }

  cancelForm(event:any) {
    event.preventDefault()
    this.closeNewAddress.emit(false);
  }

}
