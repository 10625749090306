<div class="container-fluid sub-page-banner banner-green position-relative">
    <div class="container">
        <div class="menuitem-bg-img position-relative pd-page-banner">
            <h2 class="page-titles-font text-white">Checkout</h2>
        </div>
    </div>
</div>
<div class="container-fluid py-4 bg-light vh-75">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-8 padding-mob">
                <div class="date-select-desg py-3 position-relative">
                    <label class="col-form-label ms-3 page-titles-font set-label-size"> User Info:</label>
                </div>
                <div class="product-cnt p-4 bg-white">
                    <div class="card">
                        <div class="card-body">
                            <div class="row font-sm">
                                <div><b> {{ userInfo.firstName }} {{ userInfo.lastName }}</b> </div>
                                <span class="me-auto"><b>Email:</b> {{userInfo.email}} </span>
                            </div>
                        </div>
                    </div>
                    <div class=" my-2 position-relative">
                        <label class="col-form-label page-titles-font set-label-size">Contact Number: </label> 
                        <span class="me-auto"> {{ cartItem.contactNumber }} </span>
                    </div>
                    <div class=" mt-2 position-relative">
                        <label class="col-form-label page-titles-font set-label-size">Delivery Address:</label>
                    </div>
                    <div class="row" *ngIf="selectedAddress">
                        <div class="col--md-12 col-lg-6 mt-2">
                            <div class="card" >
                                <div class="card-body font-sm add-cnt" [ngClass]="{'selected': selectedAddress.isSelected }" role="button">
                                    <span><b>Address:</b> {{ selectedAddress.address.address1 | titlecase  }}, {{selectedAddress.address.address2 | titlecase  }} </span><br>
                                    <span><b>City:</b> {{ selectedAddress.address.city  | titlecase }} </span><br>
                                    <span><b>Postcode:</b> {{ selectedAddress.address.postCode }}</span>
                                </div>
                                <div class="d-flex col-12 p-1 bg-light border-top"> 
                                    <span class="me-auto font-sm ms-2">Type: {{selectedAddress.type}} </span>
                                </div>
                            </div>
                            <div class="d-flex my-2 ms-auto" >
                                <span class="ms-auto">
                                    <a class="btn btn-sm btn-theme-yellow ms-auto secondaryButton" (click)="openModal()" role="button">Change Address</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-4 mt-lg-0 mt-sm-5 position-cart-cnt show-cart-items" *ngIf="isShowCartItems">
                <div class="date-select-desg py-2 position-relative">
                    <label class="col-form-label ms-3 page-titles-font set-label-size">Basket</label>
                </div>
                <app-product-list >
                    <!-- (click)="applyDiscountCoupon(cpn.value)" -->
                    <!-- <div class="checkout-btn pb-2">
                        <span class="font-mid" style="color: #3aa138;"><svg _ngcontent-ubh-c120="" width="16" height="17"
                                viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
                                <circle _ngcontent-ubh-c120="" cx="8" cy="8.5" r="8" fill="#3aa138" class="ng-tns-c120-0"></circle>
                                <path _ngcontent-ubh-c120="" d="M12.8536 4.65954C12.6583 4.44682 12.3417 4.44682 12.1464 4.65954L6.15614 11.1852L3.85356 8.67684C3.65831 8.46412 3.34175 8.46414 3.14645 8.67684C2.95118 8.88954 2.95118 9.23439 3.14645 9.44711L5.80258 12.3405C5.99778 12.5532 6.31458 12.5531 6.50969 12.3405L12.8536 5.42983C13.0488 5.21713 13.0488 4.87226 12.8536 4.65954Z" fill="white" class="ng-tns-c120-0"></path>
                            </svg>
                            You've got free delivery (-$5)
                        </span>
                    </div> -->
                    <div class="mt-2 py-2 d-flex justify-content-center">
                        <button type="button" (click)="placeOrder()" class="btn btn-round btn-theme-yellow">Proceed to payment</button>
                    </div>
                </app-product-list>
                <div class="mt-3">
                    <p class="small text-center"><b>Note:</b> You cannot return once you click Proceed</p>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #paymentWindow>
    <div class="modal-body">
        <div class="container stripe-container">
            <div class="row">
              <div class="col-sm-12">
                <form id="payment-form">
                    <div id="payment-element">
                      <!-- Elements will create form elements here -->
                    </div>
                    <div class="mt-2 py-2 d-flex justify-content-center">
                        <button class="btn btn-theme-yellow" id="submit">Submit</button>
                    </div>
                    <div id="error-message">
                      <!-- Display error message to your customers here -->
                    </div>
                  </form>
              </div>
            </div>
          </div>
    </div>
</ng-template>
<app-model-window #mdWindow [modalConfig]="ModalConfig">
    <div class="row">
        <div class="col-md-12 col-lg-6 mt-2" *ngFor="let addr of userAddress; let i = index;">
            <div class="card" >
                <div class="card-body font-sm add-cnt" (click)="selectAddress(i)" [ngClass]="{'selected': addr.isSelected }" role="button">
                    <span><b>Address:</b> {{ addr.address.address1 | titlecase  }}, {{addr.address.address2 | titlecase  }} </span><br>
                    <span><b>City:</b> {{ addr.address.city  | titlecase }} </span><br>
                    <span><b>Postcode:</b> {{ addr.address.postCode }}</span>
                </div>
                <div class="d-flex col-12 p-1 bg-light border-top"> 
                    <span class="me-auto font-sm ms-2">Type: {{addr.type}} </span>
                </div>
            </div>
        </div>
    </div>
</app-model-window>