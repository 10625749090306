<div *ngIf="isShowCartItems" class="mob-bottom-wrap" [style.maxHeight.px]="divHeight"  [ngClass]="{'show-full-vh': currentPage == 'proceed-payment' }">
    <span *ngIf="showCartDetails && !showPaymentContainer" role="button" [style.bottom.px]="divHeight-45" (click)="_closeCartDetails()" class="icon-cross"></span>
    <div class="vh-cart-mob position-relative" *ngIf="showCartDetails && !showPaymentContainer">
        <p class="text-center" style="font-size: 0.85rem; background-color: #f9f9f9;" >Delivery - Between 3pm-7pm on every chosen day</p>
        <app-product-list>
            <div class="p-2" *ngIf="currentPage === 'checkout'">
                <app-coupon-code></app-coupon-code>
            </div>
        </app-product-list>
    </div>
    <div class="vh-cart-mob p-0" *ngIf="showPaymentContainer && currentPage == 'proceed-payment'">
        <div class="product-cnt p-2 bg-white">
            <div class="title-bg">
                <label class="col-form-label page-titles-font font-sm set-label-size p-1">User Info: </label> 
            </div>
            <div class="row font-sm">
                <div><b> {{ userInfo.firstName }} {{ userInfo.lastName }}</b> </div>
                <span class="me-auto"><b>Email:</b> {{userInfo.email}} </span>
            </div>
            <div class="my-2 position-relative title-bg">
                <label class="col-form-label page-titles-font font-sm et-label-size p-1">Contact Number: </label> 
            </div>
            <div class="me-auto pb-2 font-sm"> {{ userInfo.phoneNumber }} </div>
            <div class="position-relative title-bg">
                <label class="col-form-label page-titles-font font-sm set-label-size p-1">Delivery Address:</label>
            </div>
            <div class="row" *ngIf="selectedAddress">
                <div class="col--md-12 col-lg-6 mt-2 font-sm">
                    <span><b>Address:</b> {{ selectedAddress.address.address1 | titlecase  }}, {{selectedAddress.address.address2 | titlecase  }} </span><br>
                    <span><b>City:</b> {{ selectedAddress.address.city  | titlecase }} </span><br>
                    <span><b>Postcode:</b> {{ selectedAddress.address.postCode }}</span>
                    <span class="me-auto font-sm ms-2">Type: {{selectedAddress.type}} </span>
                </div>
            </div>
            <div class="mb-2 position-relative page-titles-font p-2 mt-3 total-count-blk" role="button">
                <div class="d-flex mb-2" *ngIf="appliedCoupon !== 0" >
                    <div class="me-4"><span class="discount-color">Discount</span></div>
                    <div><span class="discount-color">- {{ appliedCoupon | currency:'GBP' }} </span></div>
                </div>
                <span class="me-3"><b>Basket Total:</b></span> 
                <span *ngIf="cartTotal; else noTotal">
                    <b *ngIf="cartTotal.standardTotal > cartTotal.finalAmount" class="text-decoration-line-through me-2">{{ cartTotal.standardTotal | currency:'GBP' }} </b> 
                <b>{{ cartFinal | currency:'GBP' }}</b> 
                </span>
                <ng-template #noTotal><span><b>{{ cartTotal | currency:'GBP' }} </b></span> </ng-template>
            </div>
        </div>
        <div  class="d-flex justify-content-center"  *ngIf="!showStripePayWindow">
            <button type="button" (click)="placeOrder()" class="btn btn-round btn-theme-yellow">Proceed to payment</button>
        </div>
        <div class="mt-3">
            <p class="text-center" style="font-size: 0.85rem; background-color: #f9f9f9;" >Delivery - Between 3pm-7pm on every chosen day</p>
            <p class="small text-center"><b>Note:</b> You cannot return once you click Proceed</p>
        </div>
    </div>
    <div class="col-sm-12" *ngIf="showStripePayWindow">
        <div class="stripe-container p-4">
            <form id="payment-form">
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>
                <div *ngIf="showSubmit" class="mt-2 py-2 d-flex justify-content-center">
                    <button class="btn btn-theme-yellow" id="submit">Submit</button>
                </div>
                <div id="error-message">
                    <!-- Display error message to your customers here -->
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="!showCartDetails && currentPage !== 'proceed-payment'" class="d-flex bottm-bnt-width m-auto justify-content-center">
        <div class="mb-2 position-relative" style="min-width: 100%; text-align: center;" role="button" (click)="_showCartDetails()">
            <span class="me-3"><b>Basket Total:</b></span> 
            <span *ngIf="cartTotal else noTotal" [style.display]="'inline-block'" class="position-relative">
                <b *ngIf="cartTotal.standardTotal > cartTotal.finalAmount" class="text-decoration-line-through me-2">{{ cartTotal.standardTotal | currency:'GBP' }} </b> 
                <b>{{ cartFinal | currency:'GBP' }}</b>
                <span role="button" class="button-up"></span>
            </span>
            <ng-template #noTotal>
                <span><b>{{ cartTotal | currency:'GBP' }}</b></span>
            </ng-template>
            
        </div>
    </div>
    <div *ngIf="currentPage == 'menu-items'" class="d-flex justify-content-center">
        <button [disabled]="!cartTotal" (click)="changeNavigation('checkout')" class="btn btn-round btn-theme-yellow btn-mob">Go to Checkout</button>
    </div>
    <div *ngIf="currentPage == 'checkout'" class="d-flex gap-3 justify-content-center">
        <button (click)="changeNavigation('menu-items')" class="btn btn btn-round btn-green text-trns btn-mob">Add More Meals</button>

        <button *ngIf="!isTcApplied || !cartTotal || !selectedAddress || !userInfo.phoneNumber" [disabled]="!isTcApplied || !cartTotal || !selectedAddress || !userInfo.phoneNumber" class="btn btn-round btn-theme-yellow btn-mob">Place Order</button>
        <button *ngIf="isTcApplied && cartTotal && selectedAddress && userInfo.phoneNumber"  (click)="createCart()" class="btn btn-round btn-theme-yellow btn-mob">Place Order</button>
    </div>
    <div *ngIf="currentPage == 'mycart'" class="d-flex gap-3 justify-content-center">
        <button (click)="changeNavigation('menu-items')" class="btn btn btn-round btn-green text-trns btn-mob">Add More Meals</button>
        <button [disabled]="!cartTotal" (click)="changeNavigation('checkout')" class="btn btn-round btn-theme-yellow btn-mob">Go to Checkout</button>
    </div>
</div>