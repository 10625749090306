<div class="col-md-12 m-auto card font-sm card-show">
    <div class="card-body">
        <div class="col-md-12 mb-2"  *ngIf="isApiLoaded">
            <label class="form-label">Start typing address:</label>
            <input class="form-control" ngx-google-places-autocomplete [options]="options" #palceText (onAddressChange)="AddressChange($event)"/>
        </div>
        <form [formGroup]="addressForm" (ngSubmit)="submitAddressForm(addressForm.value)" >
            <div class="col-md-12 mb-2 pt-3">
                <label class="form-label font-sm">Address Type<span class="text-danger">*</span>:</label>
                <div class="d-flex">
                    <div *ngFor="let addType of addressTypes">
                        <div class="form-check me-3">
                            <input class="form-check-input" type="radio" [value]="addType.adName" name="type" formControlName="type" >
                            <label class="form-check-label" for="flexRadioDefault1">{{ addType.adName }}</label>
                        </div>
                    </div>
                    <label class="invalid-feedback" *ngIf="addressForm.controls['type'].errors">
                        Enter a valid postal code
                    </label>
                </div>
            </div>
            <div class="col-md-12 mb-2">
                <label class="form-label">Address Line 1<span class="text-danger">*</span>:</label>
                <input type="text" name="address1" formControlName="address1" class="form-control form-control-sm"
                [ngClass]="{'is-invalid': addressForm.controls['address1'].touched && addressForm.controls['address1'].errors}"
                />
            </div>
            <div class="col-md-12 mb-2">
                <label class="form-label">Address Line 2</label>
                <input type="text" name="address2" formControlName="address2" class="form-control form-control-sm"
                [ngClass]="{'is-invalid': addressForm.controls['address2'].touched && addressForm.controls['address2'].errors}"
                />
            </div>
            <div class="row mb-2">
                <div class="col-md-6">
                    <label class="form-label">Town/City<span class="text-danger">*</span>:</label>
                    <input type="text" name="city" formControlName="city" class="form-control form-control-sm"
                    [ngClass]="{'is-invalid': addressForm.controls['city'].touched && addressForm.controls['city'].errors}"
                    />
                </div>
                <div class="col-md-6">
                    <label class="form-label">Post Code<span class="text-danger">*</span>:</label>
                    <input type="text" name="postCode" formControlName="postCode" class="form-control form-control-sm" 
                    [ngClass]="{'is-invalid': addressForm.controls['postCode'].touched && addressForm.controls['postCode'].errors}"
                    />
                    <label class="invalid-feedback" *ngIf="addressForm.get('postCode')?.errors?.['pattern']">
                        Enter a valid postal code
                    </label>
                </div>
            </div>
            <div class="d-flex mt-3 my-2">
                <div class="ms-auto">
                    <button (click)="cancelForm($event)" class="btn btn-round btn-sm btn-danger me-2">Cancel</button>
                    <button class="btn btn-round btn-sm btn-theme-yellow" [disabled]="!addressForm.valid || isDeliverable" >Save Address</button>
                </div>
            </div>
        </form>
    </div>
</div>