import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { couponCode } from '@app/models/cart.model';
import { map } from 'rxjs/operators';
import { ToasterService } from '../toaster/toaster.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-coupon-code',
  templateUrl: './coupon-code.component.html',
  styleUrls: ['./coupon-code.component.scss'],
  // changeDetection:ChangeDetectionStrategy.OnPush
})

export class CouponCodeComponent implements OnInit,OnDestroy {

  constructor(private cartService:CartService, private toaster:ToasterService, private modalService: NgbModal,) { }

  discountCoupons:any;
  couponList:any = [];
  appliedCoupon:couponCode = {
    _id:'',
    code:'',
    discountAmont:0
  };
  appliedValue:any;
  isShowAvlCpn:boolean = false;
  isCopied:boolean = false;
  copyText:string = "copy";
  discountCouponsSub$:Subscription|undefined;
  couponCodeApplied$:Subscription | undefined;
  appliedCouponCode:any = null;

  @ViewChild('couponWindow') private couponWindow: TemplateRef<any> | undefined;
  private modalRef: NgbModalRef | any

  ngOnInit(): void {
    this.getAllCouponCodes()
    this.appliedCouponCode = this.cartService.appliedCouponObj;
    this.couponCodeApplied$ = this.cartService.getDiscountApplied$.subscribe({
        next: (resp: couponCode) => {
          if(resp==null) {
            this.appliedCouponCode = null;
          }
        }
      });
  }

  getAllCouponCodes() {
    this.discountCouponsSub$ = this.cartService._getAllCouponCodes().pipe(
      map((item:any)=>{
        const items = [...item]
        this.couponList = items;
        return item;
      })
    ).subscribe({
      next:resp=>this.couponList=resp
    })
  }

  copyToClipBoard(text:string) {
    this.copyText = "copied";
    this.isCopied = true;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = text.toUpperCase();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    setTimeout(() => {
      this.copyText = "copy";
      this.isCopied = false;
    }, 1500);
  }

  applyDiscountCoupon(coupon:any) {
    const couponCode = this.couponList.find((el:any)=>{
      const code = el.code; 
      return code === coupon.userCouponCode
    })
    this.appliedCoupon.code = coupon.userCouponCode;
    if(couponCode) {
      this.cartService.getDiscountAmount(couponCode._id).subscribe({
        next:(resp:any)=>{
          this.cartService.setDiscountCoupon(resp);
          this.cartService.setSelectedDiscountCoupon(couponCode)
          this.appliedCouponCode = this.cartService.appliedCouponObj;
          if(resp!==0) {
            this.appliedValue = resp;
            this.modalRef = this.modalService.open(this.couponWindow, {
              backdrop: 'static',
              keyboard: false,
            });
            setTimeout(() => {
              this.modalRef.dismiss();
            }, 2500);
            // this.toaster.success('Coupon applied successfully!',{autoClose:true})
          } else {
            this.toaster.error('The coupon has no cash value',{autoClose:true})
          }
        },
        error:(resp:any) =>{
          this.toaster.error(resp.message,{autoClose:true})
        }
    })
    } else {
      this.toaster.error('Coupon code is not valid!',{autoClose:true})
    }
    
  }

  removeCouponCode() {
    this.appliedCouponCode = null;
    this.cartService.removeCouponCode();
  }

  ngOnDestroy(): void {
   this.discountCouponsSub$?.unsubscribe();
   this.couponCodeApplied$?.unsubscribe();
  }

}
