<div class="mt-5 vh-60 m-auto">
    <div class="d-flex align-content-center">
        <div class="col-md-12 ms-auto me-auto">
            <div class="mb-3 font-mid">Enter OTP sent to email address: </div>
            <input #inputEl class="me-2" *ngFor="let input of inputs.controls; let i = index" (focus)="handleFocus($event)"
                (blur)="onTouched?.()" (keypress)="handleKeyPress($event, i)" (input)="handleInput()"
                (keydown)="handleKeyDown($event, i)" (paste)="handlePaste($event, i)" [formControl]="$any(input)"
                [attr.autocomplete]="i === 0 ? 'one-time-code' : null" type="text" inputmode="numeric" />
        </div>
    </div>
    <ng-content></ng-content>
    <!-- <div class="mt-3 ms-2 d-flex text-right">
        <div class="ms-auto"><a role="button" class="link-primary font-mid">Resend Otp</a></div>
    </div> -->
</div>