import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from '@app/models/mealMenuItems';

@Component({
  selector: 'app-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss']
})
export class ItemViewComponent implements OnInit {

  constructor() { }

  @Input() Item:Item

  @Output() createCartItem = new EventEmitter<Item>();
  @Output() removeCartItem = new EventEmitter<string>();
  @Output() editCartItem = new EventEmitter<string>();
  @Output() updateCartItem = new EventEmitter<Item>();

  @Input() isShowSelectButton:boolean = true;

  ngOnInit(): void {
   
  }

  selectItem(itemId:Item) {
    this.createCartItem.emit(itemId);
    // this.editCartItem.emit(itemId)
  }

  removeItem(itemId:string) {
    this.removeCartItem.emit(itemId)
  }

  updateCartByItem(item:Item) {
    if(item.quantity !==0) {
      this.updateCartItem.emit(item)
    }
  }

  allergenInfo(event:any) {
    if(event.target.nextElementSibling.className=='hide-alg'){
      event.target.nextElementSibling.classList.add('show-alg')
      event.target.classList.add('rotate')
      event.target.nextElementSibling.classList.remove('hide-alg')
    } else {
      event.target.nextElementSibling.classList.add('hide-alg')
      event.target.classList.remove('rotate')
      event.target.nextElementSibling.classList.remove('show-alg')
    }
    
    
  }


}
