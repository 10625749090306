<div *ngIf="mealMenuItems; else noItems">
    <div class="menu-header" [style.top.px]="headerService.getHeaderHeight | async">
        <div class="flex-items">
            <div class="col-md-6 col-sm-12 col-lg-5">
                <select class="form-select" #dt (change)="getSelectedWeek(dt.value)">
                    <option *ngFor="let week of weeksList; let i = index" value="{{i}}">
                        {{ week.start | date:'EEEE, LLL d'}} - {{week.end | date:'EEEE, LLL d'}}
                    </option>
                </select>
            </div>
        </div>
        <div class="tab-icons mt-2">
            <div role="button" (click)="filterBySearchItem(i)" [ngClass]="{'active':currentIndex == i}" class="button-filter" *ngFor="let cat of searchContent; let i = index">{{ cat == catNames.BREAD || cat == 'All' ? cat : (cat+addText | titlecase )  }}
            </div>
        </div>
    </div>
    <div class="container">
        <div class="mt-200">
            <div class="row"  *ngFor="let cat of mealMenuItems">
                <h5 class="page-titles mt-3"> {{ cat.date | date:'EEEE, LLL d' }}  <span class="cat-label">{{ cat.name == catNames.BREAD ? cat.name : (cat.name+addText | titlecase ) }}</span></h5>
                <div class="col-md-4 col-lg-3 col-sm-6 col-xs-6 mb-3" *ngFor="let Item of cat.items">
                    <!-- <app-item-view [Item]="item" [isShowSelectButton]="false" ></app-item-view> -->
                    <div class="col-md-12">
                        <div class="item-border">
                            <div class="align-items-center">
                                <div class="prod-img img-ht" >
                                    <img [src]="Item.photos[0].url" alt="product" class="img-ht"/>
                                </div>
                                <div class="prod-desc p-2">
                                    <h6 class="position-relative mb-1"><b>{{ Item.name }} </b>
                                        <div>
                                            <span class="font-mid"><b>{{ Item.price | currency:'GBP' }} </b></span>
                                            <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==1">
                                                <span class="icon-spice" [style.right.px]="3"></span>
                                            </span>
                                            <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==2">
                                                <span class="icon-spice" [style.right.px]="3"></span>
                                                <span class="icon-spice" [style.right.px]="20"></span>
                                            </span>
                                            <span *ngIf="Item.spiceLevel && Item.spiceLevel !=0 && Item.spiceLevel==3">
                                                <span class="icon-spice" [style.right.px]="3"></span>
                                                <span class="icon-spice" [style.right.px]="20"></span>
                                                <span class="icon-spice" [style.right.px]="38"></span>
                                            </span>
                                        </div>
                                    </h6>
                                    <div class="calc-items">
                                        <p class="item-descp mb-0">{{ (Item.description.length > 85) ? (Item.description | slice:0:85)+'... ': (Item.description) }}
                                            <span role="button" style="text-decoration: underline;" class="ms-1" *ngIf="Item.description.length > 85" placement="bottom" ngbPopover="{{Item.description}}">Read full</span>
                                        </p>
                                    </div>
                                    <div class="mt-2">
                                        <i class="bi bi-info-circle me-2" style="font-size: 0.75rem"></i>
                                        <span role="button" style="font-size: 0.75rem" (click)="allergenInfo($event)"
                                            class="font-sm allergen">Allergen info</span>
                                        <div class="hide-alg">
                                            <p class="m-0"><small>{{ Item.allergen || 'Not applicable' }}</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #noItems>
    <div class="mt-200"><p class="text-center">No Items Found..</p> </div>
</ng-template>

