import { Component, HostListener, OnInit,OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Order, couponCode } from '@app/models/cart.model';
import { AddressPayload } from '@app/models/user.model';
import { CartService } from '@app/serveces/app/cart/cart.service';
import { AuthenticationService } from '@app/serveces/auth/authentication.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { PaymentService } from '@app/serveces/app/payment/payment.service';
import { ToasterService } from '../toaster/toaster.service';
import { SpinnerService } from '@app/components/loading-spinner/spinner.service';


declare var Stripe: any;


@Component({
  selector: 'app-mobile-cart-items',
  templateUrl: './mobile-cart-items.component.html',
  styleUrls: ['./mobile-cart-items.component.scss']
})
export class MobileCartItemsComponent implements OnInit, OnDestroy {
  isShowCartItems: boolean = false;
  cartTotalSubscription:Subscription | undefined;
  routeSubscription:Subscription | undefined;
  addressSubscription:Subscription | undefined;
  couponSubscription:Subscription | undefined;
  safeAreaSubscription$:Subscription | undefined;
  tAndCSubscription$:Subscription | undefined;
  cartTotal:any;
  showCartDetails:boolean = false;
  currentPage:string | undefined;
  selectedAddress:any;
  appliedCoupon:any;
  userInfo:any;
  showPaymentContainer:boolean = false;
  showStripePayWindow:boolean = false;
  paymentCartItem:any;
  showSubmit:boolean = false;
  divHeight:number = window.innerHeight - 50;
  safeArea:string | undefined;
  cartFinal:any;
  isTcApplied:boolean;

  constructor(private router:Router, private cartService:CartService, private authService:AuthenticationService, private paymentService:PaymentService, private toaser:ToasterService, private spinner:SpinnerService) { 
    this.userInfo = JSON.parse(this.authService.userInfoData);
  }

  pageUrls:any = ['checkout','mycart','myorders','menu-items','proceed-payment']

  ngOnInit(): void {
    this.divHeight = window.innerHeight - 50;
    this.cartTotalSubscription = this.cartService.getCartTotal.subscribe({
      next:resp=>{
        this.cartTotal = resp || 0;
        this.cartFinal = resp?.finalAmount || 0;
      }
    })

    this.tAndCSubscription$ = this.cartService.isTandCn$.subscribe({
      next:resp=>this.isTcApplied = resp
    })

    this.authService.userInfo.subscribe({
      next: (resp:any)=>{ this.userInfo = JSON.parse(resp) }
    })

    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
          this.cartService.setIsAcpCnd(false);
          this.currentPage = event.url.split('/')[1]
            if(this.pageUrls.includes(this.currentPage)) {
              this.isShowCartItems = true;
              this.showPaymentContainer = false;
            } else {
              this.isShowCartItems = false;
              this.showPaymentContainer = false;
            }
            if(this.pageUrls.includes(this.currentPage) && this.currentPage==='proceed-payment') {
              this.showPaymentContainer = true;
            }
            this.showCartDetails = false;
            this.showStripePayWindow = false;
        }
    });

    this.addressSubscription = this.cartService.getDelvryAddress$.subscribe({
      next:(resp:AddressPayload)=>{
        this.selectedAddress = resp
      }
    })
    this.couponSubscription = this.cartService.getDiscountApplied$.subscribe({
      next:(resp:couponCode)=>{
        this.appliedCoupon = +resp;
        this.cartFinal = this.cartTotal.finalAmount;
        this.cartFinal = this.cartFinal - +resp
      }
    })

    this.safeAreaSubscription$ = this.cartService.getSafeArea().subscribe({
      next: (resp:string) => this.safeArea = resp
    })

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if(window.innerWidth > 992) {
      this.isShowCartItems = false;
    } else {
      if(this.pageUrls.includes(this.currentPage)) {
        this.isShowCartItems = true;
        this.divHeight = window.innerHeight - 50;
        if(this.currentPage==='proceed-payment') {
          this.divHeight = window.innerHeight
        }
      }
    }
  }

  _showCartDetails() {
    this.showCartDetails = true;
  }

  _closeCartDetails() {
    this.showCartDetails = false;
  }

  changeNavigation(nav:string) {
    this.router.navigate([nav])
  }

  createCart() {
    const order:any = {
      addressId:this.selectedAddress?._id,
      contactNumber:this.userInfo.phoneNumber,
      discount: this.appliedCoupon?._id
    }
    this.cartService.createCart().subscribe({
      next:(resp:any)=>{
        const order:Order = {
          cartId: resp._id,
          addressId:this.selectedAddress?._id,
          contactNumber:this.userInfo.phoneNumber,
          discount: this.cartService.appliedCouponObj?._id,
          safePlace: this.safeArea || ''
        }
        // console.log(order)
        this.paymentCartItem = order;
        sessionStorage.setItem('userSavedCartInfo',JSON.stringify(order))
        this.router.navigate(['proceed-payment'])
      },
      error:error=>{
        console.log(error.message)
        error.error.message.message ? alert(error.error.message.message) : alert(error.message)
      }
    })
  }

  placeOrder() {
    const payload:Order = this.paymentCartItem;
    this.showPaymentContainer = false;
    this.showStripePayWindow = true;
    this.paymentService.placeOrder(payload).subscribe({
      next:(resp:any)=>{
       const { clientSecret } = resp;
       const stripe = Stripe(environment.stripePublicKey);
       const appearance = {
        theme: 'flat',
        variables: { colorPrimaryText: '#262626' }
      };
      const options = { 
        layout: {
          type: 'tabs',
          defaultCollapsed: false,
        }
       };
      //  this.ngOnDestroy(); 
       const elements = stripe.elements({ clientSecret, appearance });
       const paymentElement = elements.create('payment', options);
       paymentElement.mount('#payment-element');
       this.showSubmit = true;
       const form:any = document.getElementById('payment-form');

      form.addEventListener('submit', async (event:any) => {
        event.preventDefault();
      
        const {error} = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url:  environment.stripeRedirectUrl,
          },
        });
      
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer: any = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
          this.toaser.error(error.message,{autoClose:true});
        } else {
          
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      });
      },
      error:(err:any)=>{
        if(err || err.message) {
          this.spinner.showLoader();
          this.toaser.error(err.message,{autoClose:true})
          setTimeout(() => {
            this.spinner.hideLoader();
            sessionStorage.removeItem('userSavedCartInfo')
            this.showCartDetails = false;
            this.router.navigate(['checkout'])
          }, 3000);
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.cartTotalSubscription?.unsubscribe();
    this.routeSubscription?.unsubscribe();
    this.addressSubscription?.unsubscribe();
    this.couponSubscription?.unsubscribe();
    this.safeAreaSubscription$?.unsubscribe();
    this.tAndCSubscription$?.unsubscribe();
  }

}
